export interface UserState {
    login?: string;
    success: boolean;
    loading: boolean;
    error: string | null;
    loggedIn: boolean | undefined;
    data: {
        accessToken: string;
        roles: string[];
        email: string;
    };
}

export enum UserActionTypes {
    POST_USER_LOGIN = 'POST_USER_LOGIN',
    POST_USER_TOKEN = 'POST_USER_TOKEN',
    POST_USER_TOKEN_SUCCESS = 'POST_USER_TOKEN_SUCCESS',
    POST_USER_TOKEN_ERROR = 'POST_USER_TOKEN_ERROR',
    UPDATE_TOKEN = 'UPDATE_TOKEN',
    REMOVE_TOKEN = 'REMOVE_TOKEN',
    GET_USER_DATA = 'GET_USER_DATA',
    GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS',
    GET_USER_DATA_ERROR = 'GET_USER_DATA_ERROR',
}

interface PostUserLoginAction {
    type: UserActionTypes.POST_USER_LOGIN;
    payload: string;
}

interface PostUserTokenAction {
    type: UserActionTypes.POST_USER_TOKEN;
}

interface PostUserTokenSuccessAction {
    type: UserActionTypes.POST_USER_TOKEN_SUCCESS;
    payload: string;
}

interface PostUserTokenErrorAction {
    type: UserActionTypes.POST_USER_TOKEN_ERROR;
    payload: string;
}

interface UpdateTokenAction {
    type: UserActionTypes.UPDATE_TOKEN;
    payload: string;
}

interface RemoveTokenAction {
    type: UserActionTypes.REMOVE_TOKEN;
}

interface GetUserDataAction {
    type: UserActionTypes.GET_USER_DATA;
}
interface GetUserDataSuccessAction {
    type: UserActionTypes.GET_USER_DATA_SUCCESS;
    payload: { roles: string[]; email: string };
}
interface GetUserDataErrorAction {
    type: UserActionTypes.GET_USER_DATA_ERROR;
    payload: string;
}

export type usersAction =
    | PostUserLoginAction
    | PostUserTokenAction
    | PostUserTokenSuccessAction
    | PostUserTokenErrorAction
    | UpdateTokenAction
    | RemoveTokenAction
    | GetUserDataAction
    | GetUserDataSuccessAction
    | GetUserDataErrorAction;
