import React from 'react';

const NotWorkedIcon = (): JSX.Element => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.11719 1.72266C5.84766 1.82812 5.64258 2.02148 5.50195 2.30273L5.44922 2.44336L5.43164 9.12305C5.43164 13.5645 5.44336 15.8262 5.4668 15.9082C5.49023 15.9902 5.53711 16.0723 5.60742 16.1543C5.68945 16.2246 5.77148 16.2715 5.85352 16.2949C6.00586 16.3301 6.14648 16.3125 6.27539 16.2422C6.41602 16.1602 6.50391 16.0488 6.53906 15.9082C6.5625 15.8262 6.57422 15.2051 6.57422 14.0449V12.3047L10.0898 9.94922C12.4336 8.39062 13.6465 7.57031 13.7285 7.48828C13.8223 7.40625 13.8984 7.30664 13.957 7.18945C13.9922 7.10742 14.0156 7.04297 14.0273 6.99609C14.0391 6.9375 14.0449 6.86133 14.0449 6.76758C14.0449 6.66211 14.0391 6.58594 14.0273 6.53906C14.0156 6.48047 13.9922 6.41602 13.957 6.3457C13.8984 6.22852 13.8223 6.12891 13.7285 6.04688C13.6699 5.98828 12.5566 5.28516 10.3887 3.9375C8.23242 2.58984 7.06641 1.86914 6.89062 1.77539C6.78516 1.72852 6.65625 1.69922 6.50391 1.6875C6.35156 1.67578 6.22266 1.6875 6.11719 1.72266ZM9.70312 4.83398C11.7422 6.08789 12.7676 6.72656 12.7793 6.75C12.8027 6.76172 11.7832 7.45898 9.7207 8.8418C7.6582 10.2246 6.61523 10.916 6.5918 10.916C6.58008 10.916 6.57422 9.58008 6.57422 6.9082C6.57422 4.23633 6.58594 2.90625 6.60938 2.91797C6.63281 2.92969 7.66406 3.56836 9.70312 4.83398Z"
            fill="#FF5555"
        />
    </svg>
);

export default NotWorkedIcon;
