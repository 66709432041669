import React from 'react';
import { ApexOptions } from 'apexcharts';
import ApexChart from 'react-apexcharts';
export enum EChartType {
    Line = 'line',
    Area = 'area',
    Bar = 'bar',
    Histogram = 'histogram',
    Pie = 'pie',
    Donut = 'donut',
    RadialBar = 'radialBar',
    Scatter = 'scatter',
    Bubble = 'bubble',
    HeatMap = 'heatmap',
    TreeMap = 'treemap',
    BoxPlot = 'boxPlot',
    CandleStick = 'candlestick',
    Radar = 'radar',
    PolarArea = 'polarArea',
    RangeBar = 'rangeBar',
}

export type TChartProps = {
    chartOptions: ApexOptions;
    data?: any;
    dataFormatter?: (data: any) => Array<any>;
    series?: Array<any>;
    chartSettings: {
        type: EChartType;
    };
    height: string | number;
};

export const Chart = ({
    chartOptions,
    series,
    dataFormatter,
    data,
    chartSettings,
    height,
}: TChartProps): JSX.Element => {
    const _series = dataFormatter && data ? dataFormatter(data) || [] : undefined;
    return <ApexChart options={chartOptions} series={series || _series} type={chartSettings.type} height={height} />;
};
