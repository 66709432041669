import { Chart, EChartType } from '../../../../components/Chart';
import React, { useEffect } from 'react';
import { dateFormatter, formatDate } from '../../../../utilits/formatDate';
import { useTypeSelector } from '../../../../hooks/useTypeSelector';
import { useActions } from '../../../../hooks/useActions';
import { WorkplaceHistParams } from '../../../../types/workplace';

const COLOR_RANGES = [
    {
        from: 75,
        to: 100,
        color: `#1EBEAA`,
        name: '75-100%',
    },
    {
        from: 40,
        to: 75,
        color: `#FFC800`,
        name: '40-75%',
    },
    {
        from: 0,
        to: 40,
        color: `#F06767`,
        name: '0-40%',
    },
    {
        from: -100,
        to: -1,
        color: `#989898`,
        name: 'Данных нет',
    },
];

export const WorkplaceAttendanceDay = ({ date }: { date: string }): JSX.Element => {
    const { workplaceHist } = useTypeSelector((state) => state.workplace);
    const { selected, structures } = useTypeSelector((state) => state.structures);

    const { postWorkplaceHist } = useActions();
    useEffect(() => {
        postWorkplaceHist(selected || structures[0].structureId, date);
    }, [selected, date]);

    const dataFormatter = (data: WorkplaceHistParams) =>
        data.workPlaces
            ?.map(({ zone, dates }) => {
                const { name } = zone;
                console.log(dates, dateFormatter(dates[0].dt));
                return {
                    name,
                    data: dates.map(({ dt, value }) => ({
                        x: dateFormatter(dt),
                        y: value !== null ? value : -1,
                    })),
                };
            })
            .reverse();

    const chartOptions = {
        toolbar: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            heatmap: {
                enableShades: false,
                colorScale: {
                    ranges: COLOR_RANGES,
                },
            },
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: (value: number) => {
                    return value !== -1 ? value + '%' : 'Данных нет';
                },
            },
        },
    };

    return (
        <div className="card">
            <div className="card-header d-flex flex-sm-row flex-column justify-content-md-between align-items-start justify-content-start">
                <div>
                    <h4 className="card-title font-weight-bolder">Регистраторы за рабочим местом</h4>
                    <p className="card-subtitle mt-25 mb-25">{`Данные за ${formatDate(date)}`}</p>
                </div>
            </div>
            <div className="card-body">
                <Chart
                    chartOptions={chartOptions}
                    data={workplaceHist}
                    dataFormatter={dataFormatter}
                    chartSettings={{
                        type: EChartType.HeatMap,
                    }}
                    height={400}
                />
            </div>
        </div>
    );
};
