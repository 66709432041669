import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { formatDate } from '../../../../../utilits/formatDate';
import { Timer } from '../../../../../libs/icons';
import WorkedIcon from './WorkedIcon';
import NotWorkedIcon from './NotWorkedIcon';

interface EventsCountersProps {
    structureId: string;
    startDate: string;
    endDate: string | null;
}

interface Event {
    name: string;
    total: number;
    processed: number;
    skiped: number;
}

const EventsCounters: React.FC<EventsCountersProps> = ({ structureId, startDate, endDate }: EventsCountersProps) => {
    const [eventsTypes, setEventsTypes] = useState<Event[] | undefined>(undefined);

    useEffect(() => {
        axios
            .post(`/api/1.0/dashboard/events-counter/${structureId}`, {
                startDate: new Date(startDate).getTime(),
                endDate: endDate ? new Date(endDate).getTime() : undefined,
            })
            .then((res) => {
                setEventsTypes(res.data);
            });
    }, [structureId, startDate, endDate]);

    return (
        <div className="card">
            <div className="card-header d-flex flex-sm-row flex-column justify-content-md-between align-items-start justify-content-start">
                <div>
                    <h4 className="card-title font-weight-bolder">События по типам</h4>
                    <p className="card-subtitle mt-25 mb-25">
                        Данные за {formatDate(startDate)}
                        {endDate && <span> - {formatDate(endDate)}</span>}
                    </p>
                </div>
            </div>
            <div className="card-body pt-0">
                {eventsTypes &&
                    eventsTypes.map((event) => (
                        <div key={event.name} className="event-by-type">
                            <div className="event-description">{event.name}</div>
                            <div className="worked">
                                отработанные
                                <div className="badge-event">
                                    <WorkedIcon />
                                    <span>{event.processed}</span>
                                </div>
                            </div>
                            <div className="not-worked">
                                неотработанные
                                <div className="badge-event">
                                    <NotWorkedIcon />
                                    <span>{event.skiped}</span>
                                </div>
                            </div>
                            <div className="total">
                                всего
                                <Timer size={20} />
                                <span>{event.total}</span>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default EventsCounters;
