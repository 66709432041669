import { serviceAction, ServiceByCustomerState, ServiceState, ServiceActionTypes } from '../../types/service';

const initialState: ServiceState = {
    service: {
        structureId: '',
        zones: [],
        dates: [],
    },
    loading: false,
    error: null,
};

const initialStateByCustomer: ServiceByCustomerState = {
    service: {
        structureId: '',
        serviceZones: [],
    },
    loading: false,
    error: null,
};

export const serviceReducer = (state: ServiceState = initialState, action: serviceAction): ServiceState => {
    switch (action.type) {
        case ServiceActionTypes.GET_SERVICE_AVG: {
            return { ...state, loading: true };
        }
        case ServiceActionTypes.GET_SERVICE_AVG_SUCCESS: {
            return { ...state, loading: false, service: action.payload };
        }
        case ServiceActionTypes.GET_SERVICE_AVG_ERROR: {
            return { ...state, loading: false, error: action.payload };
        }
        case ServiceActionTypes.POST_SERVICE_AVG: {
            return { ...state, loading: true };
        }
        case ServiceActionTypes.POST_SERVICE_AVG_SUCCESS: {
            return { ...state, loading: false, service: action.payload };
        }
        case ServiceActionTypes.POST_SERVICE_AVG_ERROR: {
            return { ...state, loading: false, error: action.payload };
        }
        default:
            return state;
    }
};

export const serviceByCustomerReducer = (
    state: ServiceByCustomerState = initialStateByCustomer,
    action: serviceAction,
): ServiceByCustomerState => {
    switch (action.type) {
        case ServiceActionTypes.POST_SERVICE_BY_CUSTOMER: {
            return { ...state, loading: true };
        }
        case ServiceActionTypes.POST_SERVICE_BY_CUSTOMER_SUCCESS: {
            return { ...state, loading: false, service: action.payload };
        }
        case ServiceActionTypes.POST_SERVICE_BY_CUSTOMER_ERROR: {
            return { ...state, loading: false, error: action.payload };
        }
        default:
            return state;
    }
};
