import React from 'react';
import { Clock, Edit, EyeOff, MoreVertical, PlusCircle, Trash } from 'react-feather';
import { Select, TableRow, Line } from '@bigdata/ui-kit';
import { EscTableEmployeeParams } from '../../../types/escTableEmployee';
import { formatPhoneNumber } from '../../../utilits/formatPhoneNumber';

interface CreateTableDataArgs {
    data: EscTableEmployeeParams[];
    setData: React.Dispatch<(data: EscTableEmployeeParams[]) => EscTableEmployeeParams[]>;
    showActionsBar?: number;
    setShowActionsBar: React.Dispatch<number | undefined>;
    showFIO?: number;
    setShowFIO: React.Dispatch<number | undefined>;
    showPhone?: number;
    setShowPhone: React.Dispatch<number | undefined>;
    editItem?: number;
    setDeleteRow: React.Dispatch<number | undefined>;
    setEditItem: React.Dispatch<number | undefined>;
    setAddRow: React.Dispatch<number | undefined>;
    setEditedRow: React.Dispatch<EscTableEmployeeParams>;
    editRow: EscTableEmployeeParams | undefined;
    setChangesSaved: React.Dispatch<boolean>;
}

function createTableData({
    data,
    showActionsBar,
    setShowActionsBar,
    showFIO,
    setShowFIO,
    showPhone,
    setShowPhone,
    editItem,
    setDeleteRow,
    setEditItem,
    setAddRow,
    editRow,
    setEditedRow,
    setChangesSaved,
}: CreateTableDataArgs): TableRow[] {
    const tableData = [];

    const changeData =
        (name: string) =>
        (value: string | number): void => {
            if (editItem !== undefined) {
                const updatedRow = editRow ? { ...editRow, [name]: value } : { ...data[editItem], [name]: value };
                setEditedRow(updatedRow);
            }
            return;
        };

    for (let i = 0; i < data.length; i++) {
        tableData.push({
            escalation_level: {
                text: data[i].escalation_level ? data[i].escalation_level : '',
                render(text: string) {
                    return editItem === i ? (
                        <Select
                            optionsArr={[1, 2, 3, 4]}
                            selectedValue={editRow?.escalation_level || text}
                            onChangeAmount={changeData('escalation_level')}
                        />
                    ) : (
                        <span>{text}</span>
                    );
                },
            },
            position: {
                text: data[i].position,
                render(text: string) {
                    return <span>{text}</span>;
                },
            },
            time_reaction: {
                text: data[i].time_reaction,
                render(text: string) {
                    return (
                        text && (
                            <div className="time-badge">
                                <Clock size={12} />
                                {text}
                            </div>
                        )
                    );
                },
            },
            name: {
                text: data[i].name,
                render(text: string) {
                    return editItem === i ? (
                        <span>{text}</span>
                    ) : (
                        <div className="phone-field">
                            {showFIO !== i ? (
                                <div className="hide-field" onClick={() => setShowFIO(i)}>
                                    <EyeOff size={12} /> ПОСМОТРЕТЬ
                                </div>
                            ) : (
                                <div>{text}</div>
                            )}
                        </div>
                    );
                },
            },
            phone: {
                text: data[i].phone,
                render(text: string) {
                    return editItem === i ? (
                        <div>{formatPhoneNumber(text)}</div>
                    ) : (
                        <div className="phone-field">
                            {showPhone !== i ? (
                                <div className="hide-field" onClick={() => setShowPhone(i)}>
                                    <EyeOff size={12} /> ПОСМОТРЕТЬ
                                </div>
                            ) : (
                                <div>{formatPhoneNumber(text)}</div>
                            )}
                        </div>
                    );
                },
            },
            actions: {
                text: '',
                render() {
                    return (
                        <div className="actions-field">
                            <MoreVertical
                                size={18}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowActionsBar(i);
                                }}
                            />
                            {showActionsBar === i && (
                                <div className="actions-bar">
                                    <div
                                        className="action-item"
                                        onClick={() => {
                                            editItem !== undefined && setChangesSaved(true);
                                            setEditItem(i);
                                        }}
                                    >
                                        <Edit size={16} />
                                        <span className="title-item">
                                            {editItem === i ? 'Сохранить' : 'Редактировать'}
                                        </span>
                                    </div>
                                    <div className="action-item" onClick={() => setAddRow(i)}>
                                        <PlusCircle size={16} />
                                        <span className="title-item">Добавить</span>
                                    </div>
                                    <Line height={1} width={149} color={'#DFE0E5'} />
                                    <div className="action-item delete-action" onClick={() => setDeleteRow(i)}>
                                        <Trash size={16} />
                                        <span className="title-item">УДАЛИТЬ</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                },
            },
        });
    }

    return tableData;
}

export default createTableData;
