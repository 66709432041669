import { workplaceAction, WebsocketsConnectState, WebsocketsConnectActionTypes } from '../../types/websocketsConnect';

const initialState: WebsocketsConnectState = {
    error: null,
    loading: false,
    connected: false,
    messages: [],
};

export const websocketsConnectReducer = (
    state: WebsocketsConnectState = initialState,
    action: workplaceAction,
): WebsocketsConnectState => {
    switch (action.type) {
        case WebsocketsConnectActionTypes.GET_WEBSOCKETS_CONNECT: {
            return { ...state, loading: true };
        }
        case WebsocketsConnectActionTypes.GET_WEBSOCKETS_CONNECT_SUCCESS: {
            return { ...state, loading: false, connected: true };
        }
        case WebsocketsConnectActionTypes.GET_WEBSOCKETS_CONNECT_ERROR: {
            return { ...state, loading: false, error: action.payload };
        }
        case WebsocketsConnectActionTypes.GET_MESSAGE: {
            return { ...state, messages: [...state.messages, action.payload] };
        }
        default:
            return state;
    }
};
