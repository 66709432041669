import React, { useEffect, useState } from 'react';
import { Users } from '../../../../libs/icons';
import axios from 'axios';
import { Url } from '../../../../types/url';
import { useTypeSelector } from '../../../../hooks/useTypeSelector';

export type UserCounterData = {
    result: number;
    structureId: string;
    zones: {
        name: string;
        count: number;
    }[];
};

const UserCounter: React.FC = () => {
    const { dateFilter } = useTypeSelector((state) => state.time);
    const { selected: structureId } = useTypeSelector((state) => state.structures);
    const [userCounter, setUserCounter] = useState<UserCounterData>({} as UserCounterData);

    useEffect(() => {
        axios
            .post(`${Url.Server}/api/1.0/dashboard/service-zone-user-counter/${structureId}`, {
                startDate: new Date(dateFilter.startDate).getTime(),
                endDate: dateFilter.endDate ? new Date(dateFilter.endDate).getTime() : null,
            })
            .then(({ data }) => {
                setUserCounter(data);
            });
    }, [dateFilter, structureId]);

    return (
        <div className="card d-flex card-body pl-3 user-counter">
            <h4 className="mt-50 mb-2 card-title">Посетители в зоне обслуживания</h4>
            <div className="zones mt-2 mb-1">
                {userCounter?.zones?.map((zone) => (
                    <div key={zone.name} className="zone-item">
                        <div className="row">
                            <div className="col-1 icon-container">
                                <div className="round-icon count-users-icon">
                                    <Users size={30} color="#191C22" />
                                </div>
                            </div>
                            <div className="col">
                                <div className="row">
                                    <div className="col text-muted zone-name">{zone.name}</div>
                                </div>
                                <div className="row mt-25">
                                    <div className="col">
                                        <h1 className="font-weight-bolder text-grey70">{zone.count}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="user-counter-footer">
                <div className="horizontal-line" />
                {userCounter?.zones?.length ? (
                    <p>
                        Итог:<div>{userCounter.result}</div>
                    </p>
                ) : (
                    <p>Данных нет</p>
                )}
            </div>
        </div>
    );
};

export default React.memo(UserCounter);
