import { receptionAction, ReceptionActionTypes } from '../../types/reception';
import { Dispatch } from 'react';
import axios from 'axios';
import { Url } from '../../types/url';
import { dateStringToTimestamp } from '../../utilits/formatDate';
export const getReceptionCount = (structureID: string) => {
    return async (dispatch: Dispatch<receptionAction>): Promise<void> => {
        try {
            dispatch({
                type: ReceptionActionTypes.GET_RECEPTION_CUSTOMER_COUNT,
            });

            const response = await axios.get(`${Url.Server}/api/1.0/dashboard/reception-customer-count/${structureID}`);
            dispatch({
                type: ReceptionActionTypes.GET_RECEPTION_CUSTOMER_COUNT_SUCCESS,
                payload: response.data,
            });
        } catch (e) {
            dispatch({
                type: ReceptionActionTypes.GET_RECEPTION_CUSTOMER_COUNT_ERROR,
                payload: 'Произошла ошибка получения данных регистратуры',
            });
        }
    };
};

export const postReceptionCount = (structureID: string, startDate: string, endDate: null | string) => {
    return async (dispatch: Dispatch<receptionAction>): Promise<void> => {
        try {
            dispatch({
                type: ReceptionActionTypes.POST_RECEPTION_CUSTOMER_COUNT,
            });

            const response = await axios.post(
                `${Url.Server}/api/1.0/dashboard/reception-customer-count/${structureID}`,
                {
                    startDate: dateStringToTimestamp(startDate),
                    endDate: endDate ? dateStringToTimestamp(endDate) : endDate,
                },
            );
            dispatch({
                type: ReceptionActionTypes.POST_RECEPTION_CUSTOMER_COUNT_SUCCESS,
                payload: response.data,
            });
        } catch (e) {
            dispatch({
                type: ReceptionActionTypes.POST_RECEPTION_CUSTOMER_COUNT_ERROR,
                payload: 'Произошла ошибка получения данных пользователей',
            });
        }
    };
};

export const postReceptionCountRoom = (structureID: string, startDate: string, endDate: null | string) => {
    return async (dispatch: Dispatch<receptionAction>): Promise<void> => {
        try {
            dispatch({
                type: ReceptionActionTypes.POST_RECEPTION_CUSTOMER_COUNT_ROOM,
            });

            const response = await axios.post(
                `${Url.Server}/api/1.0/dashboard/emergency-customer-count/${structureID}`,
                {
                    startDate: dateStringToTimestamp(startDate),
                    endDate: endDate ? dateStringToTimestamp(endDate) : endDate,
                },
            );
            dispatch({
                type: ReceptionActionTypes.POST_RECEPTION_CUSTOMER_COUNT_ROOM_SUCCESS,
                payload: response.data,
            });
        } catch (e) {
            dispatch({
                type: ReceptionActionTypes.POST_RECEPTION_CUSTOMER_COUNT_ROOM_ERROR,
                payload: 'Произошла ошибка получения данных',
            });
        }
    };
};
