import { authAction, AuthActionTypes } from '../../types/auth';
import { Dispatch } from 'react';
import { getAuthDomain } from '../../libs/bx24';
import { Url } from '../../types/url';

export const fetchAuth = () => {
    return async (dispatch: Dispatch<authAction>): Promise<void> => {
        try {
            dispatch({
                type: AuthActionTypes.AUTH,
            });
            getAuthDomain().then((domain) => {
                domain === Url.Bitrix24
                    ? dispatch({ type: AuthActionTypes.AUTH_SUCCESS, payload: domain })
                    : dispatch({ type: AuthActionTypes.AUTH_ERROR, payload: '' });
            });
        } catch (e) {
            dispatch({ type: AuthActionTypes.AUTH_ERROR, payload: 'произошла ошибка авторизации' });
        }
    };
};
