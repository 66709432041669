import React from 'react';
interface Props {
    color?: string;
    size?: string;
}
const Timer = ({ color = '#6E6B7B', size = '24' }: Props): JSX.Element => {
    return (
        <svg width={size} height={size} viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 4C6.9 4 2 8.9 2 15C2 21.1 6.9 26 13 26C19.1 26 24 21.1 24 15C24 8.9 19.1 4 13 4ZM13 24C8 24 4 20 4 15C4 10 8 6 13 6C18 6 22 10 22 15C22 20 18 24 13 24ZM16.7 18.7C17.1 18.3 17.1 17.7 16.7 17.3L14 14.6V9C14 8.4 13.6 8 13 8C12.4 8 12 8.4 12 9V15C12 15.3 12.1 15.5 12.3 15.7L15.3 18.7C15.5 18.9 15.7 19 16 19C16.3 19 16.5 18.9 16.7 18.7Z"
                fill={color}
            />
            <path
                d="M10.3788 1.19067H15.6212"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Timer;
