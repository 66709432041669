import { employeeAction, EmployeeParams, EmployeeResponseParams, EmployeesActionTypes } from '../../types/employee';
import { Dispatch } from 'react';
import axios from 'axios';
import { Url } from '../../types/url';

export const getEmployees = (structureId: string) => {
    return async (dispatch: Dispatch<employeeAction>): Promise<void> => {
        try {
            dispatch({
                type: EmployeesActionTypes.GET_EMPLOYEES,
            });

            const response = await axios.get(`${Url.Server}/api/1.0/structure/${structureId}/employees`);
            const employees = response.data.map((el: EmployeeResponseParams) => {
                return {
                    employee_id: el.employeeId,
                    name: el.name,
                    phone: el.phoneNumber,
                    position: el.function,
                };
            });
            dispatch({
                type: EmployeesActionTypes.GET_EMPLOYEES_SUCCESS,
                payload: employees,
            });
        } catch (e) {
            dispatch({
                type: EmployeesActionTypes.GET_EMPLOYEES_ERROR,
                payload: 'Произошла ошибка получения ответственных сотрудников',
            });
        }
    };
};

export const postEmployees = (employee: EmployeeParams, structureId: string) => {
    return async (dispatch: Dispatch<employeeAction>): Promise<void> => {
        try {
            dispatch({
                type: EmployeesActionTypes.POST_EMPLOYEES,
            });
            const regexp = /\d{1,}/g;
            const phone = employee.phone.match(regexp);
            const response = await axios.post(`${Url.Server}/api/1.0/structure/${structureId}/employee`, {
                name: employee.name,
                function: employee.position,
                phoneNumber: phone ? phone.join('') : '',
            });
            dispatch({
                type: EmployeesActionTypes.POST_EMPLOYEES_SUCCESS,
                payload: { ...employee, employee_id: response.data },
            });
        } catch (e) {
            dispatch({
                type: EmployeesActionTypes.POST_EMPLOYEES_ERROR,
                payload: 'Произошла ошибка создания нового ответственного сотрудника',
            });
        }
    };
};

export const putEmployee = (employee: EmployeeParams, structureId: string) => {
    return async (dispatch: Dispatch<employeeAction>): Promise<void> => {
        try {
            dispatch({
                type: EmployeesActionTypes.PUT_EMPLOYEES,
            });
            await axios.put(`${Url.Server}/api/1.0/structure/${structureId}/employee`, {
                employeeId: employee.employee_id,
                name: employee.name,
                function: employee.position,
                phoneNumber: employee.phone,
            });

            dispatch({
                type: EmployeesActionTypes.PUT_EMPLOYEES_SUCCESS,
                payload: employee,
            });
        } catch (e) {
            dispatch({
                type: EmployeesActionTypes.PUT_EMPLOYEES_ERROR,
                payload: 'Произошла ошибка редактирования данных ответственного сотрудника',
            });
        }
    };
};

export const deleteEmployee = (employeeId: string, structureId: string) => {
    return async (dispatch: Dispatch<employeeAction>): Promise<void> => {
        try {
            dispatch({
                type: EmployeesActionTypes.DELETE_EMPLOYEE,
            });
            await axios.delete(`${Url.Server}/api/1.0/structure/${structureId}/employee/${employeeId}`);
            dispatch({
                type: EmployeesActionTypes.DELETE_EMPLOYEE_SUCCESS,
                payload: employeeId,
            });
        } catch (e) {
            dispatch({
                type: EmployeesActionTypes.DELETE_EMPLOYEE_ERROR,
                payload: 'Произошла ошибка удаления ответственного сотрудника',
            });
        }
    };
};
