import { structuresAction, GetStructuresActionTypes } from '../../types/structures';
import { Dispatch } from 'react';
import axios from 'axios';
import { Url } from '../../types/url';

export const getStructures = () => {
    return async (dispatch: Dispatch<structuresAction>): Promise<void> => {
        try {
            dispatch({
                type: GetStructuresActionTypes.GET_STRUCTURES,
            });
            const response = await axios.get(`${Url.Server}/api/1.0/dashboard/structure`);
            dispatch({ type: GetStructuresActionTypes.GET_STRUCTURES_SUCCESS, payload: response.data });
        } catch (e) {
            dispatch({
                type: GetStructuresActionTypes.GET_STRUCTURES_ERROR,
                payload: 'Произошла ошибка загрузки объектов',
            });
        }
    };
};

export function setSelectedStructure(structureID: string): structuresAction {
    return { type: GetStructuresActionTypes.SET_SELECTED_STRUCTURE, payload: structureID };
}
