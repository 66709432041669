export interface EmployeeParams {
    employee_id: string;
    name: string;
    phone: string;
    position: string;
}

export interface EmployeeResponseParams {
    employeeId: string;
    name: string;
    phoneNumber: string;
    function: string;
}

export interface EmployeesState {
    employees: EmployeeParams[];
    loading: boolean;
    error: null | string;
}

export enum EmployeesActionTypes {
    GET_EMPLOYEES = 'GET_EMPLOYEES',
    GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS',
    GET_EMPLOYEES_ERROR = 'GET_EMPLOYEES_ERROR',

    POST_EMPLOYEES = 'POST_EMPLOYEES',
    POST_EMPLOYEES_SUCCESS = 'POST_EMPLOYEES_SUCCESS',
    POST_EMPLOYEES_ERROR = 'POST_EMPLOYEES_ERROR',

    PUT_EMPLOYEES = 'PUT_EMPLOYEES',
    PUT_EMPLOYEES_SUCCESS = 'PUT_EMPLOYEES_SUCCESS',
    PUT_EMPLOYEES_ERROR = 'PUT_EMPLOYEES_ERROR',

    DELETE_EMPLOYEE = 'DELETE_EMPLOYEE',
    DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS',
    DELETE_EMPLOYEE_ERROR = 'DELETE_EMPLOYEE_ERROR',
}

interface GetEmployeesAction {
    type: EmployeesActionTypes.GET_EMPLOYEES;
}

interface GetEmployeesSuccessAction {
    type: EmployeesActionTypes.GET_EMPLOYEES_SUCCESS;
    payload: EmployeeParams[];
}

interface GetEmployeesErrorAction {
    type: EmployeesActionTypes.GET_EMPLOYEES_ERROR;
    payload: string;
}

interface PostEmployeesAction {
    type: EmployeesActionTypes.POST_EMPLOYEES;
}

interface PostEmployeesSuccessAction {
    type: EmployeesActionTypes.POST_EMPLOYEES_SUCCESS;
    payload: EmployeeParams;
}

interface PostEmployeesErrorAction {
    type: EmployeesActionTypes.POST_EMPLOYEES_ERROR;
    payload: string;
}

interface PutEmployeesAction {
    type: EmployeesActionTypes.PUT_EMPLOYEES;
}

interface PutEmployeesSuccessAction {
    type: EmployeesActionTypes.PUT_EMPLOYEES_SUCCESS;
    payload: EmployeeParams;
}

interface PutEmployeesErrorAction {
    type: EmployeesActionTypes.PUT_EMPLOYEES_ERROR;
    payload: string;
}

interface DeleteEmployeesAction {
    type: EmployeesActionTypes.DELETE_EMPLOYEE;
}

interface DeleteEmployeesSuccessAction {
    type: EmployeesActionTypes.DELETE_EMPLOYEE_SUCCESS;
    payload: string;
}

interface DeleteEmployeesErrorAction {
    type: EmployeesActionTypes.DELETE_EMPLOYEE_ERROR;
    payload: string;
}
export type employeeAction =
    | GetEmployeesAction
    | GetEmployeesSuccessAction
    | GetEmployeesErrorAction
    | PostEmployeesAction
    | PostEmployeesSuccessAction
    | PostEmployeesErrorAction
    | PutEmployeesAction
    | PutEmployeesSuccessAction
    | PutEmployeesErrorAction
    | DeleteEmployeesAction
    | DeleteEmployeesSuccessAction
    | DeleteEmployeesErrorAction;
