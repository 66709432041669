export interface WorkplaceParams {
    structureId: string;
    value: number;
}

export interface WorkplaceHistParams {
    structureId: string;
    workPlaces:
        | []
        | [
              {
                  zone: {
                      zoneId: string;
                      name: string;
                  };
                  dates: [
                      {
                          dt: string;
                          value: number;
                      },
                  ];
              },
          ];
}

export interface WorkplaceNobodyParams {
    structureId: string;
    // datetime: string;
    nobodyTime: number | null;
}

export interface WorkplaceState {
    workplace: WorkplaceParams;
    workplaceHist: WorkplaceHistParams;
    workplaceNobody: WorkplaceNobodyParams;
    loading: boolean;
    error: null | string;
}

export enum WorkplaceActionTypes {
    GET_WORKPLACE_COUNT = 'GET_WORKPLACE_COUNT',
    GET_WORKPLACE_COUNT_SUCCESS = 'GET_WORKPLACE_COUNT_SUCCESS',
    GET_WORKPLACE_COUNT_ERROR = 'GET_WORKPLACE_COUNT_ERROR',

    POST_WORKPLACE_HIST = 'POST_WORKPLACE_HIST',
    POST_WORKPLACE_HIST_SUCCESS = 'POST_WORKPLACE_HIST_SUCCESS',
    POST_WORKPLACE_HIST_ERROR = 'POST_WORKPLACE_HIST_ERROR',

    GET_WORKPLACE_NOBODY = 'GET_WORKPLACE_NOBODY',
    GET_WORKPLACE_NOBODY_SUCCESS = 'GET_WORKPLACE_NOBODY_SUCCESS',
    GET_WORKPLACE_NOBODY_ERROR = 'GET_WORKPLACE_NOBODY_ERROR',

    POST_WORKPLACE_NOBODY_HIST = 'POST_WORKPLACE_NOBODY_HIST',
    POST_WORKPLACE_NOBODY_HIST_SUCCESS = 'POST_WORKPLACE_NOBODY_HIST_SUCCESS',
    POST_WORKPLACE_NOBODY_HIST_ERROR = 'POST_WORKPLACE_NOBODY_HIST_ERROR',
}

interface GetWorkplaceCountAction {
    type: WorkplaceActionTypes.GET_WORKPLACE_COUNT;
}

interface GetWorkplaceCountSuccessAction {
    type: WorkplaceActionTypes.GET_WORKPLACE_COUNT_SUCCESS;
    payload: WorkplaceParams;
}

interface GetWorkplaceCountErrorAction {
    type: WorkplaceActionTypes.GET_WORKPLACE_COUNT_ERROR;
    payload: string;
}

interface PostWorkplaceHistAction {
    type: WorkplaceActionTypes.POST_WORKPLACE_HIST;
}

interface PostWorkplaceHistSuccessAction {
    type: WorkplaceActionTypes.POST_WORKPLACE_HIST_SUCCESS;
    payload: WorkplaceHistParams;
}

interface PostWorkplaceHistErrorAction {
    type: WorkplaceActionTypes.POST_WORKPLACE_HIST_ERROR;
    payload: string;
}

interface GetWorkplaceNobodyAction {
    type: WorkplaceActionTypes.GET_WORKPLACE_NOBODY;
}

interface GetWorkplaceNobodySuccessAction {
    type: WorkplaceActionTypes.GET_WORKPLACE_NOBODY_SUCCESS;
    payload: WorkplaceNobodyParams;
}

interface GetWorkplaceNobodyErrorAction {
    type: WorkplaceActionTypes.GET_WORKPLACE_NOBODY_ERROR;
    payload: string;
}

interface PostWorkplaceNobodyHistAction {
    type: WorkplaceActionTypes.POST_WORKPLACE_NOBODY_HIST;
}

interface PostWorkplaceNobodyHistSuccessAction {
    type: WorkplaceActionTypes.POST_WORKPLACE_NOBODY_HIST_SUCCESS;
    payload: WorkplaceNobodyParams;
}

interface PostWorkplaceNobodyHistErrorAction {
    type: WorkplaceActionTypes.POST_WORKPLACE_NOBODY_HIST_ERROR;
    payload: string;
}

export type workplaceAction =
    | GetWorkplaceCountAction
    | GetWorkplaceCountSuccessAction
    | GetWorkplaceCountErrorAction
    | PostWorkplaceHistAction
    | PostWorkplaceHistSuccessAction
    | PostWorkplaceHistErrorAction
    | GetWorkplaceNobodyAction
    | GetWorkplaceNobodySuccessAction
    | GetWorkplaceNobodyErrorAction
    | PostWorkplaceNobodyHistAction
    | PostWorkplaceNobodyHistSuccessAction
    | PostWorkplaceNobodyHistErrorAction;
