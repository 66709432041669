import React from 'react';

export const ScrollButton = (): JSX.Element => {
    return (
        <button
            className="btn btn-primary btn-icon scroll-top waves-effect waves-float waves-light"
            onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            type="button"
        >
            <i data-feather="arrow-up" />
        </button>
    );
};
