import React from 'react';
interface Props {
    color?: string;
    size?: string;
}
const Infomat = ({ color = '#6E6B7B', size = '24' }: Props): JSX.Element => {
    return (
        <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.4188 12.1295C20.5778 11.765 20.9377 11.5293 21.3354 11.5293H30.4818C31.034 11.5293 31.4818 11.977 31.4818 12.5293V25.9501C31.4818 26.2081 31.3821 26.456 31.2035 26.6422L27.3968 30.6122V35.1551H30.4818C31.034 35.1551 31.4818 35.6028 31.4818 36.1551C31.4818 36.7074 31.034 37.1551 30.4818 37.1551H20.3976C19.9195 37.1551 19.5083 36.8166 19.4163 36.3474L16.5118 21.53C16.4728 21.3306 16.4953 21.1241 16.5766 20.9378L20.4188 12.1295ZM25.3968 35.1551V30.2102C25.3968 30.1968 25.3971 30.1833 25.3976 30.1699L25.7137 22.3376H18.7082L21.2207 35.1551H25.3968ZM19.0204 20.3376H26.0922L28.9728 13.5293H21.9902L19.0204 20.3376ZM29.4818 17.4591L27.7467 21.5597L27.5025 27.6123L29.4818 25.5481V17.4591Z"
                fill={color}
            />
        </svg>
    );
};

export default Infomat;
