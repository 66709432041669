import { workplaceAction, WebsocketsConnectActionTypes, MessageProps } from '../../types/websocketsConnect';
import { Dispatch } from 'react';
import { clientConnection } from '../../components/Sockets/Sockets';

export const websocketConnect = () => {
    return async (dispatch: Dispatch<workplaceAction>): Promise<void> => {
        dispatch({
            type: WebsocketsConnectActionTypes.GET_WEBSOCKETS_CONNECT,
        });
        const client = clientConnection();
        client.onopen = () => {
            dispatch({
                type: WebsocketsConnectActionTypes.GET_WEBSOCKETS_CONNECT_SUCCESS,
            });
        };
        client.close = () => {
            dispatch({
                type: WebsocketsConnectActionTypes.GET_WEBSOCKETS_CONNECT_ERROR,
                payload: 'Server disconnected',
            });
        };
        client.onerror = () => {
            dispatch({
                type: WebsocketsConnectActionTypes.GET_WEBSOCKETS_CONNECT_ERROR,
                payload: 'Cannot connect to websocket server',
            });
        };

        client.onmessage = (message) => {
            const data: MessageProps = JSON.parse(message.data.toString());
            getMessage(data);
        };
    };
};

export const getMessage = (message: MessageProps) => {
    return async (dispatch: Dispatch<workplaceAction>): Promise<void> => {
        dispatch({
            type: WebsocketsConnectActionTypes.GET_MESSAGE,
            payload: message,
        });
    };
};
