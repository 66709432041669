import React, { useEffect, useState } from 'react';
import './Tab.scss';
import { useTypeSelector } from '../../../../hooks/useTypeSelector';
import { useActions } from '../../../../hooks/useActions';
import { Activity, AlertCircle, FileText, Users, Clock, ArrowRight } from 'react-feather';
import { Hospital, Camera } from '../../../../libs/icons';
import { Link } from 'react-router-dom';
import { dateFormatter } from '../../../../utilits/formatDate';
import { CameraState } from '../../../../types/structures';

export const Tab = (): JSX.Element => {
    const { events } = useTypeSelector((state) => state.events);
    const { structures, selected } = useTypeSelector((state) => state.structures);
    const { setSelectedStructure } = useActions();
    const structure = structures.find(({ structureId }) => structureId === selected);
    const { getEvents, getEmployees } = useActions();
    const [camerasAmount, setCamerasAmount] = useState(0);

    useEffect(() => {
        if (selected) {
            getEvents(selected);
            getEmployees(selected);
        }
        const _cameras: Record<string, ''> = {};
        if (structure) {
            Object.entries(structure?.cameras).forEach((el: [string, CameraState[]]) => {
                el[1].forEach((el) => (_cameras[el.id] = ''));
            });
        }
        setCamerasAmount(Object.keys(_cameras).length);
    }, [selected]);

    const alertClass = events.events.length < 10 ? 'success' : events.events.length < 20 ? 'warning' : 'danger';

    const closeModal: React.MouseEventHandler<HTMLDivElement> = ({ target }) => {
        const elem = target as HTMLElement;
        if (elem.id === 'modal-slide-in') {
            setSelectedStructure('');
        }
    };

    //TODO: REMOVE SOURCE CODE AND CHANGE TO REACT COMPONENT LIB, MAYBE REACT BOOTSTRAP MODALS
    return (
        <div
            className={'modal modal-slide-in update-item-sidebar fade show'}
            style={{ display: selected ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.5)' }}
            id={'modal-slide-in'}
            onClick={closeModal}
        >
            <div className="modal-dialog sidebar-lg">
                <div className="modal-content p-0">
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => setSelectedStructure('')}
                    >
                        ×
                    </button>
                    <div className="modal-header mb-1">
                        <h5 className="modal-title">{structure?.name}</h5>
                    </div>
                    <div className="modal-body flex-grow-1">
                        <ul className="nav nav-tabs tabs-line">
                            <li className="nav-item">
                                <a
                                    href={'#tab-update'}
                                    className="btn btn-flat-beeline nav-link nav-link-beeline nav-link-update active"
                                    data-toggle="tab"
                                >
                                    <FileText />
                                    <span className="align-middle">Детали</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="btn btn-flat-beeline nav-link nav-link-beeline nav-link-update"
                                    data-toggle="tab"
                                    href={'#tab-activity'}
                                >
                                    <Activity />
                                    <span className="align-middle">Активность</span>
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content mt-2">
                            <div className="tab-pane tab-pane-update fade show active" id="tab-update" role="tabpanel">
                                <div className="align-items-center  d-flex flex-row pb-2">
                                    <div>
                                        <Hospital />
                                    </div>
                                    <h5 className="mb-0 pl-2">
                                        <span>{structure?.description}</span>
                                    </h5>
                                </div>
                                <div className="d-flex flex-wrap pb-1">
                                    <p>
                                        <strong className="text-uppercase clinic-info-title">Адрес:</strong>
                                        <br /> <span className="clinic-info-text">{structure?.address}</span>
                                    </p>
                                    {/*<p>*/}
                                    {/*    <strong className="text-uppercase clinic-info-title">Время работы:</strong>*/}
                                    {/*    <br /> <span className="clinic-info-text">с 08:00 до 18:00</span>*/}
                                    {/*</p>*/}
                                </div>
                                <hr className="p-1" />
                                <div className="align-items-center d-flex flex-row pb-2 responsible-persons">
                                    <Users color="#2C2D37" />
                                    <h5 className="mb-0 pl-2">
                                        <span>Ответственные лица: 0{/*{employees.length}*/}</span>
                                    </h5>
                                </div>
                                {/*{employees.length > 0 &&*/}
                                {/*    employees.map((el) => {*/}
                                {/*        return (*/}
                                {/*            <div*/}
                                {/*                key={el.name + '-' + el.phone}*/}
                                {/*                className="align-items-center d-flex flex-row pb-2 pl-1 users-list"*/}
                                {/*            >*/}
                                {/*                <div style={{ paddingLeft: '32px', width: '232px' }}>*/}
                                {/*                    <span className="position">{el.position}</span>*/}
                                {/*                    <br />*/}
                                {/*                    <span*/}
                                {/*                        className="text-beeline cursor-pointer name"*/}
                                {/*                        onClick={() => openUserCard(Params.DemoUserID.toString())}*/}
                                {/*                    >*/}
                                {/*                        {el.name}*/}
                                {/*                    </span>*/}
                                {/*                </div>*/}
                                {/*                <div className="mb-0 pl-2">*/}
                                {/*                    <button*/}
                                {/*                        onClick={() => openMessenger(Params.DemoUserID)}*/}
                                {/*                        className="btn btn-icon btn-icon-beeline rounded-circle waves-effect"*/}
                                {/*                    >*/}
                                {/*                        <MessageCircle color="#1790FF" />*/}
                                {/*                    </button>*/}
                                {/*                </div>*/}
                                {/*                <div className="mb-0 pl-2">*/}
                                {/*                    <button*/}
                                {/*                        onClick={() => callTo(Params.DemoUserID, false)}*/}
                                {/*                        className="btn btn-icon btn-icon-beeline rounded-circle waves-effect"*/}
                                {/*                    >*/}
                                {/*                        <PhoneCall color="#1790FF" />*/}
                                {/*                    </button>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        );*/}
                                {/*    })}*/}
                                <hr className="p-1" />
                                <div className="align-items-center  d-flex flex-row connected-cameras">
                                    <Camera color="#2C2D37" />
                                    <h5 className="mb-0 pl-2">
                                        <span>Подключено камер: {camerasAmount}</span>
                                    </h5>
                                </div>
                            </div>
                            <div className="tab-pane tab-pane-activity fade" id="tab-activity" role="tabpanel">
                                <div className={'card border-' + alertClass}>
                                    <div className="card-body align-items-center  d-flex flex-row">
                                        <div className={'avatar p-50 w-auto bg-light-' + alertClass}>
                                            <div className="avatar-content">
                                                <AlertCircle />
                                            </div>
                                        </div>
                                        <span className="right-corner-tab">
                                            {new Date().toLocaleString('ru', {
                                                month: 'long',
                                                day: 'numeric',
                                            })}
                                        </span>
                                        <h1 className="font-weight-bolder pr-2 pl-2">{events.events.length}</h1>
                                        <h5 className="card-text font-weight-normal">Инцидентов за час</h5>
                                    </div>
                                </div>
                                {events.events.length > 0 && (
                                    <div className="events-list overflow-auto">
                                        {events.events.map(({ datetime, event, zone }, index) => {
                                            return (
                                                <div className="media mb-1 pb-1" key={index}>
                                                    <div className="media-body">
                                                        <Clock style={{ paddingBottom: '2px' }} width="12" />{' '}
                                                        <span className="text-muted">{dateFormatter(datetime)}</span>
                                                        <p className="mb-0">
                                                            <span className="font-weight-bold">{zone.name}: </span>
                                                            {event.description}
                                                        </p>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <Link to={'/dashboard'} className="btn bg-beeline-color m-2">
                        Детальный отчет <ArrowRight width="16" />
                    </Link>
                </div>
            </div>
        </div>
    );
};
