import React from 'react';
import './Registration.scss';
import { WorkplaceAttendanceTotal } from './components/WorkplaceAttendanceTotal';
import { WorkplaceAttendanceDay } from './components/WorkplaceAttendanceDay';
import { Calendar } from '../../../components/Calendar/Calendar';
import { EventsHist } from './components/EventsHist';
import { useTypeSelector } from '../../../hooks/useTypeSelector';
import { WorkplaceAttendancePeriod } from './components/WorkplaceAttendancePeriod';
import ServiceTimeAvg from './components/ServiceTimeAvg';
import ServiceTimeByCustomer from './components/ServiceTimeByCustomer';
import { ServiceCountHist } from './components/ServiceCountHist';
import UserCounter from './components/UserCounter';
import UploadReport from './components/UploadReport';
import EventsCounters from './components/EventCounters/EventsCounters';

export const Registration = (): JSX.Element => {
    const { dateFilter } = useTypeSelector((state) => state.time);
    const { startDate, endDate } = dateFilter;

    const { selected } = useTypeSelector((state) => state.structures);

    return (
        <div className="registration">
            <div className="row">
                <div className="col workplace-attendance">
                    <WorkplaceAttendanceTotal />
                </div>
                <div className="col">
                    <UserCounter />
                </div>
                <div className="col calendar-card">
                    <div className="float-right">
                        <Calendar height={380} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {endDate === null ? (
                        <WorkplaceAttendanceDay date={startDate} />
                    ) : (
                        <WorkplaceAttendancePeriod startDate={startDate} endDate={endDate} />
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {endDate === null ? (
                        <ServiceTimeByCustomer date={startDate} />
                    ) : (
                        <ServiceTimeAvg startDate={startDate} endDate={endDate} />
                    )}
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <ServiceCountHist />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <ServiceCountHist type={'room'} />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <UploadReport type={'consolidated-report'} title={'Общий агрегированный отчет'} />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <EventsHist />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <EventsCounters structureId={selected} startDate={startDate} endDate={endDate} />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <UploadReport type={'event-report'} title={'Отчет по событиям'} />
                </div>
            </div>
        </div>
    );
};
