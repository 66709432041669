import React from 'react';
import './MenuCamera.scss';
import { Link } from 'react-router-dom';
import { Map } from 'react-feather';
import { Camera } from '../../libs/icons';

export const MenuCamera = (): JSX.Element => {
    return (
        <div className="horizontal-menu-wrapper video-menu p-0">
            <div
                className="header-navbar navbar-expand-sm navbar navbar-horizontal navbar-light menu-border"
                role="navigation"
            >
                <div className="shadow-bottom" />
                <div className="navbar-container main-menu-content p-0" data-menu="menu-container">
                    <ul className="nav navbar-nav" id="main-menu-navigation" data-menu="menu-navigation">
                        <li className={'nav-item'}>
                            <Link to={'/map'} className="nav-link d-flex align-items-center">
                                <Map />
                                <span>Вернуться к карте</span>
                            </Link>
                        </li>
                        <li className="nav-item active">
                            <a className="nav-link d-flex align-items-center" href="#">
                                <Camera />
                                <span>Камеры</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
