import React, { useEffect, useMemo } from 'react';
import { formatDate } from '../../../../utilits/formatDate';
import { useTypeSelector } from '../../../../hooks/useTypeSelector';
import { useActions } from '../../../../hooks/useActions';
import { getCorrectTimeLabel } from '../../../../utilits/getCorrectName';
import Chart from 'react-apexcharts';

const ServiceTimeAvg = ({ startDate, endDate }: { startDate: string; endDate: string }): JSX.Element => {
    const { service } = useTypeSelector((state) => state.service);
    const { selected, structures } = useTypeSelector((state) => state.structures);

    const { postServiceAvg } = useActions();
    useEffect(() => {
        postServiceAvg(selected || structures[0].structureId, startDate, endDate);
    }, [selected, startDate, endDate]);

    const isEmptyData = useMemo(() => {
        return !service.dates || !service.dates?.length || service.dates.every((i) => i.value === 0);
    }, [service]);

    return (
        <div className="card">
            <div className="card-header d-flex flex-sm-row flex-column justify-content-md-between align-items-start justify-content-start">
                <div>
                    <h4 className="card-title font-weight-bolder">Среднее время проведенное у окна регистратуры</h4>
                    <p className="card-subtitle mt-25 mb-25">
                        {isEmptyData
                            ? 'Данные отсутствуют'
                            : `Данные за ${formatDate(startDate)} -${formatDate(endDate)}`}
                    </p>
                </div>
            </div>
            {!isEmptyData && (
                <div className="card-body">
                    <Chart
                        height={400}
                        options={{
                            tooltip: {
                                enabled: true,
                                followCursor: true,
                                y: {
                                    formatter: (value) => {
                                        return value + ' ' + getCorrectTimeLabel(value, ['минута', 'минуты', 'минут']);
                                    },
                                },
                            },
                            plotOptions: {
                                bar: {
                                    columnWidth: '15%',
                                    colors: {
                                        ranges: [
                                            {
                                                from: 0,
                                                to: 10000,
                                                color: '#1EBEAA',
                                            },
                                        ],
                                        backgroundBarOpacity: 1,
                                        backgroundBarRadius: 0,
                                    },
                                },
                            },
                            stroke: {
                                show: false,
                            },
                            xaxis: {
                                categories: service.dates.map(({ dt }) => {
                                    return formatDate(dt);
                                }),
                            },
                            yaxis: {
                                tickAmount: 1,
                                title: {
                                    text: 'Минут',
                                },
                            },
                        }}
                        series={[
                            {
                                name: 'Количество',
                                data: service.dates.map(({ value }) => {
                                    return value;
                                }),
                            },
                        ]}
                        type={'bar'}
                    />
                </div>
            )}
        </div>
    );
};

export default ServiceTimeAvg;
