import { gateAction, GateActionTypes, GateState } from '../../types/gate';

const initialState: GateState = {
    gates: {
        structureId: '',
        values: [],
        value: 0,
    },
    loading: false,
    error: null,
};

export const gateReducer = (state: GateState = initialState, action: gateAction): GateState => {
    switch (action.type) {
        case GateActionTypes.GET_GATE_COUNT: {
            return { ...state, loading: true };
        }
        case GateActionTypes.GET_GATE_COUNT_SUCCESS: {
            return { ...state, loading: false, gates: action.payload };
        }
        case GateActionTypes.GET_GATE_COUNT_ERROR: {
            return { ...state, loading: false, error: action.payload };
        }
        case GateActionTypes.POST_GATE_HIST_COUNT: {
            return { ...state, loading: true };
        }
        case GateActionTypes.POST_GATE_HIST_COUNT_SUCCESS: {
            return { ...state, loading: false, gates: action.payload };
        }
        case GateActionTypes.POST_GATE_HIST_COUNT_ERROR: {
            return { ...state, loading: false, error: action.payload };
        }
        default:
            return state;
    }
};
