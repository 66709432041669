import {
    structuresStatusesAction,
    GetStructuresStatusesActionTypes,
    StructuresStatusesState,
} from '../../types/statuses';

const initialState: StructuresStatusesState = {
    statuses: [],
    loading: false,
    error: null,
    selected: '',
};

export const structuresStatusesReducer = (
    state: StructuresStatusesState = initialState,
    action: structuresStatusesAction,
): StructuresStatusesState => {
    switch (action.type) {
        case GetStructuresStatusesActionTypes.GET_STRUCTURES_STATUSES: {
            return { ...state, loading: true };
        }
        case GetStructuresStatusesActionTypes.GET_STRUCTURES_STATUSES_SUCCESS: {
            return { ...state, loading: false, statuses: action.payload };
        }
        case GetStructuresStatusesActionTypes.GET_STRUCTURES_STATUSES_ERROR: {
            return { ...state, loading: false, error: action.payload };
        }
        default:
            return state;
    }
};
