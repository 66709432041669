import React, { useEffect } from 'react';
import { useTypeSelector } from '../../../../hooks/useTypeSelector';
import { useActions } from '../../../../hooks/useActions';
import { getCorrectName } from '../../../../utilits/getCorrectName';
import { useCommentsRetriever } from '../../../../hooks/useMessageRetriever';
import { Users } from '../../../../libs/icons';
import CamerasArea from './CamerasArea';

interface GateCountProps {
    openCameras: () => void;
    camerasCount: number;
}

export const GateCount = ({ openCameras, camerasCount }: GateCountProps): JSX.Element => {
    const { gates } = useTypeSelector((state) => state.gate);
    const { selected, structures } = useTypeSelector((state) => state.structures);
    const { getGateCount } = useActions();
    const messageTime = useCommentsRetriever({ path: 'gate-customer-count', structureId: selected });
    useEffect(() => {
        getGateCount(selected || structures[0].structureId);
    }, [selected, messageTime]);

    return (
        <div className="card">
            <div className="card-header flex-column align-items-center pb-0">
                <CamerasArea openCameras={openCameras} camerasCount={camerasCount} />
                <div className="avatar gate-count-icon m-0">
                    <Users size={48} color="#191C22" />
                </div>
                <h1 className="font-weight-bolder mt-1">
                    {gates.value ?? 0} {getCorrectName(gates.value ?? 0, 'человек')}
                </h1>
                <p className="card-text">Посетители за последний час</p>
                <br />
            </div>
        </div>
    );
};
