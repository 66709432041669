import React from 'react';
import { useTypeSelector } from '../../../../hooks/useTypeSelector';
import { MapPin } from 'react-feather';
export const StructureBanner = (): JSX.Element => {
    const { structures, selected } = useTypeSelector((state) => state.structures);
    const structureResults = !selected ? structures[0] : structures.find(({ structureId }) => structureId === selected);
    return (
        <div
            className="pricing-free-trial mt-0 d-flex card-body"
            style={{ background: 'url(/images/Illistration.png) no-repeat right bottom' }}
        >
            <span className="right-corner-tab">Обновлено</span>
            <span className="right-corner-tab date">
                {new Date().toLocaleString('ru', {
                    month: 'long',
                    day: 'numeric',
                })}{' '}
                | в {new Date().toLocaleTimeString('ru-RU')}
            </span>
            <div className="pricing-trial-content d-flex justify-content-center">
                <div className="row w-100">
                    <div className="col-12">
                        <div className="text-center text-md-left pt-2">
                            <h2>{structureResults?.name}</h2>
                            <h4>{structureResults?.description}</h4>
                            <h5 className="mt-5 pt-5 w-75">
                                <MapPin size="14" style={{ marginRight: '10px' }} />
                                {structureResults?.address}
                            </h5>
                        </div>
                    </div>{' '}
                </div>
            </div>
        </div>
    );
};
