import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';

import { Provider } from 'react-redux';

import { store } from './store/';
import httpClient from './utilits/httpClient';
// import { makeServer } from './utilits/Mirage/Mirage';

httpClient.initialize();
// process.env.NODE_ENV === 'development' && makeServer({ environment: 'development' }); //comment if need real dev backend server

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root'),
);
