export interface ReceptionParams {
    structureId: string;
    value?: number;
    values?:
        | []
        | [
              {
                  date: string;
                  value: number;
              },
          ];
}

export interface ReceptionState {
    reception: ReceptionParams;
    receptionRoom: ReceptionParams;
    loading: boolean;
    error: null | string;
}

export enum ReceptionActionTypes {
    GET_RECEPTION_CUSTOMER_COUNT = 'GET_RECEPTION_CUSTOMER_COUNT',
    GET_RECEPTION_CUSTOMER_COUNT_SUCCESS = 'GET_RECEPTION_CUSTOMER_COUNT_SUCCESS',
    GET_RECEPTION_CUSTOMER_COUNT_ERROR = 'GET_RECEPTION_CUSTOMER_COUNT_ERROR',

    POST_RECEPTION_CUSTOMER_COUNT = 'POST_RECEPTION_CUSTOMER_COUNT',
    POST_RECEPTION_CUSTOMER_COUNT_SUCCESS = 'POST_RECEPTION_CUSTOMER_COUNT_SUCCESS',
    POST_RECEPTION_CUSTOMER_COUNT_ERROR = 'POST_RECEPTION_CUSTOMER_COUNT_ERROR',

    POST_RECEPTION_CUSTOMER_COUNT_ROOM = 'POST_RECEPTION_CUSTOMER_COUNT_ROOM',
    POST_RECEPTION_CUSTOMER_COUNT_ROOM_SUCCESS = 'POST_RECEPTION_CUSTOMER_COUNT_ROOM_SUCCESS',
    POST_RECEPTION_CUSTOMER_COUNT_ROOM_ERROR = 'POST_RECEPTION_CUSTOMER_COUNT_ROOM_ERROR',
}

interface GetReceptionCustomerAction {
    type: ReceptionActionTypes.GET_RECEPTION_CUSTOMER_COUNT;
}

interface GetReceptionCustomerSuccessAction {
    type: ReceptionActionTypes.GET_RECEPTION_CUSTOMER_COUNT_SUCCESS;
    payload: ReceptionParams;
}

interface GetReceptionCustomerErrorAction {
    type: ReceptionActionTypes.GET_RECEPTION_CUSTOMER_COUNT_ERROR;
    payload: string;
}

interface PostReceptionCustomerAction {
    type: ReceptionActionTypes.POST_RECEPTION_CUSTOMER_COUNT;
}

interface PostReceptionCustomerSuccessAction {
    type: ReceptionActionTypes.POST_RECEPTION_CUSTOMER_COUNT_SUCCESS;
    payload: ReceptionParams;
}

interface PostReceptionCustomerErrorAction {
    type: ReceptionActionTypes.POST_RECEPTION_CUSTOMER_COUNT_ERROR;
    payload: string;
}

interface PostReceptionCustomerRoomAction {
    type: ReceptionActionTypes.POST_RECEPTION_CUSTOMER_COUNT_ROOM;
}

interface PostReceptionCustomerRoomSuccessAction {
    type: ReceptionActionTypes.POST_RECEPTION_CUSTOMER_COUNT_ROOM_SUCCESS;
    payload: ReceptionParams;
}

interface PostReceptionCustomerRoomErrorAction {
    type: ReceptionActionTypes.POST_RECEPTION_CUSTOMER_COUNT_ROOM_ERROR;
    payload: string;
}

export type receptionAction =
    | GetReceptionCustomerAction
    | GetReceptionCustomerSuccessAction
    | GetReceptionCustomerErrorAction
    | PostReceptionCustomerAction
    | PostReceptionCustomerSuccessAction
    | PostReceptionCustomerErrorAction
    | PostReceptionCustomerRoomAction
    | PostReceptionCustomerRoomSuccessAction
    | PostReceptionCustomerRoomErrorAction;
