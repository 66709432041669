import React, { useEffect } from 'react';
import { useTypeSelector } from '../../../../hooks/useTypeSelector';
import { useActions } from '../../../../hooks/useActions';

import { getCorrectName } from '../../../../utilits/getCorrectName';
import { UserWork } from '../../../../libs/icons';
import { useCommentsRetriever } from '../../../../hooks/useMessageRetriever';
import CamerasArea from './CamerasArea';

interface ServiceCountProps {
    openCameras: () => void;
    camerasCount: number;
}

export const ServiceCount: React.FC<ServiceCountProps> = ({
    openCameras,
    camerasCount,
}: ServiceCountProps): JSX.Element => {
    const { reception } = useTypeSelector((state) => state.reception);
    const { selected, structures } = useTypeSelector((state) => state.structures);

    const { getReceptionCount } = useActions();
    const messageTime = useCommentsRetriever({ path: 'reception-customer-count', structureId: selected });
    useEffect(() => {
        getReceptionCount(selected || structures[0].structureId);
    }, [selected, messageTime]);

    return (
        <div className="card">
            <div className="card-header flex-column align-items-center pb-0">
                <CamerasArea openCameras={openCameras} camerasCount={camerasCount} />
                <div className="avatar service-count-icon m-0">
                    <UserWork size="48" color="#191C22" />
                </div>
                <h1 className="font-weight-bolder mt-1">
                    {reception.value ?? 0} {getCorrectName(reception.value ?? 0, 'человек')}
                </h1>
                <p className="card-text">Сейчас в очереди в регистратуру</p>
                <br />
            </div>
        </div>
    );
};
