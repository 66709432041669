const cameras = [
    {
        label: 'Регистратура',
        value: 'receptions',
    },
    {
        label: 'Рабочее место',
        value: 'workplaces',
    },
    {
        label: 'Инфомат',
        value: 'infomats',
    },
    {
        label: 'Посетители',
        value: 'gates',
    },
];

export default cameras;
