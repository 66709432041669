import { usersAction, UserActionTypes } from '../../types/user';
import { Dispatch } from 'react';
import axios from 'axios';
import { Url } from '../../types/url';
import httpClient from '../../utilits/httpClient';

export const getUserToken = (login: string, password: string) => {
    return async (dispatch: Dispatch<usersAction>): Promise<void> => {
        try {
            dispatch({
                type: UserActionTypes.POST_USER_TOKEN,
            });

            const response = await axios.post(
                Url.TokenServer,
                `client_id=account&grant_type=password&username=${login}&password=${password}`,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                },
            );

            httpClient.setAccessToken(response?.data?.access_token);
            httpClient.setRefreshToken(response?.data?.refresh_token);

            dispatch({
                type: UserActionTypes.POST_USER_TOKEN_SUCCESS,
                payload: response?.data?.access_token,
            });

            dispatch({
                type: UserActionTypes.POST_USER_LOGIN,
                payload: login,
            });
        } catch (e) {
            dispatch({ type: UserActionTypes.POST_USER_TOKEN_ERROR, payload: String(e) });
        }
    };
};

export const updateToken = (token: string) => {
    return async (dispatch: Dispatch<usersAction>): Promise<void> => {
        try {
            const response = await axios.post(
                Url.TokenServer,
                `client_id=account&grant_type=refresh_token&refresh_token=${token}`,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                },
            );

            httpClient.setAccessToken(response?.data?.access_token);
            httpClient.setRefreshToken(response?.data?.refresh_token);

            dispatch({
                type: UserActionTypes.UPDATE_TOKEN,
                payload: response.data.access_token,
            });
        } catch (e) {
            dispatch({ type: UserActionTypes.POST_USER_TOKEN_ERROR, payload: String(e) });
        }
    };
};

export const getUserData = () => {
    return async (dispatch: Dispatch<usersAction>): Promise<void> => {
        try {
            dispatch({
                type: UserActionTypes.GET_USER_DATA,
            });
            const response = await axios.get(`${Url.Server}/api/1.0/user/userinfo`);
            console.log('getUserData', response);
            dispatch({
                type: UserActionTypes.GET_USER_DATA_SUCCESS,
                payload: { roles: response.data.roles, email: response.data.email },
            });
        } catch (e) {
            dispatch({
                type: UserActionTypes.GET_USER_DATA_ERROR,
                payload: 'Произошла ошибка получения данных пользователя',
            });
        }
    };
};
