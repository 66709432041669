import { UserActionTypes, usersAction, UserState } from '../../types/user';

const initialState: UserState = {
    login: undefined,
    success: false,
    loading: false,
    error: null,
    data: {
        accessToken: '',
        roles: [],
        email: '',
    },
    loggedIn: undefined,
};

export const userReducer = (state: UserState = initialState, action: usersAction): UserState => {
    switch (action.type) {
        case UserActionTypes.POST_USER_LOGIN: {
            return { ...state, login: action.payload };
        }
        case UserActionTypes.POST_USER_TOKEN: {
            return { ...state, loading: true };
        }
        case UserActionTypes.POST_USER_TOKEN_SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false,
                loggedIn: true,
                data: {
                    email: '',
                    roles: [],
                    accessToken: action.payload,
                },
            };
        }

        case UserActionTypes.POST_USER_TOKEN_ERROR: {
            return {
                ...initialState,
                success: true,
                loggedIn: false,
                error: action.payload,
            };
        }
        case UserActionTypes.UPDATE_TOKEN: {
            return {
                ...initialState,
                success: true,
                loggedIn: true,
                data: { ...initialState.data, accessToken: action.payload },
            };
        }
        case UserActionTypes.REMOVE_TOKEN: {
            return {
                ...initialState,
                success: true,
                loggedIn: false,
            };
        }
        case UserActionTypes.GET_USER_DATA: {
            return { ...state, loading: true };
        }
        case UserActionTypes.GET_USER_DATA_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: {
                    ...initialState.data,
                    roles: action.payload.roles,
                    email: action.payload.email,
                },
            };
        }
        case UserActionTypes.GET_USER_DATA_ERROR: {
            return { ...state, loading: false, error: action.payload };
        }
        default:
            return state;
    }
};
