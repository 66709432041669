import { Video } from 'react-feather';
import React, { useMemo } from 'react';

interface CamerasAreaProps {
    openCameras: () => void;
    camerasCount: number;
}

const CamerasArea: React.FC<CamerasAreaProps> = ({ openCameras, camerasCount }: CamerasAreaProps) => {
    const camerasText = useMemo(() => {
        if (camerasCount === 1) {
            return 'камеру';
        } else if ([2, 3, 4].includes(camerasCount)) {
            return 'камеры';
        } else {
            return 'камер';
        }
    }, [camerasCount]);

    return (
        <div className="video-cameras" onClick={openCameras}>
            <Video size={16} />
            <span>
                Посмотреть {camerasCount} {camerasText}
            </span>
        </div>
    );
};

export default CamerasArea;
