import { structuresStatusesAction, GetStructuresStatusesActionTypes } from '../../types/statuses';
import { Dispatch } from 'react';
import axios from 'axios';
import { Url } from '../../types/url';

export const getStructuresStatuses = () => {
    return async (dispatch: Dispatch<structuresStatusesAction>): Promise<void> => {
        try {
            dispatch({
                type: GetStructuresStatusesActionTypes.GET_STRUCTURES_STATUSES,
            });

            const response = await axios.get(`${Url.Server}/api/1.0/dashboard/structure/statistics`);
            dispatch({
                type: GetStructuresStatusesActionTypes.GET_STRUCTURES_STATUSES_SUCCESS,
                payload: response.data,
            });
        } catch (e) {
            dispatch({
                type: GetStructuresStatusesActionTypes.GET_STRUCTURES_STATUSES_ERROR,
                payload: 'Произошла ошибка загрузки статусов объектов',
            });
        }
    };
};
