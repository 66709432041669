export const formatPhoneNumber = (s: string): string => {
    const regexp = /\d{1,}/g;
    const phone = s.match(regexp);
    const string = phone ? phone.join('') : '';
    return (
        '+' +
        string.slice(0, 1) +
        ' (' +
        string.slice(1, 4) +
        ') ' +
        string.slice(4, 7) +
        ' ' +
        string.slice(7, 9) +
        ' ' +
        string.slice(9, 11)
    );
};

export const formatRhoneNumberToString = (string: string): string => {
    const regexp = /\d{1,}/g;
    const phone = string.match(regexp);
    return phone ? phone.join('') : '';
};
