export const Url = {
    Server:
        process.env.NODE_ENV === 'production'
            ? typeof process.env['REACT_APP_HOST'] === 'string'
                ? `https://${process.env['REACT_APP_HOST']}`
                : ''
            : '',
    Bitrix24: 'mosregdemo5.bitrix24.ru',
    WebSocketsServer:
        process.env.NODE_ENV === 'production'
            ? typeof process.env['REACT_APP_HOST'] === 'string'
                ? `wss://${process.env['REACT_APP_HOST']}/api/websockets`
                : `wss://${window.location.host}/api/websockets`
            : `wss://${process.env['REACT_APP_HOST']}/api/websockets`,
    TokenServer: 'https://aggapi-auth.ap.avlab.dev/auth/realms/vp-reception/protocol/openid-connect/token',
};
