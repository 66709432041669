export const columns = [
    {
        title: 'Уровень эскалации',
        name: 'escalation_level',
        align: 'center' as const,
        style: {
            width: '250px',
        },
    },
    {
        title: 'Время реакции',
        name: 'time_reaction',
    },
    {
        title: 'Должность',
        name: 'position',
    },
    {
        title: 'ФИО',
        name: 'name',
        style: {
            width: '350px',
        },
    },
    {
        title: 'Номер телефона',
        name: 'phone',
        align: 'center' as const,
    },
    {
        title: 'Действие',
        name: 'actions',
        align: 'center' as const,
    },
];

export const columnPeopleInCharge = [
    {
        title: 'ФИО',
        name: 'name',
        style: {
            width: '300px',
        },
    },
    {
        title: 'Должность',
        name: 'position',
    },
    {
        title: 'Номер телефона',
        name: 'phone',
        align: 'center' as const,
    },
    {
        title: 'Действие',
        name: 'actions',
        align: 'center' as const,
    },
];
