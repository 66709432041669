import { authAction, AuthActionTypes, AuthState } from '../../types/auth';

const initialState: AuthState = {
    success: false,
    domain: '',
    loading: false,
};

export const authReducer = (state: AuthState = initialState, action: authAction): AuthState => {
    switch (action.type) {
        case AuthActionTypes.AUTH: {
            return { ...state, loading: true };
        }
        case AuthActionTypes.AUTH_SUCCESS: {
            return { success: true, loading: false, domain: action.payload };
        }
        case AuthActionTypes.AUTH_ERROR: {
            return { success: false, loading: false, domain: action.payload };
        }
        default:
            return state;
    }
};
