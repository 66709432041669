import Chart from 'react-apexcharts';
import React, { useEffect } from 'react';
import { useTypeSelector } from '../../../../hooks/useTypeSelector';
import { useActions } from '../../../../hooks/useActions';
import { useCommentsRetriever } from '../../../../hooks/useMessageRetriever';

export const WorkplaceNobody = (): JSX.Element => {
    const { workplaceNobody } = useTypeSelector((state) => state.workplace);
    const { selected, structures } = useTypeSelector((state) => state.structures);
    const messageTime = useCommentsRetriever({ path: 'workplace-time-nobody', structureId: selected });
    const { getWorkplaceNobody } = useActions();
    useEffect(() => {
        getWorkplaceNobody(selected || structures[0].structureId);
    }, [selected, messageTime]);

    return (
        <div className="card" style={{ height: 'calc(100% - 2rem)' }}>
            <div className="card-header flex-column align-items-start">
                <h4 className="card-title mb-75">Регистраторы за рабочим местом</h4>
                <span className="card-subtitle">Данные за последний час </span>
            </div>
            <div className="card-body">
                <Chart
                    options={{
                        chart: {
                            type: 'donut',
                        },
                        legend: {
                            position: 'bottom',
                        },
                        yaxis: {
                            floating: false,
                        },
                        labels:
                            workplaceNobody.nobodyTime === null ? ['Данных нет'] : ['Присутствовали', 'Отсутствовали'],
                        colors: workplaceNobody.nobodyTime === null ? ['#787878FF'] : ['#1EBEAA', '#F45151'],

                        dataLabels: {
                            dropShadow: {
                                enabled: false,
                            },
                        },
                    }}
                    series={
                        workplaceNobody.nobodyTime === null
                            ? [1]
                            : [Math.round(100 - workplaceNobody.nobodyTime), Math.round(workplaceNobody.nobodyTime)]
                    }
                    type="donut"
                    width="100%"
                    height={500}
                />
            </div>
        </div>
    );
};
