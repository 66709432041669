import { WorkplaceState, workplaceAction, WorkplaceActionTypes } from '../../types/workplace';

const initialState: WorkplaceState = {
    workplace: {
        structureId: '',
        value: 0,
    },
    workplaceHist: {
        structureId: '',
        workPlaces: [],
    },
    workplaceNobody: {
        structureId: '',
        nobodyTime: 0,
    },
    loading: false,
    error: null,
};

export const workplaceReducer = (state: WorkplaceState = initialState, action: workplaceAction): WorkplaceState => {
    switch (action.type) {
        case WorkplaceActionTypes.GET_WORKPLACE_COUNT: {
            return { ...state, loading: true };
        }
        case WorkplaceActionTypes.GET_WORKPLACE_COUNT_SUCCESS: {
            return { ...state, loading: false, workplace: action.payload };
        }
        case WorkplaceActionTypes.GET_WORKPLACE_COUNT_ERROR: {
            return { ...state, loading: false, error: action.payload };
        }

        case WorkplaceActionTypes.POST_WORKPLACE_HIST: {
            return { ...state, loading: true };
        }
        case WorkplaceActionTypes.POST_WORKPLACE_HIST_SUCCESS: {
            return { ...state, loading: false, workplaceHist: action.payload };
        }
        case WorkplaceActionTypes.POST_WORKPLACE_HIST_ERROR: {
            return { ...state, loading: false, error: action.payload };
        }

        case WorkplaceActionTypes.GET_WORKPLACE_NOBODY: {
            return { ...state, loading: true };
        }
        case WorkplaceActionTypes.GET_WORKPLACE_NOBODY_SUCCESS: {
            return { ...state, loading: false, workplaceNobody: action.payload };
        }
        case WorkplaceActionTypes.GET_WORKPLACE_NOBODY_ERROR: {
            return { ...state, loading: false, error: action.payload };
        }

        case WorkplaceActionTypes.POST_WORKPLACE_NOBODY_HIST: {
            return { ...state, loading: true };
        }
        case WorkplaceActionTypes.POST_WORKPLACE_NOBODY_HIST_SUCCESS: {
            return { ...state, loading: false, workplaceNobody: action.payload };
        }
        case WorkplaceActionTypes.POST_WORKPLACE_NOBODY_HIST_ERROR: {
            return { ...state, loading: false, error: action.payload };
        }
        default:
            return state;
    }
};
