import React from 'react';
interface Props {
    color?: string;
    size?: string;
}
const UserWork = ({ color = '#6E6B7B', size = '24' }: Props): JSX.Element => {
    return (
        <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill={color}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.5919 17.3191C32.9506 17.3191 34.052 16.2177 34.052 14.859C34.052 13.5003 32.9506 12.3989 31.5919 12.3989C30.2332 12.3989 29.1318 13.5003 29.1318 14.859C29.1318 16.2177 30.2332 17.3191 31.5919 17.3191ZM14.9717 22.2625C14.9717 19.9216 16.8694 18.0239 19.2103 18.0239C21.5512 18.0239 23.4489 19.9216 23.4489 22.2625V22.4703C23.4489 23.0226 23.0012 23.4703 22.4489 23.4703C21.8966 23.4703 21.4489 23.0226 21.4489 22.4703V22.2625C21.4489 21.0262 20.4466 20.0239 19.2103 20.0239C17.9739 20.0239 16.9717 21.0262 16.9717 22.2625V24.6899H24.2171C24.7694 24.6899 25.2171 25.1377 25.2171 25.6899C25.2171 26.2422 24.7694 26.6899 24.2171 26.6899H14.1699V33.601H29.2189V29.5679C28.1599 29.3882 27.3535 28.4663 27.3535 27.3561V22.5577C27.3535 20.2168 29.2512 18.3191 31.5921 18.3191C33.933 18.3191 35.8307 20.2168 35.8307 22.5577V27.6335C35.8307 28.5405 35.2166 29.3041 34.3814 29.531V34.4863C34.3814 35.0386 33.9337 35.4863 33.3814 35.4863C32.8291 35.4863 32.3814 35.0386 32.3814 34.4863V29.0828C32.3814 28.275 33.0272 27.618 33.8307 27.6001V22.5577C33.8307 21.3214 32.8285 20.3191 31.5921 20.3191C30.3558 20.3191 29.3535 21.3214 29.3535 22.5577V27.3561C29.3535 27.4906 29.4626 27.5997 29.5971 27.5997C30.4928 27.5997 31.2189 28.3258 31.2189 29.2215V34.4863C31.2189 34.5349 31.2154 34.5827 31.2087 34.6294C31.1937 35.1685 30.7519 35.601 30.2091 35.601H13.1699C12.6176 35.601 12.1699 35.1532 12.1699 34.601V25.6899C12.1699 25.4247 12.2753 25.1704 12.4628 24.9828C12.6504 24.7953 12.9047 24.6899 13.1699 24.6899H14.9717V22.2625ZM21.6701 14.859C21.6701 16.2177 20.5687 17.3191 19.2101 17.3191C17.8514 17.3191 16.75 16.2177 16.75 14.859C16.75 13.5003 17.8514 12.3989 19.2101 12.3989C20.5687 12.3989 21.6701 13.5003 21.6701 14.859Z"
            />
        </svg>
    );
};

export default UserWork;
