import { getCorrectTimeLabel } from '../../../../utilits/getCorrectName';

export const ZONE_TIME_TABLE = {
    start: 8,
    end: 20,
};

export const getTooltip = (y1: number, y2: number): string => {
    if (y2 - y1 >= 60000) {
        const countOfMinutes = Math.ceil((y2 - y1) / 60000);
        return `${countOfMinutes} ${getCorrectTimeLabel(countOfMinutes, ['минута', 'минуты', 'минут'])}`;
    } else {
        const countOfSecond = Math.ceil((y2 - y1) / 1000);
        return `${countOfSecond} ${getCorrectTimeLabel(countOfSecond, ['секунда', 'секунды', 'секунд'])}`;
    }
};

export const checkboxData = [
    {
        name: 'employeeOff',
        text: 'Сотрудник отсутствует',
    },
];
