import { Chart, EChartType } from '../../../../components/Chart';
import React, { useEffect } from 'react';
import { useTypeSelector } from '../../../../hooks/useTypeSelector';
import { useActions } from '../../../../hooks/useActions';
import { getCorrectTimeLabel } from '../../../../utilits/getCorrectName';
import { useCommentsRetriever } from '../../../../hooks/useMessageRetriever';

export const ServiceAvg = (): JSX.Element => {
    const { service } = useTypeSelector((state) => state.service);
    const { selected, structures } = useTypeSelector((state) => state.structures);
    const messageTime = useCommentsRetriever({ path: 'service-zone-avg-time', structureId: selected });
    const { getServiceAvg } = useActions();
    useEffect(() => {
        getServiceAvg(selected || structures[0].structureId);
    }, [selected, messageTime]);

    const chartOptions = !service.zones
        ? {}
        : {
              tooltip: {
                  enabled: true,
                  followCursor: true,
                  y: {
                      formatter: (value: number) => {
                          return value + ' ' + getCorrectTimeLabel(value, ['минута', 'минуты', 'минут']);
                      },
                  },
              },
              chart: {
                  redrawOnParentResize: true,
              },
              dataLabels: {
                  enabled: false,
              },
              plotOptions: {
                  bar: {
                      barHeight: '25%',
                      borderRadius: 10,
                      horizontal: true,
                      colors: {
                          ranges: [
                              {
                                  from: 0,
                                  to: 10000,
                                  color: '#1EBEAA',
                              },
                          ],
                          backgroundBarOpacity: 1,
                          backgroundBarRadius: 0,
                      },
                  },
              },
              xaxis: {
                  categories: service.zones.map(({ name }) => {
                      return name;
                  }),
              },
              yaxis: {
                  labels: {
                      minWidth: 0,
                      maxWidth: 200,
                  },
              },
          };

    const seriesName = 'Время';

    const makeSeries = ([name, avgTimes]: [name: string, avgTimes: Array<any>]) => {
        return [
            {
                name: name,
                data: avgTimes.map(({ avgTime }) => {
                    return avgTime;
                }),
            },
        ];
    };

    return !service.zones ? (
        <></>
    ) : (
        <div className="card">
            <div className="card-header d-flex flex-sm-row flex-column justify-content-md-between align-items-start justify-content-start">
                <div>
                    <h4 className="card-title font-weight-bolder">Среднее время проведенное у окна регистратуры</h4>
                    <p className="card-subtitle text-muted mt-25 mb-25">Данные за последний час</p>
                </div>
            </div>
            <div className="card-body">
                <Chart
                    chartOptions={chartOptions}
                    data={[seriesName, service.zones]}
                    dataFormatter={makeSeries}
                    chartSettings={{
                        type: EChartType.Bar,
                    }}
                    height={500}
                />
            </div>
        </div>
    );
};
