import React from 'react';
interface Props {
    color?: string;
    size?: string;
}
const Logout = ({ color = '#6E6B7B', size = '24' }: Props): JSX.Element => {
    return (
        <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.8011 14.1271C15.8011 13.5748 16.2488 13.1271 16.8011 13.1271H30.6748C31.2271 13.1271 31.6748 13.5748 31.6748 14.1271V33.987H34.6373C35.1896 33.987 35.6373 34.4347 35.6373 34.987C35.6373 35.5393 35.1896 35.987 34.6373 35.987H30.6748C30.1225 35.987 29.6748 35.5393 29.6748 34.987V15.1271H21.6965L26.2749 16.7023C26.6786 16.8412 26.9496 17.221 26.9496 17.6479V32.2586C26.9496 32.6997 26.6605 33.0888 26.238 33.2161L17.2379 35.9271C17.184 35.9434 17.1298 35.9549 17.0757 35.9618C16.9885 35.9867 16.8963 36 16.8011 36H13.3627C12.8104 36 12.3627 35.5523 12.3627 35C12.3627 34.4477 12.8104 34 13.3627 34H15.8011V14.1271ZM17.8011 33.6687V15.9019L24.9496 18.3614V31.5154L17.8011 33.6687ZM23.1532 25.5667C23.7072 25.5667 24.1564 25.1176 24.1564 24.5635C24.1564 24.0095 23.7072 23.5603 23.1532 23.5603C22.5991 23.5603 22.15 24.0095 22.15 24.5635C22.15 25.1176 22.5991 25.5667 23.1532 25.5667Z"
                fill={color}
            />
        </svg>
    );
};

export default Logout;
