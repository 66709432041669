import { structuresAction, GetStructuresActionTypes, StructuresState } from '../../types/structures';

const initialState: StructuresState = {
    structures: [],
    loading: false,
    error: null,
    selected: localStorage.getItem('selectedStructureId') || '',
};

export const structuresReducer = (state: StructuresState = initialState, action: structuresAction): StructuresState => {
    switch (action.type) {
        case GetStructuresActionTypes.GET_STRUCTURES: {
            return { ...state, loading: true };
        }
        case GetStructuresActionTypes.GET_STRUCTURES_SUCCESS: {
            return { ...state, loading: false, structures: action.payload };
        }
        case GetStructuresActionTypes.GET_STRUCTURES_ERROR: {
            return { ...state, loading: false, error: action.payload };
        }
        case GetStructuresActionTypes.SET_SELECTED_STRUCTURE: {
            localStorage.setItem('selectedStructureId', action.payload);
            return { ...state, selected: action.payload };
        }
        default:
            return state;
    }
};
