import { timeAction, TimeActionTypes, TimeState } from '../../types/time';

const initialState: TimeState = {
    dateFilter: {
        startDate: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
        endDate: null,
    },
};

export const timeReducer = (state: TimeState = initialState, action: timeAction): TimeState => {
    switch (action.type) {
        case TimeActionTypes.SET_TIME: {
            return { dateFilter: action.payload };
        }
        default:
            return state;
    }
};
