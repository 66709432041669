import { receptionAction, ReceptionState, ReceptionActionTypes } from '../../types/reception';

const initialState: ReceptionState = {
    reception: {
        structureId: '',
        value: 0,
        values: [],
    },
    receptionRoom: {
        structureId: '',
        value: 0,
        values: [],
    },
    loading: false,
    error: null,
};

export const receptionReducer = (state: ReceptionState = initialState, action: receptionAction): ReceptionState => {
    switch (action.type) {
        case ReceptionActionTypes.GET_RECEPTION_CUSTOMER_COUNT: {
            return { ...state, loading: true };
        }
        case ReceptionActionTypes.GET_RECEPTION_CUSTOMER_COUNT_SUCCESS: {
            return { ...state, loading: false, reception: action.payload };
        }
        case ReceptionActionTypes.GET_RECEPTION_CUSTOMER_COUNT_ERROR: {
            return { ...state, loading: false, error: action.payload };
        }
        case ReceptionActionTypes.POST_RECEPTION_CUSTOMER_COUNT: {
            return { ...state, loading: true };
        }
        case ReceptionActionTypes.POST_RECEPTION_CUSTOMER_COUNT_SUCCESS: {
            return { ...state, loading: false, reception: action.payload };
        }
        case ReceptionActionTypes.POST_RECEPTION_CUSTOMER_COUNT_ERROR: {
            return { ...state, loading: false, error: action.payload };
        }
        case ReceptionActionTypes.POST_RECEPTION_CUSTOMER_COUNT_ROOM: {
            return { ...state, loading: true };
        }
        case ReceptionActionTypes.POST_RECEPTION_CUSTOMER_COUNT_ROOM_SUCCESS: {
            return { ...state, loading: false, receptionRoom: action.payload };
        }
        case ReceptionActionTypes.POST_RECEPTION_CUSTOMER_COUNT_ROOM_ERROR: {
            return { ...state, loading: false, error: action.payload };
        }
        default:
            return state;
    }
};
