import { infomatAction, InfomatActionTypes, InfomatState } from '../../types/infomat';

const initialState: InfomatState = {
    infomat: {
        structureId: '',
        value: 0,
    },
    loading: false,
    error: null,
};

export const infomatReducer = (state: InfomatState = initialState, action: infomatAction): InfomatState => {
    switch (action.type) {
        case InfomatActionTypes.GET_INFOMAT_COUNT: {
            return { ...state, loading: true };
        }
        case InfomatActionTypes.GET_INFOMAT_COUNT_SUCCESS: {
            return { ...state, loading: false, infomat: action.payload };
        }
        case InfomatActionTypes.GET_INFOMAT_COUNT_ERROR: {
            return { ...state, loading: false, error: action.payload };
        }
        default:
            return state;
    }
};
