import React, { useEffect } from 'react';
import { useTypeSelector } from '../../../../hooks/useTypeSelector';
import { AlertCircle } from 'react-feather';
import { useActions } from '../../../../hooks/useActions';
import { useCommentsRetriever } from '../../../../hooks/useMessageRetriever';
export const Incidents = (): JSX.Element => {
    const { events } = useTypeSelector((state) => state.events);
    const { selected, structures } = useTypeSelector((state) => state.structures);
    const alertClass = events.events.length < 10 ? 'success' : events.events.length < 20 ? 'warning' : 'danger';
    const messageTime = useCommentsRetriever({ path: 'event', structureId: selected });
    const { getEvents } = useActions();

    useEffect(() => {
        getEvents(selected || structures[0].structureId);
    }, [messageTime, selected]);
    return (
        <div className={'card d-flex text-center card-body border-' + alertClass}>
            <div className="card-body align-items-center d-flex flex-column justify-content-center">
                <div className={'avatar p-50 mb-1 bg-light-' + alertClass}>
                    <AlertCircle size={48} />
                </div>
                <h1 className="font-weight-bolder">{events.events.length}</h1>
                <p className="card-text">Инцидентов</p>
            </div>
        </div>
    );
};
