export interface GateParams {
    structureId: string;
    value?: number;
    values?: [] | [{ date: string; value: number }];
}

export interface GateState {
    gates: GateParams;
    loading: boolean;
    error: null | string;
}

export enum GateActionTypes {
    GET_GATE_COUNT = 'GET_GATE_COUNT',
    GET_GATE_COUNT_SUCCESS = 'GET_GATE_COUNT_SUCCESS',
    GET_GATE_COUNT_ERROR = 'GET_GATE_COUNT_ERROR',

    POST_GATE_HIST_COUNT = 'POST_GATE_HIST_COUNT',
    POST_GATE_HIST_COUNT_SUCCESS = 'POST_GATE_HIST_COUNT_SUCCESS',
    POST_GATE_HIST_COUNT_ERROR = 'POST_GATE_HIST_COUNT_ERROR',
}

interface GetGateCountAction {
    type: GateActionTypes.GET_GATE_COUNT;
}

interface GetGateCountSuccessAction {
    type: GateActionTypes.GET_GATE_COUNT_SUCCESS;
    payload: GateParams;
}

interface GetGateCountErrorAction {
    type: GateActionTypes.GET_GATE_COUNT_ERROR;
    payload: string;
}

interface PostGateHistAction {
    type: GateActionTypes.POST_GATE_HIST_COUNT;
}

interface PostGateHistSuccessAction {
    type: GateActionTypes.POST_GATE_HIST_COUNT_SUCCESS;
    payload: GateParams;
}

interface PostGateHistErrorAction {
    type: GateActionTypes.POST_GATE_HIST_COUNT_ERROR;
    payload: string;
}

export type gateAction =
    | GetGateCountAction
    | GetGateCountSuccessAction
    | GetGateCountErrorAction
    | PostGateHistAction
    | PostGateHistSuccessAction
    | PostGateHistErrorAction;
