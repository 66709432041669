import React, { useState } from 'react';
import './Form.scss';
import { useActions } from '../../../hooks/useActions';
import { useTypeSelector } from '../../../hooks/useTypeSelector';
import { Eye, EyeOff } from 'react-feather';
import InterLogo from '../../../components/Footer/adtech_black_left.png';

const Form = (): JSX.Element => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { error } = useTypeSelector((state) => state.user);
    const [showPassword, setShowPassword] = useState(false);
    const { getUserToken } = useActions();
    const formSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (email !== '' && password !== '') {
            getUserToken(email, password);
        }
    };

    return (
        <div className="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
            <div className="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
                <h3 className="card-title text-grey90 mb-0">Видеоаналитика</h3>
                <h1 className="card-title text-grey90 font-weight-bold">МОНИТОРИНГ КАЧЕСТВА ОБСЛУЖИВАНИЯ</h1>
                <p className="card-text mt-2 mb-2">Пожалуйста, авторизуйтесь чтобы продолжить работу в системе</p>
                <form className="auth-login-form mt-2" method="POST" onSubmit={(e) => formSubmit(e)}>
                    <div className="form-group">
                        <label htmlFor="login-email">Email</label>
                        <input
                            className={'form-control login-input' + (error ? ' is-invalid' : '')}
                            id="login-email"
                            type="email"
                            name="login-email"
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="email"
                            aria-describedby="login-email"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="login-password">Пароль</label>
                        <div
                            className={
                                'input-group input-group-merge form-password-toggle' + (error ? ' is-invalid' : '')
                            }
                        >
                            <input
                                className={'form-control form-control-merge' + (error ? ' is-invalid' : '')}
                                id="login-password"
                                type={!showPassword ? 'password' : 'text'}
                                autoComplete="current-password"
                                name="login-password"
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="············"
                                aria-describedby="login-password"
                            />
                            <div className="input-group-append">
                                <span
                                    onClick={() => setShowPassword(!showPassword)}
                                    className="input-group-text cursor-pointer"
                                >
                                    {showPassword ? <EyeOff /> : <Eye />}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="submit-bar">
                        <span className="error-message">{error && 'Ошибка авторизации'}</span>
                        <button className="mt-1 p-1 pl-4 pr-4 btn bg-beeline-color float-right" type="submit">
                            Войти
                        </button>
                    </div>
                </form>
            </div>
            <div className="position-absolute logo-bottom">
                <img alt="VEON" src={InterLogo} width={180} />
            </div>
        </div>
    );
};

export default Form;
