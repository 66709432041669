import { EmployeesState, EmployeesActionTypes, employeeAction } from '../../types/employee';

const initialState: EmployeesState = {
    employees: [],
    loading: false,
    error: null,
};

export const employeeReducer = (state: EmployeesState = initialState, action: employeeAction): EmployeesState => {
    switch (action.type) {
        case EmployeesActionTypes.GET_EMPLOYEES: {
            return { ...state, loading: true };
        }
        case EmployeesActionTypes.GET_EMPLOYEES_SUCCESS: {
            return { ...state, loading: false, employees: action.payload };
        }
        case EmployeesActionTypes.GET_EMPLOYEES_ERROR: {
            return { ...state, loading: false, error: action.payload };
        }
        case EmployeesActionTypes.POST_EMPLOYEES: {
            return { ...state, loading: true };
        }
        case EmployeesActionTypes.POST_EMPLOYEES_SUCCESS: {
            return { ...state, loading: false, employees: [...state.employees, action.payload] };
        }
        case EmployeesActionTypes.POST_EMPLOYEES_ERROR: {
            return { ...state, loading: false, error: action.payload };
        }
        case EmployeesActionTypes.PUT_EMPLOYEES: {
            return { ...state, loading: true };
        }
        case EmployeesActionTypes.PUT_EMPLOYEES_SUCCESS: {
            const updatedEmployees = state.employees.map((el) => {
                if (el.employee_id === action.payload.employee_id) return action.payload;
                else return el;
            });
            return { ...state, loading: false, employees: updatedEmployees };
        }
        case EmployeesActionTypes.PUT_EMPLOYEES_ERROR: {
            return { ...state, loading: false, error: action.payload };
        }
        case EmployeesActionTypes.DELETE_EMPLOYEE: {
            return { ...state, loading: true };
        }
        case EmployeesActionTypes.DELETE_EMPLOYEE_SUCCESS: {
            return {
                ...state,
                loading: false,
                employees: state.employees.filter((el) => el.employee_id !== action.payload),
            };
        }
        case EmployeesActionTypes.DELETE_EMPLOYEE_ERROR: {
            return { ...state, loading: false, error: action.payload };
        }
        default:
            return state;
    }
};
