import { gateAction, GateActionTypes } from '../../types/gate';
import { Dispatch } from 'react';
import axios from 'axios';
import { Url } from '../../types/url';
import { dateStringToTimestamp } from '../../utilits/formatDate';
export const getGateCount = (structureID: string) => {
    return async (dispatch: Dispatch<gateAction>): Promise<void> => {
        try {
            dispatch({
                type: GateActionTypes.GET_GATE_COUNT,
            });

            const response = await axios.get(`${Url.Server}/api/1.0/dashboard/gate-customer-count/${structureID}`);
            dispatch({
                type: GateActionTypes.GET_GATE_COUNT_SUCCESS,
                payload: response.data,
            });
        } catch (e) {
            dispatch({
                type: GateActionTypes.GET_GATE_COUNT_ERROR,
                payload: 'Произошла ошибка получения данных о входе',
            });
        }
    };
};

export const postGateCountHist = (structureID: string, startDate: string, endDate: null | string) => {
    return async (dispatch: Dispatch<gateAction>): Promise<void> => {
        try {
            dispatch({
                type: GateActionTypes.POST_GATE_HIST_COUNT,
            });

            const response = await axios.post(`${Url.Server}/api/1.0/dashboard/gate-customer-count/${structureID}`, {
                startDate: dateStringToTimestamp(startDate),
                endDate: endDate ? dateStringToTimestamp(endDate) : endDate,
            });
            dispatch({
                type: GateActionTypes.POST_GATE_HIST_COUNT_SUCCESS,
                payload: response.data,
            });
        } catch (e) {
            dispatch({
                type: GateActionTypes.POST_GATE_HIST_COUNT_ERROR,
                payload: 'Произошла ошибка получения данных о входе за период',
            });
        }
    };
};
