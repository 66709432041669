export interface ServiceParams {
    structureId: string;
    zones:
        | []
        | [
              {
                  zoneId: string;
                  name: string;
                  avgTime: number;
              },
          ];
    dates:
        | []
        | [
              {
                  dt: string;
                  value: number;
              },
          ];
}

export interface ServiceByCustomerParams {
    structureId: string;
    serviceZones:
        | []
        | [
              {
                  zone: {
                      id: string;
                      name: string;
                  };
                  customerIntervals: [
                      {
                          start: string;
                          end: string;
                      },
                  ];
                  employeeIntervals: [
                      {
                          start: string;
                          end: string;
                      },
                  ];
              },
          ];
}

export interface ServiceState {
    service: ServiceParams;
    loading: boolean;
    error: null | string;
}

export interface ServiceByCustomerState {
    service: ServiceByCustomerParams;
    loading: boolean;
    error: null | string;
}

export enum ServiceActionTypes {
    GET_SERVICE_AVG = 'GET_SERVICE_AVG',
    GET_SERVICE_AVG_SUCCESS = 'GET_SERVICE_AVG_SUCCESS',
    GET_SERVICE_AVG_ERROR = 'GET_SERVICE_AVG_ERROR',

    POST_SERVICE_AVG = 'POST_SERVICE_AVG',
    POST_SERVICE_AVG_SUCCESS = 'POST_SERVICE_AVG_SUCCESS',
    POST_SERVICE_AVG_ERROR = 'POST_SERVICE_AVG_ERROR',

    POST_SERVICE_BY_CUSTOMER = 'POST_SERVICE_BY_CUSTOMER',
    POST_SERVICE_BY_CUSTOMER_SUCCESS = 'POST_SERVICE_BY_CUSTOMER_SUCCESS',
    POST_SERVICE_BY_CUSTOMER_ERROR = 'POST_SERVICE_BY_CUSTOMER_ERROR',
}

interface GetServiceAvgAction {
    type: ServiceActionTypes.GET_SERVICE_AVG;
}

interface GetServiceAvgSuccessAction {
    type: ServiceActionTypes.GET_SERVICE_AVG_SUCCESS;
    payload: ServiceParams;
}

interface GetServiceAvgErrorAction {
    type: ServiceActionTypes.GET_SERVICE_AVG_ERROR;
    payload: string;
}

interface PostServiceAvgAction {
    type: ServiceActionTypes.POST_SERVICE_AVG;
}

interface PostServiceAvgSuccessAction {
    type: ServiceActionTypes.POST_SERVICE_AVG_SUCCESS;
    payload: ServiceParams;
}

interface PostServiceAvgErrorAction {
    type: ServiceActionTypes.POST_SERVICE_AVG_ERROR;
    payload: string;
}

interface PostServiceByCustomerAction {
    type: ServiceActionTypes.POST_SERVICE_BY_CUSTOMER;
}

interface PostServiceByCustomerSuccessAction {
    type: ServiceActionTypes.POST_SERVICE_BY_CUSTOMER_SUCCESS;
    payload: ServiceByCustomerParams;
}

interface PostServiceByCustomerErrorAction {
    type: ServiceActionTypes.POST_SERVICE_BY_CUSTOMER_ERROR;
    payload: string;
}

export type serviceAction =
    | GetServiceAvgAction
    | GetServiceAvgSuccessAction
    | GetServiceAvgErrorAction
    | PostServiceAvgAction
    | PostServiceAvgSuccessAction
    | PostServiceAvgErrorAction
    | PostServiceByCustomerAction
    | PostServiceByCustomerSuccessAction
    | PostServiceByCustomerErrorAction;
