import React, { useEffect, useState } from 'react';
import './App.scss';

import { Application } from './pages/Application/Application';
import { useTypeSelector } from './hooks/useTypeSelector';
import history from './utilits/history';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import Login from './pages/Auth/Login';

const App = (): JSX.Element => {
    const { data } = useTypeSelector((state) => state.user);
    const [appReady, setAppReady] = useState(false);
    const [userAuth, setUserAuth] = useState(true);
    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        if (window.location.pathname === '/login' && !accessToken && !refreshToken) {
            setAppReady(true);
            setUserAuth(false);
        } else {
            if (accessToken) {
                setAppReady(true);
            }
        }
    }, []);

    useEffect(() => {
        if (data.accessToken) {
            setAppReady(true);
            setUserAuth(true);
        }
    }, [data.accessToken]);

    if (!appReady) return <></>;

    return userAuth ? (
        <>
            <Application />
        </>
    ) : (
        <>
            <Router history={history}>
                <Switch>
                    <Route exact path="/login">
                        <Login />
                    </Route>
                    <Route>
                        <Redirect to={'/login'} />
                    </Route>
                </Switch>
            </Router>
        </>
    );
};

export default App;
