import React, { memo, useCallback, useEffect, useState } from 'react';
import { Table, TableRow } from '@bigdata/ui-kit';
import './EscalationTable.scss';
import { columns } from './columns';
import createTableData from './createTableData';
import AddRowModal from './AddRowModal';
import { useActions } from '../../../hooks/useActions';
import { useTypeSelector } from '../../../hooks/useTypeSelector';
import { EscTableEmployeeParams } from '../../../types/escTableEmployee';
import { PlusCircle } from 'react-feather';
import { getCorrectReactionTime } from '../../../utilits/getCorrectReactionTime';

const EscalationTable: React.FC = () => {
    const { escTableEmployees } = useTypeSelector((state) => state.escTableEmployees);
    const { employees } = useTypeSelector((state) => state.employees);
    const { selected } = useTypeSelector((state) => state.structures);
    const { getEscTableEmployees, postEscTableEmployees } = useActions();

    const [data, setData] = useState<EscTableEmployeeParams[]>([]);
    const [sortedData, setSortedData] = useState<EscTableEmployeeParams[]>();
    const [tableData, setTableData] = useState<TableRow[]>();
    const [editItem, setEditItem] = useState<number | undefined>();
    const [showPhone, setShowPhone] = useState<number | undefined>();
    const [showFIO, setShowFIO] = useState<number | undefined>();
    const [showActionsBar, setShowActionsBar] = useState<number | undefined>();
    const [addRow, setAddRow] = useState<number | undefined>();
    const [deleteRow, setDeleteRow] = useState<number | undefined>();
    const [changesSaved, setChangesSaved] = useState<boolean>(false);
    const [editRow, setEditedRow] = useState<EscTableEmployeeParams | undefined>();
    useEffect(() => {
        selected && getEscTableEmployees(selected);
    }, [selected, employees]); // следим за данными таблицы ответственные лица, так как при добавлении нового лица нужен его Id, приходящий с бэка

    useEffect(() => {
        escTableEmployees ? setData(escTableEmployees) : setData([]);
    }, [selected, escTableEmployees]);

    const onCloseAddRow = useCallback(() => {
        setAddRow(undefined);
    }, []);

    const onSaveAddRow = useCallback(
        (addData: EscTableEmployeeParams) => {
            if (typeof addRow === 'undefined') return;
            const updatedData = [...escTableEmployees];
            updatedData.push({
                ...addData,
                escalation_level: Number(addData.escalation_level),
                time_reaction: getCorrectReactionTime(Number(addData.escalation_level)),
            });
            postEscTableEmployees(updatedData, selected);
            setAddRow(undefined);
        },
        [addRow],
    );

    useEffect(() => {
        if (typeof deleteRow === 'undefined') return;
        const filteredTableData = data?.filter((el, i) => i !== deleteRow);
        postEscTableEmployees(filteredTableData, selected);
        setData(filteredTableData);
    }, [deleteRow]);

    useEffect(() => {
        if (changesSaved && editRow) {
            const updatedEmployee = escTableEmployees.map((el, index) => {
                if (index === editItem) {
                    return {
                        ...editRow,
                        escalation_level: Number(editRow.escalation_level),
                        time_reaction: getCorrectReactionTime(Number(editRow.escalation_level)),
                    };
                } else return el;
            });
            postEscTableEmployees(updatedEmployee, selected);
            setChangesSaved(false);
            setEditItem(undefined);
        }
    }, [changesSaved]);

    useEffect(() => {
        const closeActionsBar = () => {
            setShowActionsBar(undefined);
        };
        window.addEventListener('click', closeActionsBar);

        return () => {
            window.removeEventListener('click', closeActionsBar);
        };
    }, []);

    useEffect(() => {
        setTableData(
            createTableData({
                data,
                setData,
                showActionsBar,
                setShowActionsBar,
                showFIO,
                setShowFIO,
                showPhone,
                setShowPhone,
                editItem,
                setDeleteRow,
                setEditItem,
                setAddRow,
                editRow,
                setEditedRow,
                setChangesSaved,
            }),
        );
    }, [sortedData, showFIO, showPhone, showActionsBar, editItem, editRow]);
    useEffect(() => {
        data && data.sort((a, b) => Number(a.escalation_level) - Number(b.escalation_level));
        setSortedData(data);
    }, [data]);
    return (
        <div className="escalation-table-wrapper">
            <h3>Таблица эскалации</h3>
            <div className="table-container">{tableData && <Table columns={columns} data={tableData} />}</div>
            {typeof addRow !== 'undefined' && <AddRowModal onClose={onCloseAddRow} onSave={onSaveAddRow} />}
            {!tableData?.length && employees.length ? (
                <div className="action-item  pl-1 pt-1" onClick={() => setAddRow(0)}>
                    <PlusCircle size={16} />
                    <span className="title-item pl-50">Добавить</span>
                </div>
            ) : (
                <div className="note-action-item pt-1">
                    * Для добавления пользователя в Таблицу эскалации необходимо добавить пользователя в Таблицу
                    ответственных лиц
                </div>
            )}
        </div>
    );
};

export default memo(EscalationTable);
