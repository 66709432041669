import { Infomat } from '../../../../libs/icons';
import React, { useEffect } from 'react';
import { useTypeSelector } from '../../../../hooks/useTypeSelector';
import { useActions } from '../../../../hooks/useActions';
import { getCorrectName } from '../../../../utilits/getCorrectName';
import { useCommentsRetriever } from '../../../../hooks/useMessageRetriever';
import CamerasArea from './CamerasArea';

interface InfomatCountProps {
    openCameras: () => void;
    camerasCount: number;
}

export const InfomatCount = ({ openCameras, camerasCount }: InfomatCountProps): JSX.Element => {
    const { infomat } = useTypeSelector((state) => state.infomat);
    const { selected, structures } = useTypeSelector((state) => state.structures);
    const { getInfomatCount } = useActions();
    const messageTime = useCommentsRetriever({ path: 'infomat-customer-count', structureId: selected });
    useEffect(() => {
        getInfomatCount(selected || structures[0].structureId);
    }, [selected, messageTime]);

    return (
        <div className="card">
            <div className="card-header flex-column align-items-center pb-0">
                <CamerasArea openCameras={openCameras} camerasCount={camerasCount} />
                <div className="avatar infomat-icon m-0">
                    <Infomat color="#191C22" size="48" />
                </div>
                <h1 className="font-weight-bolder mt-1">
                    {infomat.value} {getCorrectName(infomat.value, 'человек')}
                </h1>
                <p className="card-text">Сейчас в очереди к инфомату</p>
                <br />
            </div>
        </div>
    );
};
