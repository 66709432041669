import React from 'react';
import './Footer.scss';
import InterLogo from './adtech_black_left.png';

export const Footer = ({ selectedMenu }: { selectedMenu: string }): JSX.Element => {
    return (
        <footer className={'footer footer-static footer-light' + (selectedMenu === 'map' ? ' absolute' : '')}>
            <p className="clearfix mb-0">
                <span className="float-md-left d-block d-md-inline-block">
                    <img alt="VEON" src={InterLogo} width={120} />
                </span>
            </p>
        </footer>
    );
};
