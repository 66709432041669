import { EventsState, eventsAction, EventsActionTypes } from '../../types/events';

const initialState: EventsState = {
    events: {
        structureId: '',
        datetime: '',
        count: 0,
        events: [],
    },
    loading: false,
    page: 0,
    limit: 20,
    error: null,
};

export const eventsReducer = (state: EventsState = initialState, action: eventsAction): EventsState => {
    switch (action.type) {
        case EventsActionTypes.GET_EVENTS: {
            return { ...state, loading: true };
        }
        case EventsActionTypes.GET_EVENTS_SUCCESS: {
            return { ...state, loading: false, events: action.payload };
        }
        case EventsActionTypes.GET_EVENTS_ERROR: {
            return { ...state, loading: false, error: action.payload };
        }

        case EventsActionTypes.POST_EVENTS: {
            return { ...state, loading: true };
        }
        case EventsActionTypes.POST_EVENTS_SUCCESS: {
            return { ...state, loading: false, events: action.payload };
        }
        case EventsActionTypes.POST_EVENTS_ERROR: {
            return { ...state, loading: false, error: action.payload };
        }

        case EventsActionTypes.SET_EVENTS_PAGE: {
            return { ...state, page: action.payload };
        }
        case EventsActionTypes.SET_EVENTS_LIMIT: {
            return { ...state, limit: action.payload };
        }

        default:
            return state;
    }
};
