import React, { useEffect } from 'react';
import { Router, Redirect, Route, Switch } from 'react-router-dom';
import { MainMap } from './MainMap/MainMap';
import { MenuCamera } from '../../components/MenuCamera/MenuCamera';
import { Menu, ScrollButton } from '../../components';
import { Dashboard } from './Dashboard/Dashboard';
import { Registration } from './Registration/Registration';
// import { Infomat } from './Infomat/Infomat';
import { Exit } from './Exit/Exit';
import { VideoWall } from './VideoWall/VideoWall';
import { Footer } from '../../components/Footer/Footer';
import { useActions } from '../../hooks/useActions';
import { useTypeSelector } from '../../hooks/useTypeSelector';
import history from '../../utilits/history';
import ObjectCard from './ObjectCard/ObjectCard';

export const Application = (): JSX.Element => {
    const { loading, structures } = useTypeSelector((state) => state.structures);
    const { data } = useTypeSelector((state) => state.user);

    const { getStructures, websocketConnect, getUserData } = useActions();

    useEffect(() => {
        getStructures();
        getUserData();
        websocketConnect();
    }, []);

    if (loading || structures.length === 0) return <></>;
    return (
        <>
            <Router history={history}>
                <Switch>
                    <Route exact path="/map">
                        <MainMap />
                        <Footer selectedMenu={'map'} />
                    </Route>
                    <Route exact path="/video-wall">
                        <MenuCamera />
                        <div className={'app-content content bg-black p-0'}>
                            <div className="content-wrapper">
                                <div className="content-body">
                                    <VideoWall />
                                </div>
                            </div>
                        </div>
                    </Route>

                    <Route exact path="/dashboard">
                        <Menu menuUrl="dashboard" />
                        <div className={'app-content content'}>
                            <div className="content-wrapper">
                                <div className="content-body">
                                    <Dashboard />
                                </div>
                            </div>
                        </div>
                        <Footer selectedMenu={'dashboard'} />
                    </Route>
                    <Route exact path="/registration">
                        <Menu menuUrl="registration" />
                        <div className={'app-content content'}>
                            <div className="content-wrapper">
                                <div className="content-body">
                                    <Registration />
                                </div>
                            </div>
                        </div>
                        <Footer selectedMenu={'dashboard'} />
                    </Route>
                    {/*<Route exact path="/infomat">*/}
                    {/*    <Menu menuUrl="infomat" />*/}
                    {/*    <div className={'app-content content'}>*/}
                    {/*        <div className="content-wrapper">*/}
                    {/*            <div className="content-body">*/}
                    {/*                <Infomat />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <Footer selectedMenu={'infomat'} />*/}
                    {/*</Route>*/}
                    <Route exact path="/exit">
                        <Menu menuUrl="exit" />
                        <div className={'app-content content'}>
                            <div className="content-wrapper">
                                <div className="content-body">
                                    <Exit />
                                </div>
                            </div>
                        </div>
                        <Footer selectedMenu={'exit'} />
                    </Route>
                    {data.roles.filter((el) => el === 'administrator').length > 0 && (
                        <Route exact path="/admin">
                            <Menu menuUrl="admin" />
                            <div className={'app-content content height-80-per pt-50'}>
                                <div className="content-wrapper height-95-per">
                                    <div className="content-body height-100-per">
                                        <ObjectCard />
                                    </div>
                                </div>
                            </div>
                            <Footer selectedMenu={'exit'} />
                        </Route>
                    )}
                    <Route path="*">
                        <Redirect to={'/map'} />
                        <Footer selectedMenu={'map'} />
                    </Route>
                </Switch>
            </Router>
            <ScrollButton />
        </>
    );
};
