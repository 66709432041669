import { videoAction, VideoActionTypes } from '../../types/video';
import { Dispatch } from 'react';
import axios from 'axios';

export const getVideo = (url: string, name: string) => {
    return async (dispatch: Dispatch<videoAction>): Promise<void> => {
        try {
            dispatch({
                type: VideoActionTypes.GET_VIDEO,
            });

            const response = await axios.get(url, {
                headers: { Authorization: `Bearer xtok-100K37-b84674edfce349009fcebab815e43902-ivrssDtRT5VYDIfC` },
            });
            response.data.name = name;
            dispatch({
                type: VideoActionTypes.GET_VIDEO_SUCCESS,
                payload: response.data,
            });
        } catch (e) {
            dispatch({ type: VideoActionTypes.GET_VIDEO_ERROR, payload: 'произошла ошибка получения видео' });
        }
    };
};
export function clearVideo(): videoAction {
    return { type: VideoActionTypes.CLEAR_VIDEO };
}
