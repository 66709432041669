import Chart from 'react-apexcharts';
import React, { useEffect, useMemo } from 'react';
import { useTypeSelector } from '../../../../hooks/useTypeSelector';
import { useActions } from '../../../../hooks/useActions';
import { dateFormatter, formatDate } from '../../../../utilits/formatDate';

export interface ServiceCountHistProps {
    type?: 'room' | '';
}

export const ServiceCountHist = ({ type = '' }: ServiceCountHistProps): JSX.Element => {
    const { reception, receptionRoom } = useTypeSelector((state) => state.reception);
    const { dateFilter } = useTypeSelector((state) => state.time);
    const { selected, structures } = useTypeSelector((state) => state.structures);
    const { postReceptionCount, postReceptionCountRoom } = useActions();

    useEffect(() => {
        postReceptionCount(selected || structures[0].structureId, dateFilter.startDate, dateFilter.endDate);
        postReceptionCountRoom(selected || structures[0].structureId, dateFilter.startDate, dateFilter.endDate);
    }, [selected, dateFilter]);

    const series = useMemo<ApexAxisChartSeries>(
        () => [
            {
                name: 'Количество',
                data: reception.values
                    ? reception.values.map(({ date, value }) => {
                          return {
                              x: new Date(date).getTime(),
                              y: value,
                          };
                      })
                    : [],
            },
        ],
        [reception.values],
    );

    const seriesRoom = useMemo<ApexAxisChartSeries>(
        () => [
            {
                name: 'Количество',
                data: receptionRoom.values
                    ? receptionRoom.values.map(({ date, value }) => {
                          return {
                              x: new Date(date).getTime(),
                              y: value,
                          };
                      })
                    : [],
            },
        ],
        [receptionRoom.values],
    );

    return (
        <div className="card">
            <div className="card-header d-flex flex-sm-row flex-column justify-content-md-between align-items-start justify-content-start">
                <div>
                    <h4 className="card-title font-weight-bolder">
                        {type === 'room' ? 'Регистратура у кабинета' : 'Регистратура'}
                    </h4>
                    <p className="card-subtitle mt-25 mb-25">Среднее число человек в регистратуре</p>
                </div>
            </div>
            <div className="card-body">
                <Chart
                    options={{
                        annotations: {
                            yaxis: [
                                {
                                    borderColor: '#1EBEAA',
                                    fillColor: '#1EBEAA',
                                    opacity: 0.1,
                                },
                            ],
                        },
                        tooltip: {
                            enabled: true,
                            followCursor: true,
                            x: {},
                            y: {
                                formatter: (value) => {
                                    return value + ' человек';
                                },
                            },
                        },
                        fill: {
                            type: 'gradient',
                            gradient: {
                                shadeIntensity: 1,
                                inverseColors: false,
                                opacityFrom: 0.5,
                                opacityTo: 0,
                                stops: [0, 90, 100],
                            },
                        },
                        dataLabels: {
                            enabled: true,
                        },
                        stroke: {
                            width: [5],
                            curve: 'straight',
                            dashArray: [0],
                        },
                        plotOptions: {
                            bar: {
                                horizontal: true,
                            },
                        },
                        yaxis: {
                            title: {
                                text: 'Человек',
                            },
                            tickAmount: 1,
                            min: 0,
                            max:
                                Math.max.apply(
                                    Math,
                                    (type === 'room'
                                        ? receptionRoom.values?.map(function ({ value }) {
                                              return value;
                                          })
                                        : reception.values?.map(function ({ value }) {
                                              return value;
                                          })) || [0],
                                ) + 2,
                        },
                        xaxis: {
                            labels: {
                                datetimeUTC: !!dateFilter.endDate,
                                formatter: (value) => {
                                    if (!dateFilter.endDate) {
                                        return dateFormatter(value);
                                    } else {
                                        return formatDate(value);
                                    }
                                },
                            },
                        },
                        chart: {
                            zoom: {
                                enabled: true,
                                autoScaleYaxis: false,
                                type: 'x',
                            },
                        },
                    }}
                    series={type === 'room' ? seriesRoom : series}
                    type="area"
                    height={400}
                />
            </div>
        </div>
    );
};
