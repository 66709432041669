import React, { useEffect, useMemo } from 'react';
import { useTypeSelector } from '../../../../hooks/useTypeSelector';
import { useActions } from '../../../../hooks/useActions';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_RU } from '../../../../libs/agGrid/locale.ru';
import { dateFormatter } from '../../../../utilits/formatDate';

export const EventsHist = (): JSX.Element => {
    const { events, limit, page } = useTypeSelector((state) => state.events);
    const { dateFilter } = useTypeSelector((state) => state.time);
    const { selected, structures } = useTypeSelector((state) => state.structures);
    const { postEvents } = useActions();

    const cellClass = (params: { data: { event: { grade: string } } }) => {
        switch (params.data.event.grade) {
            case 'info':
                return 'cell-info';
            case 'warning':
                return 'cell-warning';
            case 'trouble':
                return 'cell-trouble';
            default:
                return '';
        }
    };

    const gridOptions = {
        localeText: AG_GRID_LOCALE_RU,
        pagination: true,
        paginationPageSize: limit,
    };

    //TODO: change plugin cose ag-grid has server side (limit, page, group and etc) only in enterprise edition (not free). After change, add to postEvents page and limit params
    useEffect(() => {
        postEvents(selected || structures[0].structureId, dateFilter.startDate, dateFilter.endDate, 0, 999);
    }, [selected, structures, dateFilter, limit, page]);

    const eventFormatted = useMemo(
        () =>
            events.events.map((event) => ({
                ...event,
                datetime: dateFormatter(event.datetime),
            })),
        [events.events],
    );

    return (
        <>
            <div className="aggrid ag-theme-material" style={{ height: '520px', width: '100%' }}>
                {/*// @ts-ignore*/}
                <AgGridReact rowData={eventFormatted} gridOptions={gridOptions}>
                    <AgGridColumn field="datetime" headerName="ВРЕМЯ" flex={1} />
                    <AgGridColumn field="zone.name" headerName="МЕСТО СОБЫТИЯ" flex={1} />
                    <AgGridColumn cellClass={cellClass} field="event.description" headerName="СОБЫТИЕ" flex={1} />
                </AgGridReact>
            </div>
        </>
    );
};
