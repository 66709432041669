export interface EscTableEmployeeParams {
    employee_id: string;
    name: string;
    phone: string;
    position: string;
    escalation_level: number;
    time_reaction: string;
}

export interface EscTableEmployeeResponseParams {
    employee: {
        employeeId: string;
        name: string;
        phoneNumber: string;
        function: string;
    };
    level: number;
    id: string;
}

export interface PostEscTableEmployeeParams {
    employeeId: string;
    level: number;
}

export interface EscTableEmployeesState {
    escTableEmployees: EscTableEmployeeParams[];
    loading: boolean;
    error: null | string;
}

export enum EscTableEmployeesActionTypes {
    GET_ET_EMPLOYEES = 'GET_ET_EMPLOYEES',
    GET_ET_EMPLOYEES_SUCCESS = 'GET_ET_EMPLOYEES_SUCCESS',
    GET_ET_EMPLOYEES_ERROR = 'GET_ET_EMPLOYEES_ERROR',

    POST_ET_EMPLOYEES = 'POST_ET_EMPLOYEES',
    POST_ET_EMPLOYEES_SUCCESS = 'POST_ET_EMPLOYEES_SUCCESS',
    POST_ET_EMPLOYEES_ERROR = 'POST_ET_EMPLOYEES_ERROR',
}

interface GetEscTableEmployeesAction {
    type: EscTableEmployeesActionTypes.GET_ET_EMPLOYEES;
}

interface GetEscTableEmployeesSuccessAction {
    type: EscTableEmployeesActionTypes.GET_ET_EMPLOYEES_SUCCESS;
    payload: EscTableEmployeeParams[];
}

interface GetEscTableEmployeesErrorAction {
    type: EscTableEmployeesActionTypes.GET_ET_EMPLOYEES_ERROR;
    payload: string;
}

interface PostEscTableEmployeesAction {
    type: EscTableEmployeesActionTypes.POST_ET_EMPLOYEES;
}

interface PostEscTableEmployeesSuccessAction {
    type: EscTableEmployeesActionTypes.POST_ET_EMPLOYEES_SUCCESS;
    payload: EscTableEmployeeParams[];
}

interface PostEscTableEmployeesErrorAction {
    type: EscTableEmployeesActionTypes.POST_ET_EMPLOYEES_ERROR;
    payload: string;
}

export type EscTableEmployeeAction =
    | GetEscTableEmployeesAction
    | GetEscTableEmployeesSuccessAction
    | GetEscTableEmployeesErrorAction
    | PostEscTableEmployeesAction
    | PostEscTableEmployeesSuccessAction
    | PostEscTableEmployeesErrorAction;
