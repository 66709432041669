import { serviceAction, ServiceActionTypes } from '../../types/service';
import { Dispatch } from 'react';
import axios from 'axios';
import { Url } from '../../types/url';
import { dateStringToTimestamp } from '../../utilits/formatDate';
export const getServiceAvg = (structureID: string) => {
    return async (dispatch: Dispatch<serviceAction>): Promise<void> => {
        try {
            dispatch({
                type: ServiceActionTypes.GET_SERVICE_AVG,
            });

            const response = await axios.get(`${Url.Server}/api/1.0/dashboard/service-zone-avg-time/${structureID}`);
            dispatch({
                type: ServiceActionTypes.GET_SERVICE_AVG_SUCCESS,
                payload: response.data,
            });
        } catch (e) {
            dispatch({
                type: ServiceActionTypes.GET_SERVICE_AVG_ERROR,
                payload: 'Произошла ошибка получения данных регистратуры',
            });
        }
    };
};

export const postServiceAvg = (structureID: string | undefined, startDate: string, endDate: null | string) => {
    return async (dispatch: Dispatch<serviceAction>): Promise<void> => {
        try {
            dispatch({
                type: ServiceActionTypes.POST_SERVICE_AVG,
            });

            const response = await axios.post(`${Url.Server}/api/1.0/dashboard/service-zone-avg-time/${structureID}`, {
                startDate: dateStringToTimestamp(startDate),
                endDate: endDate ? dateStringToTimestamp(endDate) : null,
            });
            dispatch({
                type: ServiceActionTypes.POST_SERVICE_AVG_SUCCESS,
                payload: response.data,
            });
        } catch (e) {
            dispatch({
                type: ServiceActionTypes.POST_SERVICE_AVG_ERROR,
                payload: 'Произошла ошибка получения данных регистратуры',
            });
        }
    };
};

export const postServiceByCustomer = (structureID: string | undefined, dt: string) => {
    return async (dispatch: Dispatch<serviceAction>): Promise<void> => {
        try {
            dispatch({
                type: ServiceActionTypes.POST_SERVICE_BY_CUSTOMER,
            });

            const response = await axios.post(
                `${Url.Server}/api/1.0/dashboard/service-zone-by-customer-time/${structureID}`,
                {
                    dt: dateStringToTimestamp(dt),
                },
            );
            dispatch({
                type: ServiceActionTypes.POST_SERVICE_BY_CUSTOMER_SUCCESS,
                payload: response.data,
            });
        } catch (e) {
            dispatch({
                type: ServiceActionTypes.POST_SERVICE_BY_CUSTOMER_ERROR,
                payload: 'Произошла ошибка получения данных регистратуры',
            });
        }
    };
};
