import React, { useEffect, useState } from 'react';
import { useTypeSelector } from '../../hooks/useTypeSelector';
import { useActions } from '../../hooks/useActions';
import { DatePicker } from '@bigdata/ui-kit';
import './Calendar.scss';

interface CalendarProps {
    height?: string | number;
}

export const Calendar: React.FC<CalendarProps> = ({ height = 'auto' }: CalendarProps) => {
    const { selected } = useTypeSelector((state) => state.structures);
    const { dateFilter } = useTypeSelector((state) => state.time);
    const { setTime } = useActions();
    const [isMount, setIsMount] = useState(true);

    useEffect(() => () => setIsMount(false), [dateFilter]);

    useEffect(() => () => setIsMount(true), [selected]);

    return (
        <div className="card" style={{ height: height }}>
            <DatePicker
                value={
                    dateFilter.endDate
                        ? [dateFilter.startDate, dateFilter.endDate]
                        : isMount
                        ? new Date(dateFilter.startDate)
                        : dateFilter.startDate
                }
                onChange={(t: [Date, Date | undefined] | Date) => {
                    if (!Array.isArray(t)) return;
                    setTime({
                        startDate: t[0].toLocaleDateString('fr-CA', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        }),
                        endDate:
                            t[1] && t[0].toDateString() !== t[1].toDateString()
                                ? t[1].toLocaleDateString('fr-CA', {
                                      year: 'numeric',
                                      month: '2-digit',
                                      day: '2-digit',
                                  })
                                : null,
                    });
                }}
            />
        </div>
    );
};
