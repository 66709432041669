export interface InfomatParams {
    structureId: string;
    value: number;
}

export interface InfomatState {
    infomat: InfomatParams;
    loading: boolean;
    error: null | string;
}

export enum InfomatActionTypes {
    GET_INFOMAT_COUNT = 'GET_INFOMAT_COUNT',
    GET_INFOMAT_COUNT_SUCCESS = 'GET_INFOMAT_COUNT_SUCCESS',
    GET_INFOMAT_COUNT_ERROR = 'GET_INFOMAT_COUNT_ERROR',
}

interface GetInfomatAction {
    type: InfomatActionTypes.GET_INFOMAT_COUNT;
}

interface GetInfomatSuccessAction {
    type: InfomatActionTypes.GET_INFOMAT_COUNT_SUCCESS;
    payload: InfomatParams;
}

interface GetInfomatErrorAction {
    type: InfomatActionTypes.GET_INFOMAT_COUNT_ERROR;
    payload: string;
}

export type infomatAction = GetInfomatAction | GetInfomatSuccessAction | GetInfomatErrorAction;
