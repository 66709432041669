export interface StructureParams {
    structureId: string;
    name: string;
    description: string;
    address: string;
    gps: {
        lon: number;
        lat: number;
    };
    cameras: {
        gates?: CameraState[];
        receptions?: CameraState[];
        workplaces?: CameraState[];
        infomats?: CameraState[];
    };
}

export interface CameraState {
    id: string;
    description: string;
    streamURL: string;
    online: boolean;
    area: string;
}
export interface ActiveStructureState {
    address: string;
    closeTime: string;
    legalName: string;
    name: string;
    openTime: string;
}
export interface StructuresState {
    structures: StructureParams[];
    loading: boolean;
    error: null | string;
    selected: string;
}

export enum GetStructuresActionTypes {
    GET_STRUCTURES = 'GET_STRUCTURES',
    GET_STRUCTURES_SUCCESS = 'GET_STRUCTURES_SUCCESS',
    GET_STRUCTURES_ERROR = 'GET_STRUCTURES_ERROR',
    SET_SELECTED_STRUCTURE = 'SET_SELECTED_STRUCTURE',
}

interface GetStructuresAction {
    type: GetStructuresActionTypes.GET_STRUCTURES;
}

interface GetStructuresSuccessAction {
    type: GetStructuresActionTypes.GET_STRUCTURES_SUCCESS;
    payload: [];
}

interface GetStructuresErrorAction {
    type: GetStructuresActionTypes.GET_STRUCTURES_ERROR;
    payload: string;
}

interface SetSelectedStructureAction {
    type: GetStructuresActionTypes.SET_SELECTED_STRUCTURE;
    payload: string;
}

export type structuresAction =
    | GetStructuresAction
    | GetStructuresSuccessAction
    | GetStructuresErrorAction
    | SetSelectedStructureAction;
