import React, { useCallback, useEffect, useState } from 'react';
import { useTypeSelector } from '../../../../hooks/useTypeSelector';
import { VideoModal } from '../../../../components/VideoPlayer/StreamPlayer';
import { Camera } from '../../../../libs/icons';
import { getCorrectTimeLabel } from '../../../../utilits/getCorrectName';
import { CameraState } from '../../../../types/structures';

export const Cameras = (): JSX.Element => {
    const { structures, selected } = useTypeSelector((state) => state.structures);
    const [videoSrc, setVideoSrc] = useState<string | undefined>();
    const [camerasAmount, setCamerasAmount] = useState(0);
    const [cameras, setCameras] = useState<CameraState[]>([]);
    const structure = structures.find(({ structureId }) => structureId === selected);
    const [showFrame, setShowFrame] = useState(false);
    const closeVideoModal = useCallback(() => {
        setVideoSrc(undefined);
    }, []);

    useEffect(() => {
        const _cameras: Record<string, CameraState> = {};
        if (structure) {
            Object.entries(structure?.cameras).forEach((el: [string, CameraState[]]) => {
                el[1].forEach((_el) => {
                    _cameras[_el.id] = _el;
                });
            });
        }
        setCamerasAmount(Object.keys(_cameras).length);
        setCameras(Object.values(_cameras));
    }, [structures]);

    return (
        <>
            <div className="card d-flex cameras">
                <div className="card-body">
                    <span className="right-corner-tab">Подключено</span>
                    <h2 className="font-weight-bolder pt-1">
                        {camerasAmount} {getCorrectTimeLabel(camerasAmount, ['камера', 'камеры', 'камер'])}
                    </h2>
                    <ul className="list-style-icons p-0 m-0 pt-2 overflow-auto" style={{ height: '200px' }}>
                        {structure &&
                            cameras &&
                            cameras.map(({ online, streamURL, description }) => {
                                // return el.map(({ online, streamURL, description }) => {
                                return (
                                    <li className="pb-1" key={streamURL}>
                                        <button
                                            style={{
                                                borderRadius: '30px',
                                                fontWeight: 400,
                                            }}
                                            type="button"
                                            onClick={() => {
                                                if (online) {
                                                    setVideoSrc(streamURL);
                                                    setShowFrame(true);
                                                }
                                            }}
                                            className="btn btn-flat-secondary waves-effect"
                                        >
                                            <span className="card-icon">
                                                <Camera color={online ? '#1790FF' : '#F06767FF'} size="20" />
                                            </span>{' '}
                                            {description}
                                        </button>
                                    </li>
                                );
                                // });
                            })}
                    </ul>
                </div>
            </div>
            {showFrame && videoSrc && <VideoModal src={videoSrc} close={closeVideoModal} />}
        </>
    );
};
