import React, { useEffect, useMemo } from 'react';
import { useTypeSelector } from '../../../../hooks/useTypeSelector';
import { useActions } from '../../../../hooks/useActions';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_RU } from '../../../../libs/agGrid/locale.ru';
import { useCommentsRetriever } from '../../../../hooks/useMessageRetriever';
import { dateFormatter } from '../../../../utilits/formatDate';

export const Events = (): JSX.Element => {
    const { events } = useTypeSelector((state) => state.events);

    const { selected, structures } = useTypeSelector((state) => state.structures);
    const { getEvents } = useActions();
    const messageTime = useCommentsRetriever({ path: 'events', structureId: selected });

    const cellClass = (params: { data: { event: { grade: string } } }) => {
        switch (params.data.event.grade) {
            case 'info':
                return 'cell-info';
            case 'warning':
                return 'cell-warning';
            case 'trouble':
                return 'cell-trouble';
            default:
                return '';
        }
    };

    const gridOptions = {
        localeText: AG_GRID_LOCALE_RU,
        pagination: false,
    };
    useEffect(() => {
        getEvents(selected || structures[0].structureId);
    }, [selected, messageTime]);

    const eventFormatted = useMemo(
        () =>
            events.events.map((event) => ({
                ...event,
                datetime: dateFormatter(event.datetime),
            })),
        [events.events],
    );

    return (
        <>
            <div className="aggrid ag-theme-material" style={{ height: '520px', width: '100%' }}>
                {/*// @ts-ignore*/}
                <AgGridReact rowData={eventFormatted} gridOptions={gridOptions}>
                    <AgGridColumn field="datetime" headerName="ВРЕМЯ" flex={1} />
                    <AgGridColumn field="zone.name" headerName="МЕСТО СОБЫТИЯ" flex={1} />
                    <AgGridColumn field="event.description" cellClass={cellClass} headerName="СОБЫТИЕ" flex={1} />
                </AgGridReact>
            </div>
        </>
    );
};
