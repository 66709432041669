import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { structuresReducer } from './structuresReducer';
import { structuresStatusesReducer } from './statusesReducer';

import { workplaceReducer } from './workplaceReducer';
import { infomatReducer } from './infomatReducer';
import { gateReducer } from './gateReducer';
import { serviceReducer, serviceByCustomerReducer } from './serviceReducer';
import { timeReducer } from './timeReducer';
import { eventsReducer } from './eventsReducer';
import { receptionReducer } from './receptionReducer';
import { videoReducer } from './videoReducer';
import { websocketsConnectReducer } from './websocketsConnetcReducer';
import { userReducer } from './userReducer';
import { employeeReducer } from './employeeReducer';
import { escTableEmployeeReducer } from './escTableEmployeeReducer';

export const rootReducer = combineReducers({
    auth: authReducer,
    structures: structuresReducer,
    structuresStatuses: structuresStatusesReducer,
    workplace: workplaceReducer,
    infomat: infomatReducer,
    gate: gateReducer,
    service: serviceReducer,
    serviceByCustomer: serviceByCustomerReducer,
    time: timeReducer,
    events: eventsReducer,
    reception: receptionReducer,
    video: videoReducer,
    websocketsConnect: websocketsConnectReducer,
    user: userReducer,
    employees: employeeReducer,
    escTableEmployees: escTableEmployeeReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
