export interface WebsocketsConnectState {
    loading: boolean;
    connected: boolean;
    error: null | string;
    messages: MessageProps[];
}

export interface MessageProps {
    request: string;
    structureId: string;
}

export enum WebsocketsConnectActionTypes {
    GET_WEBSOCKETS_CONNECT = 'GET_WEBSOCKETS_CONNECT',
    GET_WEBSOCKETS_CONNECT_SUCCESS = 'GET_WEBSOCKETS_CONNECT_SUCCESS',
    GET_WEBSOCKETS_CONNECT_ERROR = 'GET_WEBSOCKETS_CONNECT_ERROR',
    GET_MESSAGE = 'GET_MESSAGE',
}

interface GetWebsocketsConnectAction {
    type: WebsocketsConnectActionTypes.GET_WEBSOCKETS_CONNECT;
}

interface GetWebsocketsConnectSuccessAction {
    type: WebsocketsConnectActionTypes.GET_WEBSOCKETS_CONNECT_SUCCESS;
}

interface GetWebsocketsConnectErrorAction {
    type: WebsocketsConnectActionTypes.GET_WEBSOCKETS_CONNECT_ERROR;
    payload: string;
}

interface GetMessageAction {
    type: WebsocketsConnectActionTypes.GET_MESSAGE;
    payload: MessageProps;
}

export type workplaceAction =
    | GetWebsocketsConnectAction
    | GetWebsocketsConnectSuccessAction
    | GetWebsocketsConnectErrorAction
    | GetMessageAction;
