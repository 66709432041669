import React, { useEffect, useState } from 'react';
import { formatDate } from '../../../../utilits/formatDate';
import { useTypeSelector } from '../../../../hooks/useTypeSelector';
import { useActions } from '../../../../hooks/useActions';
import { Chart, EChartType } from '../../../../components/Chart';
import { WorkplaceHistParams } from '../../../../types/workplace';

export const WorkplaceAttendancePeriod = ({
    startDate,
    endDate,
}: {
    startDate: string;
    endDate: string;
}): JSX.Element => {
    const { workplaceHist } = useTypeSelector((state) => state.workplace);
    const { selected, structures } = useTypeSelector((state) => state.structures);

    const [selectedWorkplace, setSelectedWorkplace] = useState(0);
    const { postWorkplaceHist } = useActions();

    useEffect(() => {
        postWorkplaceHist(selected || structures[0].structureId, startDate, endDate);
    }, [selected, startDate, endDate]);

    const chartOptions = {
        xaxis: {
            categories: workplaceHist.workPlaces[0]?.dates.map(({ dt }) => formatDate(dt)),
        },
        yaxis: {
            min: 0,
            max: 100,
            borderRadius: 16,
            labels: {
                formatter: (value: string | number) => {
                    return value + '%';
                },
            },
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '15%',
                endingShape: 'rounded',
                colors: {
                    backgroundBarOpacity: 1,
                    backgroundBarRadius: 0,
                },
            },
        },
        fill: {
            opacity: 1,
        },
        colors: ['#1EBEAA', '#DFE0E5'],
        chart: {
            stacked: true,
        },
    };

    const dataFormatter = (data: WorkplaceHistParams) => {
        return [
            {
                name: 'Присутствовал',
                data: data.workPlaces[selectedWorkplace].dates.map(({ value }) => new Date(value).getTime()),
            },
            {
                name: 'Отсутсвовал',
                data: data.workPlaces[selectedWorkplace].dates.map(({ value }) => new Date(100 - value).getTime()),
            },
        ];
    };
    return (
        <div className="card">
            <div className="card-header d-flex flex-sm-row flex-column justify-content-md-between align-items-start justify-content-start">
                <div>
                    <h4 className="card-title font-weight-bolder">Регистраторы за рабочим местом</h4>
                    <p className="card-subtitle mt-25 mb-25">{`Данные за ${formatDate(startDate)} -${formatDate(
                        endDate,
                    )}`}</p>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <div className="form-group text-center">
                            {workplaceHist.workPlaces.map(({ zone }, index) => {
                                return (
                                    <div key={index} className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="inlineRadioOptions"
                                            id={'inlineRadio_' + index}
                                            value={index}
                                            checked={index === selectedWorkplace}
                                            onChange={() => setSelectedWorkplace(index)}
                                        />
                                        <label className="form-check-label" htmlFor={'inlineRadio_' + index}>
                                            {zone.name}
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                {workplaceHist.workPlaces.length > 0 && (
                    <Chart
                        chartOptions={chartOptions}
                        data={workplaceHist}
                        dataFormatter={dataFormatter}
                        chartSettings={{
                            type: EChartType.Bar,
                        }}
                        height={400}
                    />
                )}
            </div>
        </div>
    );
};
