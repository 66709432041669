import React from 'react';
import Form from './Form/Form';
import './Login.scss';

const Login = (): JSX.Element => {
    return (
        <div className="app-content content login p-0">
            <div className="content-overlay" />
            <div className="header-navbar-shadow" />
            <div className="content-wrapper">
                <div className="content-header row" />
                <div className="content-body">
                    <div className="auth-wrapper auth-v2">
                        <div className="auth-inner row m-0">
                            <div className="d-none d-lg-flex col-lg-8 align-items-center p-0">
                                <div className="w-100 h-100 d-lg-flex align-items-center justify-content-center">
                                    <div className="login-logo" />
                                </div>
                            </div>
                            <Form />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
