//TODO: Сделать универсальный метод
export function getCorrectName(value: number, label: string): string {
    let x = '';
    if (value % 100 < 11 || value % 100 > 14) {
        switch (value % 10) {
            case 1:
                x = '';
                break;
            case 2:
                x = 'а';
                break;
            case 3:
                x = 'а';
                break;
            case 4:
                x = 'а';
                break;
        }
    }
    return label + x;
}

export function getCorrectEmployeeName(value: number, label: string): string {
    let x = 'ов';
    if (value % 100 < 11 || value % 100 > 14) {
        switch (value % 10) {
            case 1:
                x = '';
                break;
            case 2:
                x = 'а';
                break;
            case 3:
                x = 'а';
                break;
            case 4:
                x = 'а';
                break;
        }
    }
    return label + x;
}

export function getCorrectTimeLabel(value: number, label: string[]): string {
    const n1 = value % 10;
    if (value > 10 && value < 20) {
        return label[2];
    }
    if (n1 > 1 && n1 < 5) {
        return label[1];
    }
    if (n1 == 1) {
        return label[0];
    }
    return label[2];
}
