import * as authCreators from '../action/authAction';
import * as structuresCreators from '../action/structuresAction';
import * as statusesCreators from '../action/statusesAction';
import * as serviceCreators from '../action/serviceAction';
import * as workplaceCreators from '../action/workplaceAction';
import * as infomatCreators from '../action/infomatAction';
import * as gateCreators from '../action/gateAction';
import * as receptionCreators from '../action/receptionAction';
import * as timeCreators from '../action/timeAction';
import * as eventsCreators from '../action/eventsAction';
import * as videoCreators from '../action/videoAction';
import * as websocketConnectCreators from '../action/websocketsConnectAction';
import * as userCreators from '../action/userAction';
import * as employeeCreators from '../action/employeeAction';
import * as escTableEmployeeCreators from '../action/escTableEmployeeAction';

export default {
    ...authCreators,
    ...structuresCreators,
    ...statusesCreators,
    ...serviceCreators,
    ...workplaceCreators,
    ...infomatCreators,
    ...gateCreators,
    ...receptionCreators,
    ...timeCreators,
    ...eventsCreators,
    ...videoCreators,
    ...websocketConnectCreators,
    ...userCreators,
    ...employeeCreators,
    ...escTableEmployeeCreators,
};
