import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import './MainMap.scss';
import { Link } from 'react-router-dom';
import { YMaps, Clusterer, Map, Placemark } from 'react-yandex-maps';
import { Compass, LogOut } from 'react-feather';
import { useTypeSelector } from '../../../hooks/useTypeSelector';
import { useActions } from '../../../hooks/useActions';
import { Tab } from './Tab/Tab';
import { Camera, Video } from '../../../libs/icons';
import { useCommentsRetriever } from '../../../hooks/useMessageRetriever';
import httpClient from '../../../utilits/httpClient';

export const MainMap = (): JSX.Element => {
    const { structures } = useTypeSelector((state) => state.structures);
    const { statuses } = useTypeSelector((state) => state.structuresStatuses);
    const { login } = useTypeSelector((state) => state.user);

    const { setSelectedStructure } = useActions();
    const messageTime = useCommentsRetriever({ path: 'event' });
    const [activeZoneType, setActiveZoneType] = useState('');
    const iconDefault = './images/iconDefault.png';
    const [showPlayer, setShowPlayer] = useState(false);
    const { getStructuresStatuses } = useActions();

    const [centerLat, setCenterLat] = useState<number>(55.75);
    const [centerLon, setCenterLon] = useState<number>(37.57);
    const [zoom, setZoom] = useState<number>(10);

    useLayoutEffect(() => {
        setSelectedStructure('');
    }, []);

    useEffect(() => {
        if (structures) {
            const coordinates: { lons: number[]; lats: number[] } = {
                lons: [],
                lats: [],
            };

            structures.forEach((item) => {
                coordinates.lons.push(item.gps.lon);
                coordinates.lats.push(item.gps.lat);
            });

            const minLon = Math.min(...coordinates.lons);
            const maxLon = Math.max(...coordinates.lons);
            const minLat = Math.min(...coordinates.lats);
            const maxLat = Math.max(...coordinates.lats);

            const centerLon = minLon + (maxLon - minLon) / 2;
            const centerLat = minLat + (maxLat - minLat) / 2 + (maxLat - minLat) * 0.1;

            setCenterLat(centerLat);
            setCenterLon(centerLon);

            const maxDiff = Math.round(Math.max(maxLon - minLon, maxLat - minLat));

            if (maxDiff < 25) {
                setZoom(10 - Math.round(maxDiff * 0.24));
            } else {
                setZoom(10 - Math.round(maxDiff * 0.17));
            }
        }
    }, [structures]);

    useEffect(() => {
        getStructuresStatuses();
    }, [messageTime]);

    const countZoneType = [
        {
            name: 'ok',
            params: {
                countFrom: 0,
                countTo: 9,
            },
            icon: './images/iconGreen.png',
            className: 'success',
            color: '#1EBEAA',
            displayName: 'Хорошо',
            size: statuses.filter(({ countEventLastHour }) => countEventLastHour <= 9).length,
        },
        {
            name: 'warning',
            params: {
                countFrom: 10,
                countTo: 19,
            },
            icon: './images/iconYellow.png',
            className: 'warning',
            color: '#FF9F43',
            displayName: 'Некритично',
            size: statuses.filter(({ countEventLastHour }) => countEventLastHour >= 10 && countEventLastHour <= 19)
                .length,
        },
        {
            name: 'danger',
            params: {
                countFrom: 20,
                countTo: 999,
            },
            icon: './images/iconRed.png',
            className: 'danger',
            color: '#EA5455',

            displayName: 'Критично',
            size: statuses.filter(({ countEventLastHour }) => countEventLastHour >= 20).length,
        },
    ];

    const logout = useCallback(() => {
        httpClient.removeTokens();
        window.location.href = '/login';
    }, []);

    return (
        <div className="map-container overflow-hidden">
            <div className="horizontal-menu-wrapper p-0">
                <div
                    className="header-navbar navbar-expand navbar navbar-horizontal floating-nav navbar-light navbar-shadow menu-border"
                    role="navigation"
                >
                    <div className="shadow-bottom" />
                    <div className="navbar-container main-menu-content" data-menu="menu-container">
                        <div className="row">
                            <div className="col-3" style={{ marginTop: '4px' }}>
                                <ul className="nav navbar-nav">
                                    <li className="nav-item">
                                        <Compass style={{ height: '40px', width: '40px' }} />
                                    </li>
                                    <li className="nav-item">
                                        <span>Всего объектов: {structures.length}</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-7" style={{ marginTop: '4px' }}>
                                <ul className="nav navbar-nav float-right">
                                    {countZoneType.map(({ displayName, size, className, color, name }, index) => {
                                        return (
                                            <li className="nav-item" key={index}>
                                                <button
                                                    onClick={() =>
                                                        setActiveZoneType(
                                                            activeZoneType && activeZoneType === name ? '' : name,
                                                        )
                                                    }
                                                    className={
                                                        'btn btn-icon clinic-state rounded-circle waves-effect waves-float waves-light btn-relief-' +
                                                        className
                                                    }
                                                    style={{
                                                        background: !activeZoneType
                                                            ? color
                                                            : activeZoneType === name
                                                            ? color
                                                            : '#BABFC7',
                                                    }}
                                                >
                                                    {size}
                                                </button>
                                                <span>{displayName}</span>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                            <div className="col-2">
                                <div className="row">
                                    <div className="col-3 text-center" style={{ borderLeft: '1px solid #EFEFEF' }}>
                                        <button
                                            onClick={() => setShowPlayer(true)}
                                            className="btn btn-icon rounded-circle waves-effect waves-float waves-light btn-heading btn-icon-video"
                                        >
                                            <Video color={'white'} />
                                        </button>
                                    </div>
                                    <div className="col-3 text-center">
                                        <Link
                                            to={'video-wall'}
                                            className="btn btn-icon rounded-circle waves-effect waves-float waves-light btn-heading btn-icon-video ml-25"
                                        >
                                            <Camera color={'white'} />
                                        </Link>
                                    </div>
                                    <div className="col-2" />
                                    <div className="col-3 text-center">
                                        <a
                                            onClick={logout}
                                            className="btn btn-icon btn-logout rounded-circle waves-effect waves-float waves-light btn-heading"
                                        >
                                            <LogOut color="#6E6B7B" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <YMaps query={{ lang: 'en_US' }}>
                <Map
                    height="100vh"
                    width="100vw"
                    defaultState={{
                        center: [centerLat, centerLon],
                        zoom: zoom,
                        controls: [],
                    }}
                    options={{ suppressMapOpenBlock: true }}
                    modules={['geoObject.addon.hint']}
                >
                    <Clusterer>
                        {structures.map(({ structureId, gps, name }, index) => {
                            const countEventLastHour =
                                statuses.find((data) => data.structureId == structureId)?.countEventLastHour || 0;
                            const zoneType = countZoneType.find(
                                ({ params }) =>
                                    params.countFrom <= countEventLastHour && params.countTo >= countEventLastHour,
                            );
                            return (
                                <Placemark
                                    key={index}
                                    onClick={() => {
                                        setSelectedStructure(structureId);
                                    }}
                                    options={{
                                        iconLayout: 'default#image',
                                        iconImageHref:
                                            activeZoneType && activeZoneType != zoneType?.name
                                                ? iconDefault
                                                : zoneType?.icon,
                                        iconImageSize: [50, 50],
                                        iconImageOffset: [-25, -25],
                                    }}
                                    geometry={[gps.lat, gps.lon]}
                                    properties={{ hintContent: name }}
                                />
                            );
                        })}
                    </Clusterer>
                </Map>
            </YMaps>
            {showPlayer && (
                <div
                    className="modal text-center show modal-camera"
                    style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.75)' }}
                    role="dialog"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    onClick={() => setShowPlayer(false)}
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <video width="100%" height="100%" autoPlay={true} controls={false} loop={true}>
                                    {login === 'netris@demo.ru' ? (
                                        <>
                                            <source
                                                src={`https://${process.env['REACT_APP_HOST']}/media/camera_netris.mp4`}
                                                type="video/mp4"
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <source
                                                src={`https://${process.env['REACT_APP_HOST']}/media/camera.mp4`}
                                                type="video/mp4"
                                            />
                                            <source
                                                src={`https://${process.env['REACT_APP_HOST']}/media/camera.webm`}
                                                type="video/webm"
                                            />
                                        </>
                                    )}
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Tab />
        </div>
    );
};
