import { eventsAction, EventsActionTypes } from '../../types/events';
import { Dispatch } from 'react';
import axios from 'axios';
import { Url } from '../../types/url';
import { dateStringToTimestamp } from '../../utilits/formatDate';
export const getEvents = (structureID: string) => {
    return async (dispatch: Dispatch<eventsAction>): Promise<void> => {
        try {
            dispatch({
                type: EventsActionTypes.GET_EVENTS,
            });

            const response = await axios.get(`${Url.Server}/api/1.0/dashboard/events/${structureID}`);
            dispatch({
                type: EventsActionTypes.GET_EVENTS_SUCCESS,
                payload: response.data,
            });
        } catch (e) {
            dispatch({
                type: EventsActionTypes.GET_EVENTS_ERROR,
                payload: 'Произошла ошибка получения событий',
            });
        }
    };
};

export const postEvents = (
    structureID: string,
    startDate: string,
    endDate: null | string,
    page: number,
    limit: number,
) => {
    return async (dispatch: Dispatch<eventsAction>): Promise<void> => {
        try {
            dispatch({
                type: EventsActionTypes.POST_EVENTS,
            });

            const response = await axios.post(`${Url.Server}/api/1.0/dashboard/events/${structureID}`, {
                startDate: dateStringToTimestamp(startDate),
                endDate: endDate ? dateStringToTimestamp(endDate) : null,
                paging: [
                    {
                        page: page,
                        limit: limit,
                    },
                ],
            });
            dispatch({
                type: EventsActionTypes.POST_EVENTS_SUCCESS,
                payload: response.data,
            });
        } catch (e) {
            dispatch({
                type: EventsActionTypes.POST_EVENTS_ERROR,
                payload: 'Произошла ошибка получения событий',
            });
        }
    };
};

export function setEventsLimit(limit: number): eventsAction {
    return { type: EventsActionTypes.SET_EVENTS_LIMIT, payload: limit };
}

export function setEventsPage(page: number): eventsAction {
    return { type: EventsActionTypes.SET_EVENTS_PAGE, payload: page };
}
