import React, { useEffect, useState } from 'react';
import './VideoWall.scss';
import { useTypeSelector } from '../../../hooks/useTypeSelector';
import { StreamPlayer } from '../../../components/VideoPlayer/StreamPlayer';
import { CameraState } from '../../../types/structures';

interface Cameras {
    url: string;
    name: string;
}

export const VideoWall = (): JSX.Element => {
    const [cameras, setCameras] = useState<Cameras[]>([]);
    const { structures } = useTypeSelector((state) => state.structures);
    const [height, setHeight] = useState(window.innerHeight);
    const handleResize = () => {
        setHeight(window.innerHeight);
    };

    useEffect(() => {
        const _cameras: Record<string, Cameras> = {};
        structures.map(({ cameras, address }) => {
            Object.entries(cameras).forEach((el: [string, CameraState[]]) => {
                el[1].forEach((item) => (_cameras[item.id] = { url: item.streamURL, name: address }));
            });
        });
        setCameras(Object.values(_cameras));
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [structures]);

    return (
        <div className="video-wall">
            <div className="row match-height">
                <div className="col-12">
                    <div className="card p-0">
                        <div className="row video-grid p-0">
                            {cameras.map(({ url, name }, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="col-3"
                                        style={{
                                            height: Math.ceil(height / Math.ceil((cameras.length + 1) / 4)) + 'px',
                                        }}
                                    >
                                        <div className="card-body h-100 p-0">
                                            <StreamPlayer src={url} controls={false} />
                                        </div>
                                        <div className="card-header flex-column align-items-start p-0">
                                            <span>{name}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
