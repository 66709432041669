import { Workplace } from '../../../../libs/icons';
import React, { useEffect } from 'react';
import { useTypeSelector } from '../../../../hooks/useTypeSelector';
import { useActions } from '../../../../hooks/useActions';
import { getCorrectEmployeeName } from '../../../../utilits/getCorrectName';
import { useCommentsRetriever } from '../../../../hooks/useMessageRetriever';
import CamerasArea from './CamerasArea';

interface WorkplaceCountProps {
    openCameras: () => void;
    camerasCount: number;
}

export const WorkplaceCount = ({ openCameras, camerasCount }: WorkplaceCountProps): JSX.Element => {
    const { workplace } = useTypeSelector((state) => state.workplace);
    const { selected, structures } = useTypeSelector((state) => state.structures);
    const { getWorkplaceCount } = useActions();
    const messageTime = useCommentsRetriever({ path: 'workplace-count-employee', structureId: selected });
    useEffect(() => {
        getWorkplaceCount(selected || structures[0].structureId);
    }, [selected, messageTime]);

    return (
        <div className="card">
            <div className="card-header flex-column align-items-center pb-0">
                <CamerasArea openCameras={openCameras} camerasCount={camerasCount} />
                <div className="avatar workspace-icon m-0">
                    <Workplace color="#191C22" size="48" />
                </div>
                <h1 className="font-weight-bolder mt-1">
                    {workplace.value} {getCorrectEmployeeName(workplace.value, 'сотрудник')}
                </h1>
                <p className="card-text">Сейчас на рабочем месте</p>
                <br />
            </div>
        </div>
    );
};
