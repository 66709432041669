export interface DateInterface {
    startDate: string;
    endDate: string | null;
}

export interface TimeState {
    dateFilter: DateInterface;
}

export enum TimeActionTypes {
    SET_TIME = 'SET_TIME',
}

interface SetTimeAction {
    type: TimeActionTypes.SET_TIME;
    payload: DateInterface;
}

export type timeAction = SetTimeAction;
