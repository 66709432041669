import React, { useCallback, useEffect, useState } from 'react';
import { Input, Button, Select, Icon } from '@bigdata/ui-kit';
import './AddRowModal.scss';
import { EmployeeParams } from '../../../types/employee';
import { getCorrectReactionTime } from '../../../utilits/getCorrectReactionTime';
import { useTypeSelector } from '../../../hooks/useTypeSelector';
import { EscTableEmployeeParams } from '../../../types/escTableEmployee';
import { getEscalationLevelArray, levelsArray } from '../../../utilits/getEscalationLevelArray';

interface AddRowModal {
    onClose: () => void;
    onSave: (addData: EscTableEmployeeParams) => void;
    type?: 'user' | 'responsible';
}

const AddRowModal: React.FC<AddRowModal> = ({ onClose, onSave, type = 'user' }: AddRowModal) => {
    const { escTableEmployees } = useTypeSelector((state) => state.escTableEmployees);
    const { employees } = useTypeSelector((state) => state.employees);
    const [activeEmployee, setActiveEmployee] = useState<EmployeeParams>();
    const [error, setError] = useState(false);
    const [levelEscalation, setLevelEscalation] = useState(levelsArray);
    const [addData, setAddData] = useState<EscTableEmployeeParams>({
        employee_id: '',
        escalation_level: 0,
        position: '',
        name: '',
        phone: '',
        time_reaction: '–',
    });
    useEffect(() => {
        setActiveEmployee(employees[0]);
    }, [employees]);

    useEffect(() => {
        if (activeEmployee) {
            const levelEscalationArray: number[] = getEscalationLevelArray(
                escTableEmployees,
                activeEmployee?.employee_id,
            );
            if (levelEscalationArray.length) setLevelEscalation(levelEscalationArray);
        }
        activeEmployee && setAddData({ ...activeEmployee, escalation_level: 0, time_reaction: '–' });
    }, [activeEmployee, escTableEmployees]);

    const onChange = useCallback(
        (name: string) => (value: unknown) => {
            setAddData((data) => ({ ...data, [name]: value }));
        },
        [],
    );

    const saveChanges = () => {
        if (type === 'user') {
            const errorArr = Object.values(addData).filter((el) => !el);
            if (errorArr.length) setError(true);
            else {
                onSave(addData);
                setError(false);
            }
        } else {
            onSave(addData);
        }
    };

    return (
        <div
            className="modal text-center show"
            style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.75)' }}
            role="dialog"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-md modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            onClick={onClose}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="add-row-container">
                            <div className="add-row-title">Добавление пользователя</div>
                            {error && <span className="error">Все поля должны быть заполнены</span>}
                            <div className="add-row-fields">
                                {type === 'user' ? (
                                    employees.length !== 0 && (
                                        <Select
                                            optionsArr={employees.map((el) => el.name)}
                                            onChangeAmount={(val) => {
                                                const actEmpl = employees.filter((el) => el.name === val)[0];
                                                setActiveEmployee(actEmpl);
                                                setAddData({ ...actEmpl, escalation_level: 0, time_reaction: '–' });
                                            }}
                                            selectedValue={activeEmployee ? activeEmployee.name : employees[0].name}
                                            multiple={false}
                                            label="ФИО пользователя *"
                                        />
                                    )
                                ) : (
                                    <Input label="ФИО пользователя *" size={29} onChange={onChange('name')} />
                                )}
                                <Input
                                    label="Телефон"
                                    mask="+7 (999) 999-99-99"
                                    size={17}
                                    onChange={onChange('phone')}
                                    defaultValue={type === 'user' ? activeEmployee?.phone : ''}
                                />
                            </div>
                            <div className="add-row-fields">
                                <Input
                                    label="Должность"
                                    size={53}
                                    onChange={onChange('position')}
                                    defaultValue={type === 'user' ? activeEmployee?.position : ''}
                                />
                            </div>
                            {type === 'user' && (
                                <div className="add-row-fields">
                                    <div className="level-escalation">
                                        <label>Уровень эскалации</label>
                                        <div className="level-escalation-select">
                                            {levelEscalation.map((level) => (
                                                <div
                                                    key={level}
                                                    className={
                                                        'escalation-level' +
                                                        (addData.escalation_level === level ? ' active-level' : '')
                                                    }
                                                    onClick={() => onChange('escalation_level')(level)}
                                                >
                                                    {level}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {/*<Input //todo в будущем добавить возможность задать время реакции */}
                                    {/*    label="Время реакции"*/}
                                    {/*    defaultValue="30 мин"*/}
                                    {/*    size={20}*/}
                                    {/*    // onChange={() => onChange('time_reaction')}*/}
                                    {/*/>*/}
                                    <div className="reaction-time-wrapper">
                                        <label>Время реакции</label>
                                        <span>
                                            <Icon icon={'Clock'} size={14} color={'black'} />
                                            {getCorrectReactionTime(addData.escalation_level)}
                                        </span>
                                    </div>
                                </div>
                            )}
                            <div className="add-row-actions">
                                <Button label="Сохранить" onClick={() => saveChanges()} />
                                <Button label="Отменить" type="secondary" onClick={onClose} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddRowModal;
