import React, { useEffect } from 'react';
import './Exit.scss';
import Chart from 'react-apexcharts';
import { useTypeSelector } from '../../../hooks/useTypeSelector';
import { useActions } from '../../../hooks/useActions';
import { getCorrectName } from '../../../utilits/getCorrectName';
import { Calendar } from '../../../components/Calendar/Calendar';
import { dateFormatter, formatDate } from '../../../utilits/formatDate';
import { Logout } from '../../../libs/icons/index';

export const Exit = (): JSX.Element => {
    const { gates } = useTypeSelector((state) => state.gate);
    const { dateFilter } = useTypeSelector((state) => state.time);
    const { selected, structures } = useTypeSelector((state) => state.structures);

    const { postGateCountHist } = useActions();
    useEffect(() => {
        postGateCountHist(selected || structures[0].structureId, dateFilter.startDate, dateFilter.endDate);
    }, [selected, dateFilter]);
    return (
        <div className="exit">
            <div className="row match-height">
                <div className="col-lg-4 col-12">
                    <div className="card d-flex text-center card-body">
                        <div className="card-body align-items-center d-flex flex-column justify-content-center">
                            <div className="avatar logout-icon m-0">
                                <Logout size={48} color="#191C22" />
                            </div>
                            <h1 className="font-weight-bolder mt-1">
                                {gates.values ? Object.values(gates.values).reduce((r, { value }) => r + value, 0) : 0}{' '}
                                {gates.values &&
                                    getCorrectName(
                                        Object.values(gates.values).reduce((r, { value }) => r + value, 0),
                                        'человек',
                                    )}
                            </h1>
                            <p className="card-text">Входная дверь</p>
                        </div>
                        <br />
                    </div>
                </div>
                <div className="d-none d-xl-block col-lg-4" />
                <div className="d-none d-xl-block col-xl-4">
                    <div className="float-right">
                        <Calendar />
                    </div>
                </div>
            </div>
            <div className="row match-height">
                <div className="col-lg-12 col-sm-12 col-12">
                    <div className="card">
                        <div className="card-header d-flex flex-sm-row flex-column justify-content-md-between align-items-start justify-content-start">
                            <div>
                                <h4 className="card-title font-weight-bolder">Подсчёт посетителей</h4>
                            </div>
                        </div>
                        <div className="card-body">
                            <Chart
                                height={400}
                                options={{
                                    tooltip: {
                                        enabled: true,
                                        followCursor: true,
                                        y: {
                                            formatter: (value) => {
                                                return value + ' ' + getCorrectName(value, 'Человек');
                                            },
                                        },
                                    },
                                    plotOptions: {
                                        bar: {
                                            columnWidth: '55%',
                                            colors: {
                                                ranges: [
                                                    {
                                                        from: 0,
                                                        to: 10000,
                                                        color: '#1EBEAA',
                                                    },
                                                ],
                                                backgroundBarOpacity: 1,
                                                backgroundBarRadius: 0,
                                            },
                                        },
                                    },
                                    stroke: {
                                        show: false,
                                    },
                                    xaxis: {
                                        categories: gates.values
                                            ? gates.values.map(({ date }) => {
                                                  return dateFilter.endDate ? formatDate(date) : dateFormatter(date);
                                              })
                                            : [],
                                    },
                                    yaxis: {
                                        tickAmount: 1,
                                        max:
                                            Math.max.apply(
                                                null,
                                                gates.values
                                                    ? gates.values.map(({ value }) => {
                                                          return value;
                                                      })
                                                    : [0],
                                            ) + 2,
                                        title: {
                                            text: 'Человек',
                                        },
                                    },
                                }}
                                series={[
                                    {
                                        name: 'Количество',
                                        data: gates.values
                                            ? gates.values.map(({ value }) => {
                                                  return value;
                                              })
                                            : [],
                                    },
                                ]}
                                type={'bar'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
