import { videoAction, VideoActionTypes, VideoState } from '../../types/video';

const initialState: VideoState = {
    success: false,
    error: null,
    loading: false,
    videoData: [],
};

export const videoReducer = (state: VideoState = initialState, action: videoAction): VideoState => {
    switch (action.type) {
        case VideoActionTypes.GET_VIDEO: {
            return { ...state, loading: true };
        }
        case VideoActionTypes.GET_VIDEO_SUCCESS: {
            return { ...state, success: true, loading: false, videoData: [...state.videoData, action.payload] };
        }
        case VideoActionTypes.GET_VIDEO_ERROR: {
            return { ...state, success: false, loading: false, error: action.payload };
        }

        case VideoActionTypes.CLEAR_VIDEO: {
            return { ...initialState };
        }
        default:
            return state;
    }
};
