import React, { memo, useCallback, useEffect, useState } from 'react';
import { Table, TableRow } from '@bigdata/ui-kit';
import { useTypeSelector } from '../../../hooks/useTypeSelector';
import { columnPeopleInCharge } from './columns';
import createTableInChargeData from './createTableInChargeData';
import AddRowModal from './AddRowModal';
import { useActions } from '../../../hooks/useActions';
import { EmployeeParams } from '../../../types/employee';
import { PlusCircle } from 'react-feather';
import { EscTableEmployeeParams } from '../../../types/escTableEmployee';

const PeopleInChargeTable: React.FC = () => {
    const { selected } = useTypeSelector((state) => state.structures);
    const { employees } = useTypeSelector((state) => state.employees);

    const { getEmployees, postEmployees, putEmployee, deleteEmployee } = useActions();

    const [data, setData] = useState<EmployeeParams[]>([]);
    const [tableData, setTableData] = useState<TableRow[]>();
    const [editItem, setEditItem] = useState<number | undefined>(undefined);
    const [showPhone, setShowPhone] = useState<number | undefined>();
    const [showActionsBar, setShowActionsBar] = useState<number | undefined>();
    const [editRow, setEditedRow] = useState<EmployeeParams | undefined>();
    const [addRow, setAddRow] = useState<number | undefined>();
    const [deleteRow, setDeleteRow] = useState<number | undefined>();
    const [changesSaved, setChangesSaved] = useState<boolean>(false);

    const onCloseAddRow = useCallback(() => {
        setAddRow(undefined);
    }, []);

    useEffect(() => {
        selected && getEmployees(selected);
    }, [selected]);

    useEffect(() => {
        if (changesSaved && editRow) {
            putEmployee(editRow, selected);
            setChangesSaved(false);
            setEditItem(undefined);
        }
    }, [changesSaved]);

    useEffect(() => {
        employees ? setData(employees) : setData([]);
    }, [selected, employees]);

    useEffect(() => {
        if (typeof deleteRow === 'undefined') return;
        const filteredTableData = data?.filter((el, i) => i === deleteRow);
        filteredTableData[0].employee_id &&
            deleteEmployee(filteredTableData[0].employee_id, selected) &&
            setDeleteRow(undefined);
    }, [deleteRow]);

    const onSaveAddRow = useCallback(
        (addData: EscTableEmployeeParams) => {
            if (typeof addRow === 'undefined') return;
            postEmployees(addData, selected);
            setAddRow(undefined);
        },
        [addRow],
    );

    useEffect(() => {
        const closeActionsBar = () => {
            setShowActionsBar(undefined);
        };
        window.addEventListener('click', closeActionsBar);

        return () => {
            window.removeEventListener('click', closeActionsBar);
        };
    }, []);

    useEffect(() => {
        setTableData(
            createTableInChargeData({
                data,
                showActionsBar,
                setShowActionsBar,
                showPhone,
                setShowPhone,
                editItem,
                setDeleteRow,
                setEditItem,
                setAddRow,
                editRow,
                setEditedRow,
                setChangesSaved,
            }),
        );
    }, [data, showPhone, showActionsBar, editItem, selected, editRow]);

    return (
        <>
            <div className="object-responsible">
                <h3>Ответственные лица</h3>
                <div className="table-container">
                    {tableData && <Table columns={columnPeopleInCharge} data={tableData} />}
                    {!tableData?.length && (
                        <div className="action-item" onClick={() => setAddRow(0)}>
                            <PlusCircle size={16} />
                            <span className="title-item">Добавить</span>
                        </div>
                    )}
                </div>
                {typeof addRow !== 'undefined' && (
                    <AddRowModal onClose={onCloseAddRow} onSave={onSaveAddRow} type="responsible" />
                )}
            </div>
        </>
    );
};

export default memo(PeopleInChargeTable);
