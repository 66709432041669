import React from 'react';
interface Props {
    color?: string;
    size?: string;
}
const Workplace = ({ color = '#6E6B7B', size = '24' }: Props): JSX.Element => {
    return (
        <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.7988 17.5534C17.7988 15.8214 19.2029 14.4174 20.9348 14.4174C22.6668 14.4174 24.0708 15.8214 24.0708 17.5534V19.2352C24.0708 19.5002 24.0382 19.7562 23.9772 20H20.8936C19.9462 20 19.1527 20.6587 18.946 21.543C18.7127 21.3939 18.4897 21.1695 18.2961 20.8712C17.9779 20.381 17.7988 19.7762 17.7988 19.2352V17.5534ZM26.0708 19.2352C26.0708 19.4947 26.0515 19.7501 26.0141 20H33.9999C35.1045 20 35.9999 20.8954 35.9999 22V31.3402C35.9999 32.4448 35.1045 33.3402 33.9999 33.3402H27.9493V34.704H34.0763C34.6285 34.704 35.0763 35.1518 35.0763 35.704C35.0763 36.2563 34.6285 36.704 34.0763 36.704H22.1932C22.1472 36.7105 22.1004 36.7139 22.0528 36.7139H12.3311C11.7788 36.7139 11.3311 36.2661 11.3311 35.7139V32.9847C11.3311 28.5706 14.6279 24.9266 18.8936 24.3806V23.6718C17.8907 23.4257 17.1226 22.7366 16.6186 21.9602C16.098 21.1582 15.7988 20.1773 15.7988 19.2352V17.5534C15.7988 14.7168 18.0983 12.4174 20.9348 12.4174C23.7714 12.4174 26.0708 14.7168 26.0708 17.5534V19.2352ZM22.8019 33.3402H25.9493V34.704H23.074C23.0442 34.264 22.9634 33.7909 22.8019 33.3402ZM18.8936 26.4022V31.3027H15.998C15.4458 31.3027 14.998 31.7505 14.998 32.3027C14.998 32.855 15.4458 33.3027 15.998 33.3027H20.0059C20.2949 33.3027 20.4581 33.3807 20.5647 33.464C20.6841 33.5572 20.8 33.7118 20.895 33.951C20.9855 34.179 21.04 34.4415 21.0671 34.704H20.9375C20.8898 34.704 20.8428 34.7074 20.7968 34.7139H13.3311V32.9847C13.3311 29.6773 15.7366 26.9318 18.8936 26.4022ZM33.9999 22L20.8936 22V31.3402L33.9999 31.3402L33.9999 22ZM27.0415 28.4308C28.0309 28.4308 28.833 27.6287 28.833 26.6393C28.833 25.6499 28.0309 24.8478 27.0415 24.8478C26.0521 24.8478 25.25 25.6499 25.25 26.6393C25.25 27.6287 26.0521 28.4308 27.0415 28.4308Z"
                fill={color}
            />
        </svg>
    );
};

export default Workplace;
