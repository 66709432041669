import { infomatAction, InfomatActionTypes } from '../../types/infomat';
import { Dispatch } from 'react';
import axios from 'axios';
import { Url } from '../../types/url';
export const getInfomatCount = (structureID: string) => {
    return async (dispatch: Dispatch<infomatAction>): Promise<void> => {
        try {
            dispatch({
                type: InfomatActionTypes.GET_INFOMAT_COUNT,
            });

            const response = await axios.get(`${Url.Server}/api/1.0/dashboard/infomat-customer-count/${structureID}`);
            dispatch({
                type: InfomatActionTypes.GET_INFOMAT_COUNT_SUCCESS,
                payload: response.data,
            });
        } catch (e) {
            dispatch({
                type: InfomatActionTypes.GET_INFOMAT_COUNT_ERROR,
                payload: 'Произошла ошибка получения данных о зоне инфомата',
            });
        }
    };
};
