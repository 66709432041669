import axios from 'axios';

import { store } from '../store';
import { Url } from '../types/url';
import { Dispatch } from 'react';
import { UserActionTypes, usersAction } from '../types/user';

const axiosApiInstance = axios.create();

const httpClient = {
    initialize: function (): void {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;
        } else {
            httpClient.removeTokens();
            if (!window.location.pathname.includes('/login')) {
                window.location.href = '/login';
            }
        }

        axios.interceptors.response.use(
            function (response) {
                return response;
            },
            async function (error) {
                if (error.request.responseURL === Url.TokenServer) {
                    httpClient.removeTokens();
                    window.location.href = '/login';
                }

                if (401 === error.response.status) {
                    const refreshToken = localStorage.getItem('refreshToken');

                    if (refreshToken) {
                        await updateToken(store.dispatch, refreshToken);
                        return axiosApiInstance({
                            ...error.config,
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                            },
                        });
                    } else {
                        httpClient.removeTokens();
                        window.location.href = '/';
                    }
                } else {
                    return Promise.reject(error);
                }
            },
        );
    },
    setAccessToken: function (token: string): void {
        localStorage.setItem('accessToken', token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    },
    setRefreshToken: function (token: string): void {
        localStorage.setItem('refreshToken', token);
    },
    removeTokens: function (): void {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
    },
};

async function updateToken(dispatch: Dispatch<usersAction>, token: string): Promise<void> {
    try {
        const response = await axios.post(
            Url.TokenServer,
            `client_id=account&grant_type=refresh_token&refresh_token=${token}`,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            },
        );

        httpClient.setAccessToken(response?.data?.access_token);
        httpClient.setRefreshToken(response?.data?.refresh_token);

        dispatch({
            type: UserActionTypes.UPDATE_TOKEN,
            payload: response.data.access_token,
        });
    } catch (e) {
        dispatch({ type: UserActionTypes.POST_USER_TOKEN_ERROR, payload: String(e) });
    }
}

export default httpClient;
