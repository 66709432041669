import React from 'react';
import { Edit, EyeOff, MoreVertical, Trash, PlusCircle } from 'react-feather';
import { Input, Line, TableRow } from '@bigdata/ui-kit';
import { EmployeeParams } from '../../../types/employee';
import { formatPhoneNumber } from '../../../utilits/formatPhoneNumber';

interface CreateTableDataArgs {
    data: EmployeeParams[];
    showActionsBar?: number;
    setShowActionsBar: React.Dispatch<number | undefined>;
    showPhone?: number;
    setShowPhone: React.Dispatch<number | undefined>;
    editItem?: number;
    setDeleteRow: React.Dispatch<number | undefined>;
    setEditItem: React.Dispatch<number | undefined>;
    setAddRow: React.Dispatch<number | undefined>;
    setEditedRow: React.Dispatch<EmployeeParams>;
    editRow: EmployeeParams | undefined;
    setChangesSaved: React.Dispatch<boolean>;
}

function createTableInChargeData({
    data,
    showActionsBar,
    setShowActionsBar,
    showPhone,
    setShowPhone,
    editItem,
    setEditItem,
    setDeleteRow,
    setAddRow,
    setEditedRow,
    editRow,
    setChangesSaved,
}: CreateTableDataArgs): TableRow[] {
    const tableData = [];

    const changeData =
        (name: string) =>
        (value: string | number): void => {
            if (editItem !== undefined) {
                const updatedRow = editRow ? { ...editRow, [name]: value } : { ...data[editItem], [name]: value };
                setEditedRow(updatedRow);
            }
            return;
        };

    for (let i = 0; i < data.length; i++) {
        tableData.push({
            position: {
                text: data[i].position,
                render(text: string) {
                    return editItem === i ? (
                        <Input defaultValue={text} onBlur={changeData('position')} />
                    ) : (
                        <span>{text}</span>
                    );
                },
            },
            name: {
                text: data[i].name,
                render(text: string) {
                    return editItem === i ? (
                        <Input defaultValue={text} onBlur={changeData('name')} />
                    ) : (
                        <div className="phone-field">
                            <div>{text}</div>
                        </div>
                    );
                },
            },
            phone: {
                text: data[i].phone,
                render(text: string) {
                    return editItem === i ? (
                        <Input mask="+7 (999) 999-99-99" size={17} onChange={changeData('phone')} defaultValue={text} />
                    ) : (
                        <div className="phone-field">
                            {showPhone !== i ? (
                                <div className="hide-field" onClick={() => setShowPhone(i)}>
                                    <EyeOff size={12} /> ПОСМОТРЕТЬ
                                </div>
                            ) : (
                                <div>{formatPhoneNumber(text)}</div>
                            )}
                        </div>
                    );
                },
            },
            actions: {
                text: '',
                render() {
                    return (
                        <div className="actions-field">
                            <MoreVertical
                                size={18}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowActionsBar(i);
                                }}
                            />
                            {showActionsBar === i && (
                                <div className="actions-bar">
                                    <div
                                        className="action-item"
                                        onClick={() => {
                                            editItem !== undefined && setChangesSaved(true);
                                            setEditItem(i);
                                        }}
                                    >
                                        <Edit size={16} />
                                        <span className="title-item">
                                            {editItem === i ? 'Сохранить' : 'Редактировать'}
                                        </span>
                                    </div>
                                    <div className="action-item" onClick={() => setAddRow(i)}>
                                        <PlusCircle size={16} />
                                        <span className="title-item">Добавить</span>
                                    </div>
                                    <Line height={1} width={149} color={'#DFE0E5'} />
                                    <div className="action-item delete-action" onClick={() => setDeleteRow(i)}>
                                        <Trash size={16} />
                                        <span className="title-item">УДАЛИТЬ</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                },
            },
        });
    }
    return tableData;
}

export default createTableInChargeData;
