import React, { useCallback } from 'react';
import axios from 'axios';
import { Button } from '@bigdata/ui-kit';
import { useTypeSelector } from '../../../../hooks/useTypeSelector';

export interface UploadReportProps {
    type: 'consolidated-report' | 'event-report';
    title: string;
}
const UploadReport = ({ type, title }: UploadReportProps): JSX.Element => {
    const { dateFilter } = useTypeSelector((state) => state.time);
    const { startDate, endDate } = dateFilter;

    const downloadReport = useCallback(() => {
        const start = new Date(startDate).getTime();
        const end = endDate ? new Date(endDate).getTime() : start;
        axios.post(`/api/1.0/reports/${type}`, { start, end }, { responseType: 'blob' }).then((response) => {
            const href = URL.createObjectURL(response.data);

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'report.xlsx');
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
        });
    }, [dateFilter]);

    return (
        <div className="card">
            <div className="upload-report">
                <div className="upload-report-title text-grey80">{title}</div>
                <div className="download-report">
                    <Button label="Cкачать отчёт Excel" onClick={downloadReport} />
                </div>
            </div>
        </div>
    );
};

export default UploadReport;
