import { useEffect, useState } from 'react';
import { useTypeSelector } from './useTypeSelector';

interface Props {
    path: string;
    structureId?: string;
}

export const useCommentsRetriever = ({ path, structureId }: Props): string => {
    const { messages } = useTypeSelector((state) => state.websocketsConnect);
    const [messageTime, setMessageTime] = useState(new Date().toString());

    useEffect(() => {
        if (structureId === undefined)
            messages[messages.length - 1]?.request === path && setMessageTime(new Date().toString());
        else
            messages[messages.length - 1]?.request === path &&
                messages[messages.length - 1]?.structureId === structureId &&
                setMessageTime(new Date().toString());
    }, [messages]);
    return messageTime;
};
