export interface StructuresStatusesParams {
    structureId: string;
    countEventLastHour: number;
}

export interface StructuresStatusesState {
    statuses: StructuresStatusesParams[];
    loading: boolean;
    error: null | string;
    selected: string;
}

export enum GetStructuresStatusesActionTypes {
    GET_STRUCTURES_STATUSES = 'GET_STRUCTURES_STATUSES',
    GET_STRUCTURES_STATUSES_SUCCESS = 'GET_STRUCTURES_STATUSES_SUCCESS',
    GET_STRUCTURES_STATUSES_ERROR = 'GET_STRUCTURES_STATUSES_ERROR',
}

interface GetGetStructuresStatusesAction {
    type: GetStructuresStatusesActionTypes.GET_STRUCTURES_STATUSES;
}

interface GetStructuresStatusesSuccessAction {
    type: GetStructuresStatusesActionTypes.GET_STRUCTURES_STATUSES_SUCCESS;
    payload: [];
}

interface GetStructuresStatusesErrorAction {
    type: GetStructuresStatusesActionTypes.GET_STRUCTURES_STATUSES_ERROR;
    payload: string;
}

export type structuresStatusesAction =
    | GetGetStructuresStatusesAction
    | GetStructuresStatusesSuccessAction
    | GetStructuresStatusesErrorAction;
