import {
    EscTableEmployeeAction,
    EscTableEmployeeParams,
    EscTableEmployeeResponseParams,
    EscTableEmployeesActionTypes,
} from '../../types/escTableEmployee';
import { Dispatch } from 'react';
import axios from 'axios';
import { Url } from '../../types/url';
import { getCorrectReactionTime } from '../../utilits/getCorrectReactionTime';

export const getEscTableEmployees = (structureId: string) => {
    return async (dispatch: Dispatch<EscTableEmployeeAction>): Promise<void> => {
        try {
            dispatch({
                type: EscTableEmployeesActionTypes.GET_ET_EMPLOYEES,
            });

            const response = await axios.get(`${Url.Server}/api/1.0/structure/${structureId}/escalation-table`);
            const employees = response.data.map((el: EscTableEmployeeResponseParams) => {
                return {
                    employee_id: el.employee.employeeId,
                    name: el.employee.name,
                    phone: el.employee.phoneNumber,
                    position: el.employee.function,
                    escalation_level: el.level || 0,
                    row_id: el.id,
                    time_reaction: getCorrectReactionTime(el.level || 0),
                };
            });
            dispatch({
                type: EscTableEmployeesActionTypes.GET_ET_EMPLOYEES_SUCCESS,
                payload: employees,
            });
        } catch (e) {
            dispatch({
                type: EscTableEmployeesActionTypes.GET_ET_EMPLOYEES_ERROR,
                payload: 'Произошла ошибка получения сотрудников',
            });
        }
    };
};

export const postEscTableEmployees = (employees: EscTableEmployeeParams[], structureId: string) => {
    return async (dispatch: Dispatch<EscTableEmployeeAction>): Promise<void> => {
        try {
            dispatch({
                type: EscTableEmployeesActionTypes.POST_ET_EMPLOYEES,
            });
            const data = employees.map((el) => {
                return {
                    employeeId: el.employee_id,
                    level: el.escalation_level,
                };
            });
            await axios.post(`${Url.Server}/api/1.0/structure/${structureId}/escalation-table `, data);
            dispatch({
                type: EscTableEmployeesActionTypes.POST_ET_EMPLOYEES_SUCCESS,
                payload: employees,
            });
        } catch (e) {
            dispatch({
                type: EscTableEmployeesActionTypes.POST_ET_EMPLOYEES_ERROR,
                payload: 'Произошла ошибка создания нового ответственного сотрудника',
            });
        }
    };
};
