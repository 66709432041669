export interface VideoParams {
    name: string;
    result: {
        url: string;
    };
}

export interface VideoState {
    videoData: VideoParams[];
    success: boolean;
    loading: boolean;
    error: null | string;
}

export enum VideoActionTypes {
    GET_VIDEO = 'GET_VIDEO',
    GET_VIDEO_SUCCESS = 'GET_VIDEO_SUCCESS',
    GET_VIDEO_ERROR = 'GET_VIDEO_ERROR',
    CLEAR_VIDEO = 'CLEAR_VIDEO',
}

interface GetVideoCountAction {
    type: VideoActionTypes.GET_VIDEO;
}

interface GetVideoCountSuccessAction {
    type: VideoActionTypes.GET_VIDEO_SUCCESS;
    payload: VideoParams;
}

interface ClearVideoAction {
    type: VideoActionTypes.CLEAR_VIDEO;
}

interface GetVideoCountErrorAction {
    type: VideoActionTypes.GET_VIDEO_ERROR;
    payload: string;
}
export type videoAction =
    | GetVideoCountAction
    | GetVideoCountSuccessAction
    | GetVideoCountErrorAction
    | ClearVideoAction;
