import { WorkplaceActionTypes, workplaceAction } from '../../types/workplace';
import { Dispatch } from 'react';
import axios from 'axios';
import { Url } from '../../types/url';
import { dateStringToTimestamp } from '../../utilits/formatDate';
export const getWorkplaceCount = (structureID: string) => {
    return async (dispatch: Dispatch<workplaceAction>): Promise<void> => {
        try {
            dispatch({
                type: WorkplaceActionTypes.GET_WORKPLACE_COUNT,
            });

            const response = await axios.get(`${Url.Server}/api/1.0/dashboard/workplace-count-employee/${structureID}`);
            dispatch({
                type: WorkplaceActionTypes.GET_WORKPLACE_COUNT_SUCCESS,
                payload: response.data,
            });
        } catch (e) {
            dispatch({
                type: WorkplaceActionTypes.GET_WORKPLACE_COUNT_ERROR,
                payload: 'Произошла ошибка получения данных сотрудников',
            });
        }
    };
};

export const postWorkplaceHist = (structureID: string, startDate: string, endDate?: string) => {
    return async (dispatch: Dispatch<workplaceAction>): Promise<void> => {
        try {
            dispatch({
                type: WorkplaceActionTypes.POST_WORKPLACE_HIST,
            });

            const response = await axios.post(`${Url.Server}/api/1.0/dashboard/workplace-time-general/${structureID}`, {
                startDate: dateStringToTimestamp(startDate),
                endDate: endDate ? dateStringToTimestamp(endDate) : endDate,
            });
            dispatch({
                type: WorkplaceActionTypes.POST_WORKPLACE_HIST_SUCCESS,
                payload: response.data,
            });
        } catch (e) {
            dispatch({
                type: WorkplaceActionTypes.POST_WORKPLACE_HIST_ERROR,
                payload: 'Произошла ошибка получения данных сотрудников',
            });
        }
    };
};

export const getWorkplaceNobody = (structureID: string) => {
    return async (dispatch: Dispatch<workplaceAction>): Promise<void> => {
        try {
            dispatch({
                type: WorkplaceActionTypes.GET_WORKPLACE_COUNT,
            });

            const response = await axios.get(`${Url.Server}/api/1.0/dashboard/workplace-time-nobody/${structureID}`);
            dispatch({
                type: WorkplaceActionTypes.GET_WORKPLACE_NOBODY_SUCCESS,
                payload: response.data,
            });
        } catch (e) {
            dispatch({
                type: WorkplaceActionTypes.GET_WORKPLACE_NOBODY_ERROR,
                payload: 'Произошла ошибка получения данных сотрудников',
            });
        }
    };
};

export const postWorkplaceNobodyHist = (structureID: string | undefined, startDate: string, endDate: null | string) => {
    return async (dispatch: Dispatch<workplaceAction>): Promise<void> => {
        try {
            dispatch({
                type: WorkplaceActionTypes.POST_WORKPLACE_NOBODY_HIST,
            });

            const response = await axios.post(`${Url.Server}/api/1.0/dashboard/workplace-time-nobody/${structureID}`, {
                startDate: dateStringToTimestamp(startDate),
                endDate: endDate ? dateStringToTimestamp(endDate) : null,
            });
            dispatch({
                type: WorkplaceActionTypes.POST_WORKPLACE_NOBODY_HIST_SUCCESS,
                payload: response.data,
            });
        } catch (e) {
            dispatch({
                type: WorkplaceActionTypes.POST_WORKPLACE_NOBODY_HIST_ERROR,
                payload: 'Произошла ошибка получения данных сотрудников',
            });
        }
    };
};
