import { EscTableEmployeeParams } from '../types/escTableEmployee';

export const levelsArray = [1, 2, 3, 4]; // уровни эскалации

export const getEscalationLevelArray = (
    escTableEmployees: EscTableEmployeeParams[],
    activeEmployeeId: string,
): number[] => {
    const levelEscalationArray: number[] = [];
    // проверка есть ли у данного сотрудника записи с уровнями эскалации, если такие есть, убираем соответствующие уровни, чтобы они не повторялись
    // находим записи в таблице эскалации с id данного сотрудника
    const activeEmployeeRows = escTableEmployees.filter(
        (el: EscTableEmployeeParams) => el.employee_id === activeEmployeeId,
    );
    if (activeEmployeeRows.length) {
        levelsArray.forEach((numb) => {
            if (!activeEmployeeRows.find((item: EscTableEmployeeParams) => item.escalation_level === numb))
                levelEscalationArray.push(numb);
        });
    }
    return levelEscalationArray;
};
