export interface EventParams {
    event: {
        name: string;
        description: string;
        grade: string;
    };
    zone: {
        name: string;
        description: string;
    };
    datetime: string;
}

export interface EventsParams {
    structureId: string;
    datetime: string;
    count: number;
    events: EventParams[];
}

export interface EventsState {
    events: EventsParams;
    loading: boolean;
    page?: number;
    limit?: number;
    error: null | string;
}

export enum EventsActionTypes {
    GET_EVENTS = 'GET_EVENTS',
    GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS',
    GET_EVENTS_ERROR = 'GET_EVENTS_ERROR',

    POST_EVENTS = 'POST_EVENTS',
    POST_EVENTS_SUCCESS = 'POST_EVENTS_SUCCESS',
    POST_EVENTS_ERROR = 'POST_EVENTS_ERROR',

    SET_EVENTS_LIMIT = 'SET_EVENTS_LIMIT',
    SET_EVENTS_PAGE = 'SET_EVENTS_PAGE',
}

interface GetEventsAction {
    type: EventsActionTypes.GET_EVENTS;
}

interface GetEventsSuccessAction {
    type: EventsActionTypes.GET_EVENTS_SUCCESS;
    payload: EventsParams;
}

interface GetEventsErrorAction {
    type: EventsActionTypes.GET_EVENTS_ERROR;
    payload: string;
}

interface PostEventsAction {
    type: EventsActionTypes.POST_EVENTS;
}

interface PostEventsSuccessAction {
    type: EventsActionTypes.POST_EVENTS_SUCCESS;
    payload: EventsParams;
}

interface PostEventsErrorAction {
    type: EventsActionTypes.POST_EVENTS_ERROR;
    payload: string;
}

interface SetEventsLimitAction {
    type: EventsActionTypes.SET_EVENTS_LIMIT;
    payload: number;
}
interface SetEventsPageAction {
    type: EventsActionTypes.SET_EVENTS_PAGE;
    payload: number;
}

export type eventsAction =
    | GetEventsAction
    | GetEventsSuccessAction
    | GetEventsErrorAction
    | PostEventsAction
    | PostEventsSuccessAction
    | PostEventsErrorAction
    | SetEventsLimitAction
    | SetEventsPageAction;
