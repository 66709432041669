export interface AuthState {
    success: boolean;
    loading: boolean;
    domain: string;
}

export enum AuthActionTypes {
    AUTH = 'AUTH',
    AUTH_SUCCESS = 'AUTH_SUCCESS',
    AUTH_ERROR = 'AUTH_ERROR',
}

interface AuthAction {
    type: AuthActionTypes.AUTH;
}

interface AuthSuccessAction {
    type: AuthActionTypes.AUTH_SUCCESS;
    payload: string;
}

interface AuthErrorAction {
    type: AuthActionTypes.AUTH_ERROR;
    payload: string;
}

export type authAction = AuthAction | AuthSuccessAction | AuthErrorAction;
