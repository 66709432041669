import React from 'react';
import './ObjectCard.scss';
import EscalationTable from './EscalationTable';
import ObjectHeader from './ObjectHeader';

const ObjectCard: React.FC = () => {
    return (
        <div className="object-card-wrapper">
            <div className="header-table-container">
                <ObjectHeader />
            </div>
            <EscalationTable />
        </div>
    );
};

export default ObjectCard;
