import React from 'react';
interface Props {
    color?: string;
    size?: string;
}
const Camera = ({ color = '#6E6B7B', size = '24' }: Props): JSX.Element => {
    return (
        <svg
            style={{ fontSize: size }}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox={`0 0 24 24`}
        >
            <path
                fill={color}
                fillRule="evenodd"
                d="M4.343 4.792a2 2 0 012.653-.876l14.886 7.27a3.979 3.979 0 01-.92.6c-.32.14-.531.149-.666.114l-6.204-3.118a1 1 0 10-.898 1.787l3.033 1.524-2.022 3.816-7.767-3.83a2.816 2.816 0 01-1.52-3.055l2.352 1.148a1 1 0 10.877-1.797L3.63 6.169l.714-1.377zm-1.26 3.336l-1.252-.611a1 1 0 01-.45-1.36l1.187-2.286a4 4 0 015.306-1.752l15.013 7.333c1.086.53 1.591 2.095.494 3.081-.442.398-1 .815-1.618 1.085-.115.05-.235.097-.36.137a1 1 0 01-.111.636L19.4 17.83a1 1 0 01-1.332.408l-2.229-1.14-.322.607a1 1 0 01-1.326.429l-3.42-1.687-1.55 2.052a2.689 2.689 0 01-4.497 2.849H1.006a1 1 0 110-2H4.09a2.69 2.69 0 013.678-2.245l1.176-1.556-3.392-1.673a4.816 4.816 0 01-2.47-5.745zm13.693 7.201l.886-1.673 1.375.684-.921 1.675-1.34-.686zM6.768 18.91a.689.689 0 100 1.378.689.689 0 000-1.378z"
                clipRule="evenodd"
            />
        </svg>
    );
};

export default Camera;
