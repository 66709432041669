import Chart from 'react-apexcharts';
import React, { useEffect } from 'react';
import { useTypeSelector } from '../../../../hooks/useTypeSelector';
import { useActions } from '../../../../hooks/useActions';

export const WorkplaceAttendanceTotal = (): JSX.Element => {
    const { workplaceNobody } = useTypeSelector((state) => state.workplace);
    const { dateFilter } = useTypeSelector((state) => state.time);
    const { selected, structures } = useTypeSelector((state) => state.structures);

    const { postWorkplaceNobodyHist } = useActions();
    useEffect(() => {
        postWorkplaceNobodyHist(selected || structures[0].structureId, dateFilter.startDate, dateFilter.endDate);
    }, [selected, dateFilter]);

    return (
        <div className="card" style={{ height: 380, width: 540 }}>
            <div className="card-header flex-column align-items-start mb-50 pb-50">
                <h4 className="card-title">Регистраторы за рабочим местом</h4>
            </div>
            <div className="card-body">
                <Chart
                    options={{
                        chart: {
                            type: 'donut',
                            width: 400,
                        },
                        legend: {
                            position: 'right',
                            itemMargin: {
                                horizontal: 5,
                                vertical: 8,
                            },
                            width: 145,
                            offsetY: 200,
                        },
                        yaxis: {
                            floating: false,
                        },
                        labels: [0, null].includes(workplaceNobody.nobodyTime)
                            ? ['Данных нет']
                            : ['Присутствовали', 'Отсутствовали'],
                        colors: [0, null].includes(workplaceNobody.nobodyTime) ? ['#787878'] : ['#1EBEAA', '#F06767'],

                        dataLabels: {
                            dropShadow: {
                                enabled: false,
                            },
                        },
                        plotOptions: {
                            pie: {
                                donut: {
                                    size: '60px',
                                },
                            },
                        },
                    }}
                    series={
                        workplaceNobody.nobodyTime === 0 || workplaceNobody.nobodyTime === null
                            ? [1]
                            : [Math.round(100 - workplaceNobody.nobodyTime), Math.round(workplaceNobody.nobodyTime)]
                    }
                    type="donut"
                    width={500}
                    height={350}
                />
            </div>
        </div>
    );
};
