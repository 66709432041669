import React, { useEffect, useState } from 'react';
import { Clock, MapPin } from 'react-feather';
import { useTypeSelector } from '../../../hooks/useTypeSelector';
import PeopleInChargeTable from './PeopleInChargeTable';
import { ActiveStructureState } from '../../../types/structures';
import axios from 'axios';
import { Url } from '../../../types/url';

const getStructure = async (selected: string) => await axios.get(`${Url.Server}/api/1.0/structure/${selected}/card `);

const ObjectHeader: React.FC = () => {
    const [activeStructure, setActiveStructure] = useState<ActiveStructureState | null>();
    const { selected } = useTypeSelector((state) => state.structures);

    useEffect(() => {
        const fetchData = async () => {
            const response = await getStructure(selected);
            response && setActiveStructure(response.data);
        };
        fetchData();
    }, [selected]);

    return activeStructure ? (
        <div className="object-header">
            <div className="object-info">
                <h3>{activeStructure?.name}</h3>
                <div className="horizontal-line" />
                <div className="object-subtitle">
                    {/*<span> //todo с бэка не приходит ОГРН*/}
                    {/*    <MinusSquare size={18} />*/}
                    {/*    /!*{activeStructure?.ogrn}*!/*/}
                    {/*</span>*/}
                    <span>
                        <Clock size={18} />с {activeStructure?.openTime} до {activeStructure?.closeTime}
                    </span>
                    <span>
                        <MapPin size={18} />
                        {activeStructure?.address}
                    </span>
                </div>
            </div>
            <PeopleInChargeTable />
        </div>
    ) : (
        <></>
    );
};

export default ObjectHeader;
