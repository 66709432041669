import React, { useCallback, useState } from 'react';
import './Dashboard.scss';
import { StructureBanner } from './components/StructureBanner';
import { ServiceCount } from './components/ServiceCount';
import { WorkplaceCount } from './components/WorkplaceCount';
import { InfomatCount } from './components/InfomatCount';
import { GateCount } from './components/GateCount';
import { ServiceAvg } from './components/ServiceAvg';
import { WorkplaceNobody } from './components/WorkplaceNobody';
import { Events } from './components/Events';
import { Incidents } from './components/Incidents';
import { Cameras } from './components/Cameras';
import { VideoModal } from '../../../components/VideoPlayer/StreamPlayer';
import { useTypeSelector } from '../../../hooks/useTypeSelector';

export const Dashboard = (): JSX.Element => {
    const [camerasArea, setCamerasArea] = useState<string>('');
    const { structures, selected } = useTypeSelector((state) => state.structures);
    const structure = structures.find(({ structureId }) => structureId === selected);

    const closeVideoModal = useCallback(() => {
        setCamerasArea('');
    }, []);

    return (
        <div className="dashboard">
            <div className="row match-height">
                <div className="col-md-6 col-12">
                    <StructureBanner />
                </div>
                <div className="col-md-3 col-6">
                    <Cameras />
                </div>
                <div className="col-md-3 col-6">
                    <Incidents />
                </div>
            </div>
            <div className="row match-height">
                <div className="col-lg-3 col-sm-6 col-12">
                    <ServiceCount
                        openCameras={() => setCamerasArea('receptions')}
                        camerasCount={structure?.cameras.receptions?.length || 0}
                    />
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                    <WorkplaceCount
                        openCameras={() => setCamerasArea('workplaces')}
                        camerasCount={structure?.cameras.workplaces?.length || 0}
                    />
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                    <InfomatCount
                        openCameras={() => setCamerasArea('infomats')}
                        camerasCount={structure?.cameras.infomats?.length || 0}
                    />
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                    <GateCount
                        openCameras={() => setCamerasArea('gates')}
                        camerasCount={structure?.cameras.gates?.length || 0}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-8 col-12">
                    <ServiceAvg />
                </div>
                <div className="col-md-4 col-12">
                    <WorkplaceNobody />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <Events />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {camerasArea && structure?.cameras && (
                <VideoModal area={camerasArea} cameras={structure.cameras} close={closeVideoModal} />
            )}
        </div>
    );
};
