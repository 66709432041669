import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { formatDate } from '../../../../utilits/formatDate';
import { useTypeSelector } from '../../../../hooks/useTypeSelector';
import { useActions } from '../../../../hooks/useActions';
import { Chart, EChartType } from '../../../../components/Chart';
import { ServiceByCustomerParams } from '../../../../types/service';
import { checkboxData, getTooltip } from './helpers';
import { clone } from '../../../../utilits/common';

const chartOptionsData = {
    xaxis: {
        type: 'datetime' as const,
        labels: {
            datetimeUTC: true,
        },
    },
    tooltip: {
        enabled: true,
        followCursor: true,
        custom: function (opts: Record<string, number>) {
            return getTooltip(opts.y1, opts.y2);
        },
    },
    plotOptions: {
        bar: {
            horizontal: true,
            barHeight: '80%',
            rangeBarGroupRows: true,
        },
    },
    legend: {
        markers: {
            strokeColor: '#BEBEC9',
            strokeWidth: 0.9,
        },
        showForSingleSeries: true,
        showForNullSeries: true,
        showForZeroSeries: true,
    },
    colors: ['#D9D9D9', '#1EBEAA', '#FFC800', '#F06767'],
    fill: {
        type: 'solid',
    },
};

const ServiceTimeByCustomer = ({ date }: { date: string }): JSX.Element => {
    const { service } = useTypeSelector((state) => state.serviceByCustomer);
    const { selected, structures } = useTypeSelector((state) => state.structures);
    const [series, setSeries] = useState<Array<any>>([]);
    const [exampleSeries, setExampleSeries] = useState<Array<any>>([]);
    const [chartOptions, setChartOptions] = useState(chartOptionsData);
    const { postServiceByCustomer } = useActions();
    const [buttonStatus, setButtonStatus] = useState<Record<string, boolean>>({
        employeeOff: false,
    });

    useEffect(() => {
        if (service.structureId) {
            const _series = dataFormatter(service);
            setSeries(_series);
            setExampleSeries(_series);
            setChartOptions(chartOptionsData);
        }
    }, [service]);

    useEffect(() => {
        postServiceByCustomer(selected || structures[0].structureId, date);
    }, [selected, date]);

    useEffect(() => {
        if (series.length) {
            filterSeries('Сотрудник отсутствует');
        }
    }, [exampleSeries]);

    const getLabel = (timeInterval: number) => {
        if (timeInterval <= 4) return '1 - 4 минут';
        else if (timeInterval <= 9) return '5 - 9 минут';
        else if (timeInterval > 10) return '10 и более минут';
    };

    const isEmptyData = useMemo(() => {
        return (
            !service.serviceZones ||
            !service.serviceZones?.length ||
            service.serviceZones.every((i) => !i.customerIntervals.length)
        );
    }, [service]);

    const filterSeries = useCallback(
        (name: string) => {
            let result = clone(series); // todo подумать как можно сделать оптимальнее
            let colors = chartOptions.colors;
            if (series.length) {
                const index = series[0].name === name ? 0 : -1;
                // элемент найден, необходимо удалить элемент и обновить массив цветов
                if (index !== -1) {
                    result = series.filter((el, i) => i !== index);
                    colors = colors.filter((el, i) => i !== index);
                    // элемент НЕ найден, необходимо добавить элемент и обновить массив цветов
                } else {
                    const element = exampleSeries.find((el) => el.name === name);
                    const elIndex = 0;
                    result.splice(elIndex, 0, element);
                    colors.splice(elIndex, 0, '#D9D9D9');
                    setSeries(result);
                }
                setSeries(result);
                setChartOptions({ ...chartOptions, colors: colors });
            }
        },
        [series, chartOptions],
    );

    const dataFormatter = (data: ServiceByCustomerParams) => {
        const employeeMissing: { x: string; y: number[] }[] = [];
        const fullResults = data.serviceZones
            .map(({ zone, customerIntervals, employeeIntervals }) => {
                employeeIntervals.forEach(({ start, end }) => {
                    employeeMissing.push({
                        x: zone.name,
                        y: [new Date(start).getTime(), new Date(end).getTime()],
                    });
                });
                return customerIntervals.map(({ start, end }) => ({
                    name: getLabel(Math.ceil((new Date(end).getTime() - new Date(start).getTime()) / 60000)),
                    data: {
                        x: zone.name,
                        y: [new Date(start).getTime(), new Date(end).getTime()],
                    },
                }));
            })
            .flat();

        return [
            {
                name: 'Сотрудник отсутствует',
                data: employeeMissing,
            },
            {
                name: '1 - 4 минут',
                data: fullResults
                    .filter(({ name }) => name === '1 - 4 минут')
                    .map(({ data }) => {
                        return data;
                    }),
            },
            {
                name: '5 - 9 минут',
                data: fullResults
                    .filter(({ name }) => name === '5 - 9 минут')
                    .map(({ data }) => {
                        return data;
                    }),
                options: {
                    chart: {
                        bar: {
                            height: '100%',
                        },
                    },
                },
            },
            {
                name: '10 и более минут',
                data: fullResults
                    .filter(({ name }) => name === '10 и более минут')
                    .map(({ data }) => {
                        return data;
                    }),
            },
        ];
    };

    return (
        <div className="card">
            <div className="card-header d-flex flex-sm-row flex-column justify-content-md-between align-items-start justify-content-start">
                <div>
                    <h4 className="card-title font-weight-bolder">
                        Время проведенное у окна регистратуры по каждому посетителю
                    </h4>
                    <p className="card-subtitle text-muted mt-25 mb-25">
                        {isEmptyData ? 'Данные отсутствуют' : `Данные за ${formatDate(date)}`}
                    </p>
                </div>
            </div>
            {!isEmptyData && (
                <div id="chart-time-by-customer" className="card-body">
                    <Chart
                        chartOptions={chartOptions}
                        series={series}
                        chartSettings={{
                            type: EChartType.RangeBar,
                        }}
                        height={400}
                    />
                    <div className="checkboxes-wrapper">
                        {checkboxData.map((input) => (
                            <div
                                key={input.name}
                                onClick={() => {
                                    filterSeries(input.text);
                                    setButtonStatus({
                                        ...buttonStatus,
                                        [input.name]: !buttonStatus[input.name],
                                    });
                                }}
                                style={buttonStatus[input.name] ? {} : { opacity: '50%' }}
                                className={'checkbox-element'}
                            >
                                <div className="checkbox" id={input.name} />
                                <span>- {input.text}</span>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ServiceTimeByCustomer;
