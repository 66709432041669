import BX24 from './bx24';
import ICall from './entities/call';
declare global {
    interface Window {
        BX24: BX24;
    }
}

export const getAuthDomain = (): Promise<string> => {
    return new Promise((resolve) => {
        window.BX24.init(() => {
            const { domain } = window.BX24.getAuth();
            resolve(domain || '');
        });
    });
};

export const fitWindow = (callback?: () => void): void => {
    return window.BX24.fitWindow(callback);
};

export const resizeWindow = (width: number, height: number, callback?: () => void): void => {
    return window.BX24.resizeWindow(width, height, callback);
};

export const callMethod = (methodName: string, methodObject: ICall): void => {
    return window.BX24.callMethod(methodName, methodObject);
};

export const openMessenger = (dialogId: number): void => {
    return window.BX24.im.openMessenger(dialogId);
};

export const callTo = (userID: number, video: boolean): void => {
    return window.BX24.im.callTo(userID, video);
};

export const openPath = (path: string, callback?: (result: string, errorCode?: string) => void): void => {
    return window.BX24.openPath(path, callback);
};
export const openUserCard = (userID: string, callback?: (result: string, errorCode?: string) => void): void => {
    const fullPath = `/company/personal/user/${userID}/`;
    return openPath(fullPath, callback);
};
