import React, { useCallback, useEffect, useState } from 'react';
import './Menu.scss';
import { Link } from 'react-router-dom';
import { LogOut, Map } from 'react-feather';
import { useTypeSelector } from '../../hooks/useTypeSelector';
import { useActions } from '../../hooks/useActions';
import { MenuTab } from '../../types/menu';
import httpClient from '../../utilits/httpClient';

interface Params {
    menuUrl: string;
}

export const Menu = ({ menuUrl }: Params): JSX.Element => {
    const { structures, selected, loading, error } = useTypeSelector((state) => state.structures);
    const { setSelectedStructure } = useActions();
    const { data } = useTypeSelector((state) => state.user);

    const menuItems = [
        {
            name: 'Вернуться к карте',
            url: MenuTab.Map,
            icon: <Map />,
            clearStructure: true,
            className: 'map-menu-item',
        },
        {
            name: 'Общая',
            url: MenuTab.Dashboard,
            clearStructure: false,
        },
        {
            name: 'Инфостойка',
            url: MenuTab.Registration,
            clearStructure: false,
        },
        // {
        //     name: 'Инфомат',
        //     url: MenuTab.InfoMat,
        //     clearStructure: false,
        // },
        {
            name: 'Входная дверь',
            url: MenuTab.Exit,
            clearStructure: false,
        },
    ];

    const [_menuItems, setMenuItems] = useState(menuItems);

    const logout = useCallback(() => {
        httpClient.removeTokens();
        window.location.href = '/login';
    }, []);

    useEffect(() => {
        const menuItemsArr = [...menuItems];
        if (data.roles.filter((el) => el === 'administrator').length > 0) {
            menuItemsArr.push({
                name: 'Администрирование',
                url: MenuTab.Admin,
                clearStructure: false,
            });
        }
        setMenuItems(menuItemsArr);
    }, [data.roles]);

    return (
        <div className="horizontal-menu-wrapper">
            <div
                className="header-navbar navbar-expand-sm navbar navbar-horizontal navbar-light navbar-shadow menu-border"
                role="navigation"
            >
                <div className="shadow-bottom" />
                <div className="navbar-container main-menu-content menu-detail" data-menu="menu-container">
                    <ul className="nav navbar-nav" id="main-menu-navigation" data-menu="menu-navigation">
                        {_menuItems.map(({ url, name, clearStructure, icon, className }, key) => {
                            return (
                                <li
                                    key={key}
                                    className={
                                        'nav-item-beeline ' +
                                        (!name ? ' only-icon' : '') +
                                        (url === menuUrl ? ' active' : '') +
                                        ` ${className}`
                                    }
                                >
                                    <Link
                                        to={url}
                                        onClick={() => {
                                            if (clearStructure) setSelectedStructure('');
                                        }}
                                        className="nav-link d-flex align-items-center"
                                    >
                                        {icon}
                                        <span className="menu-item-text">{name}</span>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                    <div className="right-bar">
                        <div className="right-select">
                            {!loading && !error && (
                                <select
                                    onChange={(e) => setSelectedStructure(e.target.value)}
                                    value={selected}
                                    className="form-control select-clinic"
                                >
                                    {structures.map((data, key) => {
                                        return (
                                            <option value={data.structureId} key={key}>
                                                {data.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            )}
                        </div>
                        <div className="logout text-center">
                            <a
                                onClick={logout}
                                className="btn btn-icon btn-logout rounded-circle waves-effect waves-float waves-light btn-heading"
                            >
                                <LogOut color="#6E6B7B" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
