import {
    EscTableEmployeesState,
    EscTableEmployeesActionTypes,
    EscTableEmployeeAction,
} from '../../types/escTableEmployee';

const initialState: EscTableEmployeesState = {
    escTableEmployees: [],
    loading: false,
    error: null,
};

export const escTableEmployeeReducer = (
    state: EscTableEmployeesState = initialState,
    action: EscTableEmployeeAction,
): EscTableEmployeesState => {
    switch (action.type) {
        case EscTableEmployeesActionTypes.GET_ET_EMPLOYEES: {
            return { ...state, loading: true };
        }
        case EscTableEmployeesActionTypes.GET_ET_EMPLOYEES_SUCCESS: {
            return { ...state, loading: false, escTableEmployees: action.payload };
        }
        case EscTableEmployeesActionTypes.GET_ET_EMPLOYEES_ERROR: {
            return { ...state, loading: false, error: action.payload };
        }
        case EscTableEmployeesActionTypes.POST_ET_EMPLOYEES_SUCCESS: {
            return { ...state, loading: false, escTableEmployees: action.payload };
        }
        case EscTableEmployeesActionTypes.POST_ET_EMPLOYEES_SUCCESS: {
            return { ...state, loading: false };
        }
        case EscTableEmployeesActionTypes.POST_ET_EMPLOYEES_ERROR: {
            return { ...state, loading: false, error: action.payload };
        }
        default:
            return state;
    }
};
