import React from 'react';
interface Props {
    color?: string;
    size?: string;
}

const Users = ({ color = '#6E6B7B', size = '24' }: Props): JSX.Element => (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.0159 19C16.0159 16.2 18.2247 14 21.0359 14C23.847 14 26.0558 16.2 26.0558 19C26.0558 21.8 23.847 24 21.0359 24C18.2247 24 16.0159 21.8 16.0159 19ZM30.0718 31V33C30.0718 33.6 29.6702 34 29.0678 34C28.4654 34 28.0638 33.6 28.0638 33V31C28.0638 29.3 26.7586 28 25.0518 28H17.0199C15.3132 28 14.008 29.3 14.008 31V33C14.008 33.6 13.6064 34 13.004 34C12.4016 34 12 33.6 12 33V31C12 28.2 14.2088 26 17.0199 26H25.0518C27.863 26 30.0718 28.2 30.0718 31ZM21.0359 22C19.3291 22 18.0239 20.7 18.0239 19C18.0239 17.3 19.3291 16 21.0359 16C22.7427 16 24.0478 17.3 24.0478 19C24.0478 20.7 22.7427 22 21.0359 22ZM32.2805 26.2C31.7785 26.1 31.1761 26.4 31.0757 26.9C30.9753 27.4 31.2765 28 31.7785 28.1C33.0837 28.4 33.9873 29.6 33.9873 31V33C33.9873 33.6 34.3889 34 34.9913 34C35.5937 34 35.9953 33.6 35.9953 33V31C36.0957 28.7 34.5897 26.7 32.2805 26.2ZM27.0598 14.9C27.2606 14.3 27.7626 14 28.2646 14.2C30.9753 14.8 32.5817 17.6 31.8789 20.4C31.4773 22.2 30.0718 23.5 28.2646 24H28.0638C27.5618 24 27.1602 23.7 27.0598 23.2C26.859 22.7 27.2606 22.1 27.7626 22C28.867 21.7 29.6702 20.9 29.9714 19.8C30.3729 18.1 29.369 16.5 27.7626 16.1C27.2606 16 26.9594 15.4 27.0598 14.9Z"
            fill={color}
        />
    </svg>
);

export default Users;
