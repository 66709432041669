import React, { useEffect, useRef, useState } from 'react';
import flvjs from 'flv.js';
import { Tabs, Tab } from '@beeline/design-system-react';
import tabs from './cameras';
import './StreamPlayer.scss';

flvjs.LoggingControl.enableError = false;
flvjs.LoggingControl.enableWarn = false;

interface PlayerProps {
    src: string;
    autoPlay?: boolean;
    controls?: boolean;
}

export const StreamPlayer = ({ src, autoPlay = true, controls = true }: PlayerProps): JSX.Element => {
    const video = useRef<HTMLVideoElement>(null);
    useEffect(() => {
        let flvPlayer: flvjs.Player;
        if (flvjs.isSupported() && video.current && src) {
            flvPlayer = flvjs.createPlayer(
                {
                    url: src,
                    type: 'flv',
                    hasAudio: false,
                },
                {
                    enableStashBuffer: true,
                    stashInitialSize: 384,
                },
            );
            flvPlayer.attachMediaElement(video.current);
            flvPlayer.load();
            flvPlayer.play();
            flvPlayer.on('error', (err) => {
                console.log('StreamPlayer Error:', err);
            });
        }
        return () => {
            flvPlayer.destroy();
        };
    }, [src]);
    return (
        <video
            autoPlay={autoPlay}
            controls={controls}
            style={{
                width: '100%',
            }}
            ref={video}
        />
    );
};

interface VideoModalProps {
    src?: string;
    area?: string;
    cameras?: Record<string, any[]>;
    close: () => void;
}

export const VideoModal = ({ src = '', area = '', cameras = {}, close }: VideoModalProps): JSX.Element => {
    const [selectedTab, setSelectedTab] = useState<string>(area);

    useEffect(() => {
        setSelectedTab(area);
    }, [area]);

    return (
        <div
            className="modal text-center show"
            style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.75)' }}
            role="dialog"
            aria-hidden="true"
        >
            <div
                className={'modal-dialog modal-dialog-centered ' + (area ? 'cameras-area' : 'single-camera')}
                role="document"
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" onClick={close}>
                                &times;
                            </span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {area ? (
                            <div>
                                <Tabs className="tabs">
                                    {tabs.map((tab) => (
                                        <Tab
                                            key={tab.value}
                                            selected={tab.value === selectedTab}
                                            label={tab.label}
                                            value={tab.value}
                                            onClick={setSelectedTab}
                                        />
                                    ))}
                                </Tabs>
                                <div className="players-cameras">
                                    {cameras[selectedTab]?.map(({ streamURL }) => (
                                        <div key={streamURL} className="stream-player">
                                            <StreamPlayer src={streamURL} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="stream-player">
                                <StreamPlayer src={src} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
