import React from 'react';
interface Props {
    color?: string;
    size?: string;
}
const Hospital = ({ color = '#6E6B7B', size = '24' }: Props): JSX.Element => {
    return (
        <svg
            style={{ fill: color, fontSize: size }}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox={`0 0 24 24`}
        >
            <path
                fill={color}
                fillRule="evenodd"
                d="M11.449 1.41a1 1 0 011.274.004l10.86 9.043a1 1 0 01-1.28 1.537l-.823-.686v7.51a4 4 0 01-4 4H6.52a4 4 0 01-4-4v-7.5l-.83.68a1 1 0 11-1.267-1.546L11.449 1.41zm.63 2.07l7.4 6.163v9.174a2 2 0 01-2 2H6.52a2 2 0 01-2-2V9.678l7.558-6.198z"
                clipRule="evenodd"
            />
            <path
                fill={color}
                d="M12.835 10.577c.114 0 .207.094.207.21v1.525c0 .116.092.21.207.21h1.544c.115 0 .207.093.207.209v1.692c0 .115-.092.21-.207.21H13.25a.208.208 0 00-.207.208v1.527c0 .115-.093.209-.207.209h-1.67a.208.208 0 01-.207-.21v-1.526a.208.208 0 00-.207-.209H9.207a.208.208 0 01-.207-.21v-1.691c0-.116.093-.21.207-.21h1.544a.208.208 0 00.207-.209v-1.526c0-.115.093-.21.207-.21h1.67z"
            />
        </svg>
    );
};

export default Hospital;
